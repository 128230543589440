import React, { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Button, Card, CardBody, Col, Row } from 'reactstrap';
import Box from 'reusableComponents/Box';
import { InputNew } from 'reusableComponents/HookForm/Input';
import { AsyncSelectField, SelectFieldNew } from 'reusableComponents/HookForm/Select';
import { generateMongoId, parseQueryParams, showToast } from 'utils/commonHelpers';
import { getCreatorBadgeOptions, creatorBadgeOptions } from 'utils/constants';
import { DropzoneNew } from 'reusableComponents/HookForm/Dropzone';
import { getPreviewImages, uploadImagesAndConvertToDatabaseFormat } from 'utils/hookFormImageHelpers';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigation } from 'context/navigationContext';
import LoadingOverlay from 'reusableComponents/LoadingOverlay';
import { TReel } from '../utils/types';
import { addReel, getReelData, updateReel } from '../utils/reelEditFetchHelpers';
import { fetchReels } from '../actions/reels.actions';
import { setIsLoaded, setIsLoading } from '../ReelSlice';
import { fetchVideos } from '../../../utils/videoHelpers/fetchVideoApiWrapper';

// fetches and format video of type reel for async select
const fetchVideoOfTypeReel = async ({ query = '' }) => {
	const videos = await fetchVideos({ query, videoType: 'REEL' });
	return videos.map((v) => ({
		value: v.id,
		label: v.videoName,
	}));
};

const formatFormDataToReelRequest = (data, creatorImage): TReel => {
	return {
		id: null,
		caption: data?.caption,
		video: { id: data?.video?.value, videoName: data?.video?.label, url: null },
		likesCount: null,
		viewCount: null,
		creator: {
			badge: data?.creatorBadge?.value,
			fullName: data?.creatorName,
			profilePic: creatorImage?.creatorImage?.url,
		},
		cta: {
			buttonText: data?.ctaButtonText,
			url: data?.ctaUrl,
		},
		createdAt: null,
	};
};

const preFillReelData = async (reelId, hookFormMethods, dispatch) => {
	dispatch(setIsLoading());

	const reelData = await getReelData(reelId);

	if (!reelData) {
		window.open('/404', '_self');
	}

	hookFormMethods.reset({
		caption: reelData?.caption,
		video: { value: reelData?.video?.id, label: reelData?.video?.videoName },
		creatorBadge: getCreatorBadgeOptions(reelData?.creator?.badge),
		creatorName: reelData?.creator?.fullName,
		ctaButtonText: reelData?.cta?.buttonText,
		ctaUrl: reelData?.cta?.url,
		creatorImage: getPreviewImages(
			{
				url: reelData.creator.profilePic,
				mediaType: 'IMAGE',
				id: '1',
			},
			false
		),
	});
	dispatch(setIsLoaded());
};
const ReelAddEditForm = (prop) => {
	const { setValue, ...hookFormMethods } = useForm();
	const { id: reelId, mode } = parseQueryParams(prop.location.search) ?? {};

	const { apiParams, loggedInUser, isLoading } = useSelector(({ auth, reel }) => ({
		loggedInUser: auth.user,
		apiParams: reel.apiParams,
		isLoading: reel.isLoading,
	}));

	const { navigate } = useNavigation();
	const dispatch = useDispatch();

	// INITIAL LOAD AND PREFILL
	useEffect(() => {
		if (mode === 'edit') {
			preFillReelData(reelId, hookFormMethods, dispatch);
		}
	}, []);

	// ON SUBMIT BUTTON CLICK
	const onSubmit = async (data) => {
		const imagesKeys = [
			{
				key: 'creatorImage',
				isMulti: false,
				withCaption: false,
				entityId: reelId === undefined ? generateMongoId() : reelId,
				entityType: 'PROFILE_PIC',
			},
		];
		const uploadFileData: any = await uploadImagesAndConvertToDatabaseFormat({ imagesKeys, data });

		const isImageUploaded = !!uploadFileData?.coverImage?.url;

		if (isImageUploaded) {
			showToast('success', 'Image Uploaded Successfully');
		}

		const requestData = formatFormDataToReelRequest(data, uploadFileData);

		dispatch(setIsLoading());
		if (mode === 'add') {
			const reelId = await addReel(requestData, loggedInUser.id);
			if (reelId) {
				navigate(`/reels/manage`);
			}
		} else if (mode === 'edit' && reelId) {
			await updateReel(requestData, reelId, loggedInUser.id);
			preFillReelData(reelId, hookFormMethods, dispatch);
			// dispatch the fetchReels Action to get the updated list of reels
			dispatch(fetchReels({ ...apiParams }));
		}
		dispatch(setIsLoaded());
	};

	return (
		<Card>
			<CardBody>
				<LoadingOverlay isLoading={isLoading} />

				<FormProvider {...hookFormMethods} setValue={setValue}>
					<form className="form ltr-support" onSubmit={hookFormMethods.handleSubmit(onSubmit)}>
						<Box w="100%" mt="1rem" mr="1rem" ml="0.5rem" mb="2rem">
							<Row>
								<Col style={{ height: '200' }}>
									<InputNew label="Caption" name="caption" type="text" required />
								</Col>
							</Row>

							<Row>
								<Col>
									<AsyncSelectField label="Video" name="video" required isClearable fetchOptions={fetchVideoOfTypeReel} />
								</Col>
							</Row>
							<Row>
								<Col sm={6}>
									<SelectFieldNew
										label="Creator Badge"
										name="creatorBadge"
										options={creatorBadgeOptions}
										required
										defaultValue={creatorBadgeOptions[1]}
									/>
								</Col>
								<Col sm={6}>
									<InputNew label="Creator Name" name="creatorName" type="text" required />
								</Col>
							</Row>

							<Row>
								<Col sm={6}>
									<DropzoneNew label="Creator Image" name="creatorImage" accept="image/*" required />
								</Col>
								<Col sm={6}>
									<InputNew label="Click To Action Button Text" name="ctaButtonText" type="text" />
									<InputNew label="Click To Action URL" name="ctaUrl" type="text" />
								</Col>
							</Row>
							<Button type="submit">{mode === 'add' ? 'Add' : 'Update'}</Button>
						</Box>
					</form>
				</FormProvider>
			</CardBody>
		</Card>
	);
};

export default ReelAddEditForm;

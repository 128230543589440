import { CaseReducer, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { showToast } from 'utils/commonHelpers';
import { TSubtopicsState } from './types';
import {
	prepareLessonAddForm,
	fetchSubtopicLessons,
	getLessonData,
	toggleTestingLessons,
	createOrUpdateSubtopicLesson,
	copyControlLessonsToTestLessons,
	scaleControlOrTestForm,
	scaleSubtopicLessons,
} from './actions/subtopics.actions';

type TSubtopicReducerType<T> = CaseReducer<TSubtopicsState, PayloadAction<T>>;

type TSubtopicReducers = {
	setIsLoading: TSubtopicReducerType<void>;
	setIsLoaded: TSubtopicReducerType<void>;
	setError: TSubtopicReducerType<string>;
	setApiParams: TSubtopicReducerType<TSubtopicsState['apiParams']>;
	setPage: TSubtopicReducerType<number>;
	toggleIsSubmitting: TSubtopicReducerType<{ isSubmitting: boolean }>;
};

const subtopicsSlice = createSlice<TSubtopicsState, TSubtopicReducers>({
	name: 'subtopics',
	initialState: {
		lessons: [],
		additionalData: {},
		isLoading: false,
		error: null,
		page: 1,
		total: 10,
		isSubmitting: false,
		apiParams: {
			limit: 50,
			skip: 0,
			query: '',
			sortKey: '_id',
			sortOrder: '-1',
		},
	},
	reducers: {
		setIsLoading: (state) => {
			state.isLoading = true;
		},
		setIsLoaded: (state) => {
			state.isLoading = false;
		},
		setError: (state, action) => {
			state.error = action?.payload;
		},
		setApiParams: (state, action) => {
			state.apiParams = {
				...(action.payload.limit && { limit: action.payload.limit }),
				...(action.payload.skip !== undefined && { skip: action.payload.skip }),
				...(action.payload.sortKey !== undefined && { sortKey: action.payload.sortKey }),
				...(action.payload.sortOrder !== undefined && { sortOrder: action.payload.sortOrder }),
				...(typeof action.payload.query !== 'undefined' && { query: action.payload.query }),
				...(action.payload.filters && { filters: action.payload.filters }),
			};
		},
		setPage: (state, action) => {
			state.page = action.payload;
		},
		toggleIsSubmitting: (state, action) => {
			state.isSubmitting = action.payload.isSubmitting;
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(fetchSubtopicLessons.pending, (state) => {
				state.isLoading = true;
				state.lessons = [];
				state.additionalData = {};
			})
			.addCase(fetchSubtopicLessons.fulfilled, (state, { payload }) => {
				const { lessons } = payload ?? {};
				if (lessons) {
					state.lessons = lessons;
					state.rankNumber = lessons.find((lesson) => lesson?.rankNumber)?.rankNumber;
					state.additionalData = {
						...state.additionalData,
						subtopic: lessons.find((lesson) => lesson?.subtopic)?.subtopic,
						// for extra info
						topic: lessons.find((lesson) => lesson?.topic)?.topic,
						course: lessons.find((lesson) => lesson?.course)?.course,
					};
				} else {
					window.open('/404', '_self');
				}
				state.isLoading = false;
			})

			.addCase(toggleTestingLessons.pending, (state) => {
				state.isLoading = true;
			})
			.addCase(toggleTestingLessons.fulfilled, (state, { payload }) => {
				state.isLoading = false;
			})

			.addCase(getLessonData.pending, (state) => {
				state.isLoading = true;
				state.additionalData = {
					...state.additionalData,
					lesson: null,
				};
			})
			.addCase(getLessonData.fulfilled, (state, { payload }) => {
				state.isLoading = false;

				if (payload) {
					state.additionalData = {
						...state.additionalData,
						lesson: payload,
					};
				} else {
					showToast('error', 'Unable to fetch lesson data');
				}
			})

			.addCase(prepareLessonAddForm.pending, (state) => {
				state.additionalData = {
					...state.additionalData,
					lesson: {},
				};
			})
			.addCase(prepareLessonAddForm.fulfilled, (state, { payload }) => {
				state.additionalData = {
					...state.additionalData,
					lesson: payload,
				};
			})

			.addCase(scaleControlOrTestForm.pending, (state) => {
				state.additionalData = {
					...state.additionalData,
					bucket: null,
				};
			})
			.addCase(scaleControlOrTestForm.fulfilled, (state, { payload }) => {
				state.additionalData = {
					...state.additionalData,
					bucket: payload.bucket,
				};
			})

			.addCase(createOrUpdateSubtopicLesson.pending, (state) => {
				state.isLoading = true;
			})
			.addCase(createOrUpdateSubtopicLesson.fulfilled, (state, { payload }) => {
				state.isLoading = false;
			})

			.addCase(copyControlLessonsToTestLessons.pending, (state) => {
				state.isLoading = true;
			})
			.addCase(copyControlLessonsToTestLessons.fulfilled, (state, { payload }) => {
				state.isLoading = false;
			})

			.addCase(scaleSubtopicLessons.pending, (state) => {
				state.isLoading = true;
			})
			.addCase(scaleSubtopicLessons.fulfilled, (state, { payload }) => {
				state.isLoading = false;
			});
	},
});

export const { setApiParams, setIsLoading, setIsLoaded, setPage, toggleIsSubmitting } = subtopicsSlice.actions;

export default subtopicsSlice.reducer;

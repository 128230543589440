import React from 'react';
import { DropdownItem } from 'reactstrap';
import { DocumentStatusEnum } from 'utils/constants';

type StatusDropDownItemProps = {
	status: DocumentStatusEnum;
	videoCourseId: string;
	updatedBy: string;
	dispatch: any;
	apiParams: any;
	updateVideoCourseStatus: ({
		videoCourseId,
		status,
		updatedBy,
		dispatch,
		apiParams,
	}: {
		videoCourseId: string;
		status: DocumentStatusEnum;
		updatedBy: string;
		dispatch: any;
		apiParams: any;
	}) => void;
	disabled: boolean;
};
// resuable component for documentStatus update dropdown item
// USAGE: input the current Status
const StatusDropDownItem: React.FC<StatusDropDownItemProps> = ({
	status,
	videoCourseId,
	updatedBy,
	dispatch,
	apiParams,
	updateVideoCourseStatus,
	disabled,
}) => {
	const handleUpdateStatus = (newStatus: DocumentStatusEnum) => {
		updateVideoCourseStatus({ videoCourseId, status: newStatus, updatedBy, dispatch, apiParams });
	};

	return (
		<>
			{(status === DocumentStatusEnum.DRAFT || status === DocumentStatusEnum.ACTIVE) && (
				<DropdownItem onClick={() => handleUpdateStatus(DocumentStatusEnum.INACTIVE)} disabled={disabled}>
					Mark Inactive
				</DropdownItem>
			)}
			{(status === DocumentStatusEnum.DRAFT || status === DocumentStatusEnum.INACTIVE) && (
				<DropdownItem onClick={() => handleUpdateStatus(DocumentStatusEnum.ACTIVE)} disabled={disabled}>
					Mark Active
				</DropdownItem>
			)}
		</>
	);
};

export default StatusDropDownItem;

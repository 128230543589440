import React, { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Button, Card, CardBody, Col, Row } from 'reactstrap';
import Box from 'reusableComponents/Box';
import { InputNew } from 'reusableComponents/HookForm/Input';
import { AsyncSelectField } from 'reusableComponents/HookForm/Select';
import { parseQueryParams } from 'utils/commonHelpers';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigation } from 'context/navigationContext';
import LoadingOverlay from 'reusableComponents/LoadingOverlay';
import { TextAreaNew } from 'reusableComponents/HookForm/TextArea';
import { fetchVideos } from 'utils/videoHelpers/fetchVideoApiWrapper';
import { VideoTypesEnum } from 'containers/Videos/utils/types';
import { TVideoCourse } from '../utils/types';
import { addVideoCourse, getVideoCourseData, updateVideoCourse } from '../utils/videoCourseEditFetchHelpers';
import { fetchVideoCourses } from '../actions/videoCourses.actions';
import { setIsLoaded, setIsLoading } from '../VideoCourseSlice';

// fetches and format video of type VIDEO_COURSE for async select
const fetchVideoOfTypeVideoCourse = async ({ query = '' }) => {
	const videos = await fetchVideos({ query, videoType: VideoTypesEnum.VIDEO_COURSE });
	return videos.map((v) => ({
		value: v.id,
		label: v.videoName,
	}));
};

const formatFormDataToVideoCourseRequest = (data): TVideoCourse => {
	return {
		id: null,
		title: data.title,
		video: { id: data.video.value, videoName: data.video.label, url: null },
		description: data.description,
		viewCount: null,
		createdAt: null,
	};
};

const preFillVideoCourseData = async (videoCourseId, hookFormMethods, dispatch) => {
	dispatch(setIsLoading());

	const videoCourseData = await getVideoCourseData(videoCourseId);

	if (!videoCourseData) {
		window.open('/404', '_self');
	}

	hookFormMethods.reset({
		title: videoCourseData?.title,
		description: videoCourseData?.description,
		video: { value: videoCourseData?.video?.id, label: videoCourseData?.video?.videoName },
	});

	dispatch(setIsLoaded());
};
const VideoCourseAddEditForm = (prop) => {
	const { setValue, ...hookFormMethods } = useForm();
	const { id: videoCourseId, mode } = parseQueryParams(prop.location.search) ?? {};

	const { apiParams, loggedInUser, isLoading } = useSelector(({ auth, videoCourse }) => ({
		loggedInUser: auth.user,
		apiParams: videoCourse.apiParams,
		isLoading: videoCourse.isLoading,
	}));

	const { navigate } = useNavigation();
	const dispatch = useDispatch();

	// INITIAL LOAD AND PREFILL
	useEffect(() => {
		if (mode === 'edit') {
			preFillVideoCourseData(videoCourseId, hookFormMethods, dispatch);
		}
	}, []);

	// ON SUBMIT BUTTON CLICK
	const onSubmit = async (data) => {
		const requestData = formatFormDataToVideoCourseRequest(data);

		console.log('[DEBUG] requestData', requestData);

		dispatch(setIsLoading());
		if (mode === 'add') {
			const videoCourseId = await addVideoCourse(requestData, loggedInUser.id);
			if (videoCourseId) {
				navigate(`/video-course/manage`);
			}
		} else if (mode === 'edit' && videoCourseId) {
			await updateVideoCourse(requestData, videoCourseId, loggedInUser.id);
			preFillVideoCourseData(videoCourseId, hookFormMethods, dispatch);
			// dispatch the fetchReels Action to get the updated list of reels
			dispatch(fetchVideoCourses({ ...apiParams }));
		}
		dispatch(setIsLoaded());
	};

	return (
		<Card>
			<CardBody>
				<LoadingOverlay isLoading={isLoading} />

				<FormProvider {...hookFormMethods} setValue={setValue}>
					<form className="form ltr-support" onSubmit={hookFormMethods.handleSubmit(onSubmit)}>
						<Box w="100%" mt="1rem" mr="1rem" ml="0.5rem" mb="2rem">
							<Row>
								<Col style={{ height: '200' }}>
									<InputNew label="Title" name="title" type="text" required />
								</Col>
							</Row>

							<Row>
								<Col>
									<TextAreaNew label="Description" name="description" required />
								</Col>
							</Row>

							<Row>
								<Col>
									<AsyncSelectField label="Video" name="video" required isClearable fetchOptions={fetchVideoOfTypeVideoCourse} />
								</Col>
							</Row>
							<Button type="submit">{mode === 'add' ? 'Add' : 'Update'}</Button>
						</Box>
					</form>
				</FormProvider>
			</CardBody>
		</Card>
	);
};

export default VideoCourseAddEditForm;

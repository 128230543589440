import React from 'react';
import { Switch, Route } from 'react-router-dom';
import PreviewVideo from 'components/PreviewVideo';
import { UploadVideo } from './UploadVideo';
import VideoList from './components/VideoList';

const Videos = () => {
	return (
		<Switch>
			<Route path="/videos/upload" component={UploadVideo} />
			<Route path="/videos/manage" component={VideoList} />
			{/* <Route path="/videos/reels/manage" component={ReelList} /> */}
			<Route path="/videos/preview" component={PreviewVideo} />
			{/* <Route path="/videos/reels/add-edit" component={ReelAddEditForm} /> */}
		</Switch>
	);
};

export default Videos;

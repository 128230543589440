import React from 'react';
import { Col, Row } from 'reactstrap';
import { TextAreaNew } from 'reusableComponents/HookForm/TextArea';
import { TConversationLessonQuestion } from 'containers/Lessons/utils/types';
import { SelectFieldNew } from 'reusableComponents/HookForm/Select';
import { InputNew } from 'reusableComponents/HookForm/Input';
import { gptModelOptions, roleVoiceOptions, roleVoiceRealtimeOptions } from 'utils/constants';
import { UiTypeEnum } from 'containers/Subtopics/constants';
import AudioPlayer from '../audioPlayerQuestion';
import { MultilingualAudioUploadField } from '../multilingualAudioUploadField';
import { FieldTranslation } from '../fieldTranslations';

export const conversationQuestionForm = ({ control, watch, question, oldQuestion, audioData, uiType }) => {
	if (uiType === UiTypeEnum.REALTIME_CALL) {
		return (
			<>
				<Row>
					<Col sm="11">
						<TextAreaNew name="title" label="Title" required defaultValue="" />
						<FieldTranslation name="title" control={control} oldFieldData={question?.title} />
						<MultilingualAudioUploadField name="title" watch={watch} />
					</Col>
					<Col sm="1">
						<AudioPlayer audioData={audioData?.title?.[question?.voiceId]} text={question?.voiceId} />
					</Col>
				</Row>

				<Row>
					<Col>
						<TextAreaNew name="systemPrompt" label="System Prompt" required defaultValue="" />
					</Col>
				</Row>

				<Row>
					<Col sm="12">
						<SelectFieldNew
							label="Bot Voice"
							name="botVoiceId"
							options={roleVoiceRealtimeOptions}
							defaultValue={roleVoiceRealtimeOptions[0]}
						/>
					</Col>
					<Col>
						<InputNew name="botName" label="AI Role Name" required defaultValue="" />
					</Col>
				</Row>
			</>
		);
	}

	return (
		<>
			<Row>
				<Col sm="11">
					<TextAreaNew name="title" label="Title" required defaultValue="" />
					<FieldTranslation name="title" control={control} oldFieldData={question?.title} />
					<MultilingualAudioUploadField name="title" watch={watch} />
				</Col>
				<Col sm="1">
					<AudioPlayer audioData={audioData?.title?.[question?.voiceId]} text={question?.voiceId} />
				</Col>
			</Row>
			<Row>
				<Col sm="11">
					<TextAreaNew name="scene" label="Scene" defaultValue="" required />
					<FieldTranslation name="scene" control={control} oldFieldData={(oldQuestion as TConversationLessonQuestion).scene} />
					<MultilingualAudioUploadField name="scene" watch={watch} />
				</Col>
				<Col sm="1">
					<AudioPlayer audioData={audioData?.scene?.[question?.systemVoiceId]} text={question?.systemVoiceId} />
				</Col>
				<Col sm="12">
					<SelectFieldNew label="Scene Voice" name="systemVoiceId" options={roleVoiceOptions} defaultValue={roleVoiceOptions[0]} />
				</Col>
			</Row>
			<Row>
				<Col>
					<TextAreaNew name="systemPrompt" label="System Prompt" required defaultValue="" />
				</Col>
			</Row>
			<Row>
				<Col>
					<TextAreaNew name="userPrompt" label="User Prompts" required defaultValue="" />
				</Col>
			</Row>
			<Row>
				<Col sm="12">
					<SelectFieldNew label="Bot Voice" name="botVoiceId" options={roleVoiceOptions} defaultValue={roleVoiceOptions[0]} />
				</Col>
				<Col>
					<InputNew name="botName" label="AI Role Name" required defaultValue="" />
				</Col>
			</Row>
			<Row>
				<Col sm="11">
					<TextAreaNew name="firstBotDialogue" label="Initial Dialogue for AI Role" required defaultValue="" />
					<FieldTranslation
						name="firstBotDialogue"
						control={control}
						oldFieldData={(oldQuestion as TConversationLessonQuestion).firstBotDialogue}
					/>
					<MultilingualAudioUploadField name="firstBotDialogue" watch={watch} />
				</Col>
				<Col sm="1">
					<AudioPlayer audioData={audioData?.firstBotDialogue?.[question?.botVoiceId]} text={question?.botVoiceId} />
				</Col>
			</Row>
			<Row>
				<Col>
					<TextAreaNew name="firstBotDialogueHint" label="Hint for the user of the Initial Dialogue" required defaultValue="" />
					<FieldTranslation
						name="firstBotDialogueHint"
						control={control}
						oldFieldData={(oldQuestion as TConversationLessonQuestion).firstBotDialogueHint}
					/>
					<MultilingualAudioUploadField name="firstBotDialogueHint" watch={watch} />
				</Col>
			</Row>
			<Row>
				<Col>
					<InputNew
						type="number"
						name="maxLength"
						label="Max Conversation Length(End conversation after this)"
						required
						defaultValue={9999}
						readOnly
						max={9999}
						min={1}
					/>
				</Col>
			</Row>
			<Row>
				<Col sm="12">
					<SelectFieldNew label="Chatgpt Model" name="gptModel" options={gptModelOptions} defaultValue={gptModelOptions[0]} />
				</Col>
			</Row>
		</>
	);
};

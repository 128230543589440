import React, { useState, useEffect } from 'react';
import { Card, CardBody, Row, Col, Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import { fetchWrapper } from 'utils/fetchWrapper';
import Select from 'react-select';
import { stringifyQueryParams } from 'utils/commonHelpers';
import Pagination from 'reusableComponents/Pagination/Pagination';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import { useNavigation } from 'context/navigationContext';
import LessonListPaginationTable from 'reusableComponents/Tables/LessonListPaginationTable';
import { setApiParams, setPage } from '../LessonsSlice';

const heads = [
	{ accessor: 'no', Header: 'No.' },
	{ accessor: 'title', Header: 'Title' },
	{ accessor: 'adminCode', Header: 'AdminCode' },
	{ accessor: 'courseAdminCode', Header: 'Course AdminCode' },
	{ accessor: 'subtopicAdminCode', Header: 'Subtopic AdminCode' },
	{ accessor: 'topicAdminCode', Header: 'Topic AdminCode' },
	{ accessor: 'uiType', Header: 'Ui Type' },
	{ accessor: 'rank', Header: 'Rank' },
	{ accessor: 'status', Header: 'Status' },
	{ accessor: 'actions', Header: 'Actions' },
];

const Actions = ({ id, status, postToggleStatus }) => {
	const [dropdownOpen, setDropdownOpen] = useState(false);
	const toggle = () => setDropdownOpen((prevState) => !prevState);
	const dispatch = useDispatch();
	const { navigate } = useNavigation();

	return (
		<Dropdown isOpen={dropdownOpen} toggle={toggle}>
			<DropdownToggle caret>Actions</DropdownToggle>
			<DropdownMenu>
				<DropdownItem onClick={() => navigate(`/lessons/add-edit${stringifyQueryParams({ lessonId: id, mode: 'edit' })}`)} disabled={false}>
					Edit Lesson
				</DropdownItem>
				<DropdownItem onClick={() => navigate(`/lessons/questions/list${stringifyQueryParams({ lessonId: id })}`)} disabled={false}>
					Show Questions
				</DropdownItem>
			</DropdownMenu>
		</Dropdown>
	);
};

const structureRows = (data, handleLessonRankChange, postToggleStatus) => {
	const groupedData = data.reduce((acc, item) => {
		const key = `${item.courseAdminCode}-${-item.rankNumber}.${item.rankDecimal}`;
		if (!acc[key]) {
			acc[key] = [];
		}
		acc[key].push({
			_id: item._id,
			title: item.course?.title?.en,
			adminCode: item.adminCode,
			courseAdminCode: item.course?.adminCode,
			subtopicAdminCode: item.subtopic?.adminCode,
			topicAdminCode: item.topic?.adminCode,
			uiType: item.uiType,
			rank: `${item.rankNumber}.${item.rankDecimal}`,
			status: item.status,
			bucket: item.bucket,
			rankNumber: item.rankNumber,
			rankDecimal: item.rankDecimal,
			actions: <Actions id={item._id} status={item.status} postToggleStatus={postToggleStatus} />,
		});
		return acc;
	}, {});

	return Object.entries(groupedData)
		.sort(([a], [b]) => a.localeCompare(b))
		.flatMap(([, values]: any) => values.sort((x, y) => x.rankNumber - y.rankNumber || x.rankDecimal - y.rankDecimal));
};

const LessonsList = () => {
	const location = useLocation();
	const history = useHistory();
	const dispatch = useDispatch();

	const { apiParams, page: currPage } = useSelector(({ lessons }) => {
		return {
			apiParams: lessons.apiParams,
			page: lessons.page,
		};
	});

	const courseFilter = apiParams?.filters?.courseFilter;
	const subtopicFilter = apiParams?.filters?.subtopicFilter;
	const topicFilter = apiParams?.filters?.topicFilter;

	const [lessonDetails, setLessonDetails] = useState([]);

	const [courseDataMap, setCourseDataMap] = useState({});
	const [topicSubtopicMap, setTopicSubtopicMap] = useState({});

	// filter options
	const [courseOptions, setCourseOptions] = useState([]);
	const [topicOptions, setTopicOptions] = useState([]);
	const [subtopicOptions, setSubtopicOptions] = useState([]);

	const [overallDocumentCount, setOverallDocumentCount] = useState(0);
	const [statusUpdate, setStatusUpdate] = useState(0);
	const itemsToShow = 20;
	const { navigate } = useNavigation();

	const changePageInQp = (newPage) => {
		const params = new URLSearchParams(location.search);
		params.set('page', `${newPage}`);
		history.push({ search: params.toString() });
		dispatch(setPage(newPage));
	};

	// Filter Change
	useEffect(() => {
		dispatch(setPage(1));
		changePageInQp(1);
		getLessons({ skip: 0, itemsToShow });
	}, [courseFilter, subtopicFilter, topicFilter]);

	// Initial Render
	useEffect(() => {
		const params = new URLSearchParams(location.search);
		const page = params.get('page') ? Number(params.get('page')) : currPage;

		changePageInQp(page);

		getCoursesData();
		getLessons({ skip: (page - 1) * itemsToShow, itemsToShow });
	}, [statusUpdate]);

	const updateFilterStore = (updatedFilters) => {
		dispatch(
			setApiParams({
				...apiParams,
				filters: { ...apiParams.filters, ...updatedFilters },
			})
		);
	};

	const handleLessonRankChange = async (lessonId, rank, additionalParams) => {
		const params = new URLSearchParams(location.search);
		const currPage = params.get('page') ? Number(params.get('page')) : 1;

		const response = await fetchWrapper('/v1/update-lesson-rank', {
			method: 'POST',
			body: {
				lessonId,
				rank,
			},
		});
		getLessons({ skip: (currPage - 1) * itemsToShow, itemsToShow });
	};

	const getLessons = async ({ skip, itemsToShow }) => {
		const response = await fetchWrapper('/admin/get-lessons', {
			method: 'POST',
			body: {
				filters: {
					courseAdminCode: courseFilter,
					subtopicAdminCode: subtopicFilter,
					topicAdminCode: topicFilter,
					adminCodeExclude: '_hang_', // ignore hanging exercise
					status: ['ACTIVE'],
				},
				skip,
				limit: itemsToShow,
			},
		});
		const structuredRows = structureRows(response?.data?.lessonList, handleLessonRankChange, ({ lessonId }) => {
			setStatusUpdate(Math.random() + lessonId); // to force rerender on callback
		});

		setOverallDocumentCount(response.data.documentCount);
		setLessonDetails(structuredRows);
	};

	const handleCourseChange = (selectedOption) => {
		updateFilterStore({
			courseFilter: selectedOption?.value || '',
			subtopicFilter: null,
			topicFilter: null,
		});
		setTopicOptions(courseDataMap[selectedOption?.value]?.topics.map((topic) => ({ label: topic.title.en, value: topic.adminCode })));
	};

	const handlePageChange = (pageNumber) => {
		changePageInQp(pageNumber);
		getLessons({ skip: (pageNumber - 1) * itemsToShow, itemsToShow });
	};

	const getCoursesData = async () => {
		const data = await fetchWrapper('/admin/get-courses-data', { method: 'GET' });
		const coursesOptions = data?.data?.map((course) => ({ label: course.courseTitle.en, value: course.courseAdminCode }));
		const courseDataMap = data?.data?.reduce((acc, course) => {
			acc[course.courseAdminCode] = { subtopics: course.subtopics, topics: course.topics };
			return acc;
		}, {});
		const topicToSubtopicsMap = data?.data?.reduce((acc, course) => {
			course.topics?.forEach((topic) => {
				acc[topic.adminCode] = topic.subtopics;
			});
			return acc;
		}, {});

		if (courseFilter) {
			setTopicOptions(courseDataMap[courseFilter]?.topics?.map((topic) => ({ label: topic.title.en, value: topic.adminCode })));
		}

		if (topicFilter) {
			setSubtopicOptions(topicToSubtopicsMap[topicFilter]?.map((subtopic) => ({ label: subtopic.title.en, value: subtopic.adminCode })));
		}

		setCourseDataMap(courseDataMap);
		setTopicSubtopicMap(topicToSubtopicsMap);
		setCourseOptions(coursesOptions);
	};

	const handleTopicChange = (selectedTopic) => {
		updateFilterStore({
			subtopicFilter: null,
			topicFilter: selectedTopic?.value || '',
		});
		setSubtopicOptions(
			topicSubtopicMap[selectedTopic?.value]?.map((subtopic) => ({ label: subtopic.title.en, value: subtopic.adminCode }))
		);
	};

	const handleSubtopicChange = (selectedOption) => {
		updateFilterStore({
			subtopicFilter: selectedOption?.value || '',
		});
	};

	const handleClearFilters = () => {
		updateFilterStore({
			topicFilter: null,
			subtopicFilter: null,
			courseFilter: null,
		});
		setTopicOptions([]);
		setSubtopicOptions([]);
	};

	return (
		<Card>
			<CardBody>
				<div style={styles.container}>
					<div style={styles.filters}>
						{/* Course Filter */}
						<Select
							value={courseFilter ? courseOptions.find((option) => option.value === courseFilter) : null}
							onChange={handleCourseChange}
							options={courseOptions}
							placeholder="Select Course"
							isSearchable
							styles={customStyles}
						/>
						{/* Topic Filter */}
						<Select
							value={topicFilter ? topicOptions.find((option) => option.value === topicFilter) : null}
							onChange={handleTopicChange}
							options={topicOptions}
							placeholder="Select Topic"
							isSearchable
							styles={customStyles}
						/>
						{/* Subtopic Filter */}
						<Select
							value={subtopicFilter ? subtopicOptions.find((option) => option.value === subtopicFilter) : null}
							onChange={handleSubtopicChange}
							options={subtopicOptions}
							placeholder="Select Subtopic"
							isSearchable
							styles={customStyles}
						/>

						<button type="button" onClick={handleClearFilters} style={styles.clearButton}>
							Clear Filters
						</button>
						{subtopicFilter && (
							<button
								type="button"
								onClick={() => {
									navigate(`/subtopic/lesson-ordering${stringifyQueryParams({ subtopicAdminCode: subtopicFilter })}`);
								}}
								style={styles.editButton}
							>
								Edit Subtopic
							</button>
						)}
					</div>
				</div>
				<Row className="mt-1 mb-4 rounded">
					<Col sm={8}>
						<h3>Lessons</h3>
					</Col>
				</Row>
				<LessonListPaginationTable heads={heads} rows={lessonDetails as any} />
				<Pagination itemsCount={overallDocumentCount} itemsToShow={itemsToShow} pageOfItems={currPage} onChangePage={handlePageChange} />
			</CardBody>
		</Card>
	);
};

const styles = {
	container: {
		padding: '20px',
		fontFamily: 'Arial, sans-serif',
		textAlign: 'center' as const,
	} as React.CSSProperties,
	filters: {
		display: 'flex',
		justifyContent: 'center',
		gap: '20px',
		marginBottom: '20px',
	} as React.CSSProperties,
	select: {
		padding: '10px',
		fontSize: '16px',
		borderRadius: '5px',
		border: '1px solid #ccc',
		outline: 'none',
		transition: 'border 0.3s ease-in-out',
		cursor: 'pointer',
	} as React.CSSProperties,
	button: {
		padding: '10px 20px',
		fontSize: '16px',
		borderRadius: '5px',
		backgroundColor: '#c2f2e4',
		color: 'black',
		border: 'none',
		cursor: 'pointer',
		transition: 'background-color 0.3s ease',
	} as React.CSSProperties,
	clearButton: {
		padding: '10px 20px',
		fontSize: '16px',
		borderRadius: '5px',
		backgroundColor: '#f5c6cb',
		color: 'black',
		border: 'none',
		cursor: 'pointer',
		transition: 'background-color 0.3s ease',
	} as React.CSSProperties,
	editButton: {
		padding: '10px 20px',
		fontSize: '16px',
		borderRadius: '5px',
		backgroundColor: '#FCFFAB',
		color: 'black',
		cursor: 'pointer',
		transition: 'background-color 0.3s ease',
		border: '1px solid #ddd',
	} as React.CSSProperties,
};

const customStyles = {
	control: (provided) => ({
		...provided,
		padding: '10px',
		fontSize: '16px',
		borderRadius: '5px',
		border: '1px solid #ccc',
		width: '200px',
	}),
	option: (provided, state) => ({
		...provided,
		backgroundColor: state.isSelected ? '#4CAF50' : 'white',
		color: state.isSelected ? 'white' : 'black',
		padding: '10px',
	}),
	placeholder: (provided) => ({
		...provided,
		color: '#999',
	}),
};

export default LessonsList;

import { createAsyncThunk } from '@reduxjs/toolkit';
import { setError } from 'containers/App/globalSlice';
import { showToast, stringifyQueryParams } from 'utils/commonHelpers';
import { fetchWrapper } from 'utils/fetchWrapper';
import { getLessonQuestion } from 'containers/Lessons/utils/helpers';
import { ContentDocumentStatusEnum, TLessonsState, TQuestion } from 'containers/Lessons/utils/types';

export const fetchHangingQuestions = createAsyncThunk(
	'/lessons/fetchHangingQuestions',
	async (
		apiParams: TLessonsState['apiParams'] & { lessonId?: string; adminCodeRegex?: string } & {
			parentId?: string;
			siblingId?: string;
		},
		{ dispatch }
	) => {
		try {
			const response = await fetchWrapper(`/admin/get-hanging-questions`, {
				method: 'POST',
				body: apiParams,
			});

			const { data } = response;
			if (response.status !== 200) {
				dispatch({ type: setError.type, payload: { errors: response.errors } });
			}

			return data;
		} catch (error) {
			showToast('error', error.message);
		}
	}
);

export const setLessonStatus = createAsyncThunk(
	'/lessons/setLessonStatus',
	async (
		apiParams: {
			lessonId: string;
			status: ContentDocumentStatusEnum;
			postToggleStatus;
		},
		{ dispatch }
	) => {
		try {
			const response = await fetchWrapper('/admin/set-lesson-status', {
				method: 'PUT',
				body: apiParams,
			});
			const { data } = response;
			if (response.status !== 200) {
				dispatch({ type: setError.type, payload: { errors: response.errors } });
				return null;
			}

			showToast('success', `Updated status of Lesson to ${apiParams.status}`);
			apiParams.postToggleStatus({
				lessonId: apiParams.lessonId,
			});
			if (data) return { isUpdated: data.isUpdated, status: data.isUpdated ? apiParams.status : null };
			return null;
		} catch (error) {
			showToast('error', error.message);
		}
	}
);

export const fetchLessonQuestion = createAsyncThunk(
	'/lessons/fetchLessonQuestion',
	async (
		apiParams: {
			questionId?: string;
			questionAdminCode?: string;
		},
		{ dispatch }
	) => {
		try {
			const response = await getLessonQuestion({ apiParams });
			const { error, lessonQuestion } = response;
			if (response.error) {
				dispatch({ type: setError.type, payload: { errors: error } });
				return null;
			}
			return { lessonQuestion };
		} catch (error) {
			showToast('error', error.message);
		}
	}
);

export const fetchLesson = createAsyncThunk(
	'/lessons/fetchLesson',
	async (
		apiParams: {
			lessonId: string;
		},
		{ dispatch }
	) => {
		try {
			const response = await fetchWrapper('/admin/get-lesson-data', {
				method: 'POST',
				body: apiParams,
			});
			const { data } = response;
			if (response.status !== 200) {
				dispatch({ type: setError.type, payload: { errors: response.errors } });
				return null;
			}
			return { lesson: data };
		} catch (error) {
			showToast('error', error.message);
		}
	}
);

export const updateLessonQuestion = createAsyncThunk(
	'/lessons/updateLessonQuestion',
	async (
		apiParams: {
			questionId: string;
			lessonId: string;
			set: string;
			updatedQuestion: Partial<TQuestion>;
			mode: string;
			userId: string;
			navigate: any;
		},
		{ dispatch }
	) => {
		try {
			const isEditMode = apiParams.mode === 'edit';
			delete apiParams.mode;
			const response = await fetchWrapper('/admin/add-or-update-lesson-question', {
				method: 'PUT',
				body: {
					...apiParams,
					edit: isEditMode,
				},
			});
			const { data } = response;
			if (response.status !== 200) {
				dispatch({ type: setError.type, payload: { errors: response.errors } });
				return null;
			}
			showToast('success', 'Lesson question successfully updated');

			if (!isEditMode) {
				apiParams.navigate(`/lessons/questions/list${stringifyQueryParams({ lessonId: apiParams.lessonId, set: apiParams.set })}`);
			}

			return { updatedQuestion: data };
		} catch (error) {
			showToast('error', error.message);
		}
	}
);

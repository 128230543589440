import React from 'react';

export const questionSetTitleSpan = (set: string) => {
	return <></>; // question level AB is disabled now

	if (!set) {
		return <></>;
	}

	return (
		<>
			<span style={{ fontWeight: 'bold', color: '#F55A42', backgroundColor: '#f9f2f4', padding: '2px 6px', borderRadius: '4px' }}>
				({set === '1' ? 'Control' : 'Test'})
			</span>
		</>
	);
};

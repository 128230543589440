import { MatchTheColumnMobilePreview } from 'containers/Exercise/components/MatchTheColumnMobilePreview';
import { MatchColumnMappingTypes, QuestionMappingEnum, TMatchColumnExerciseQuestion } from 'containers/Exercise/utils/types';
import { QuestionTypesEnum } from 'containers/Lessons/utils/types';
import React from 'react';
import { Button, Col, Row } from 'reactstrap';
import Box from 'reusableComponents/Box';
import { InputNew } from 'reusableComponents/HookForm/Input';
import { SelectFieldNew } from 'reusableComponents/HookForm/Select';
import { TextAreaNew } from 'reusableComponents/HookForm/TextArea';
import { getSanitizedAudioUrl } from 'utils/commonHelpers';
import { FieldTranslation } from '../fieldTranslations';

export const matchColumnQuestionForm = ({ watch, itemsFieldArray, control, audioData }) => {
	const questionMapping = watch('questionMapping');
	const matchTheColumnWatch = watch(['items']);
	const questionType = watch('type');
	const uiType = watch('uiType');

	const getMatchTheFollowingList = ({ column }) => {
		return itemsFieldArray.fields.map((item, idx) => {
			if (item.column !== column) return;
			// check the question format to decide to show image
			// const isImageItem =
			// 	!!item.value.image ||
			// 	(column === '1'
			// 	? [QuestionMappingEnum.IMAGES_TO_TEXT, QuestionMappingEnum.IMAGES_TO_IMAGES].includes(questionMapping && questionMapping.value)
			// 	: [QuestionMappingEnum.TEXT_TO_IMAGES, QuestionMappingEnum.IMAGES_TO_IMAGES].includes(questionMapping && questionMapping.value));
			const isImageItem = false;

			return (
				<Box key={item.id} pos="relative">
					<Row style={{ border: '1px solid #bababa', padding: '20px 0', margin: '10px 0' }}>
						<Col>
							{/* {isImageItem && (
								<>
									<DropzoneNew label="Upload Image (100X100)px" name={`items[${idx}].value.image`} defaultValue={item.value.image} />
									<MidjourneyImageGenerator
										width="3rem"
										height="3rem"
										acceptImage={async ({ imageUrl }: { imageUrl: string }) => {
											const imageName = `${questionId}_item_${idx}_descriptionImage`;

											const file = await getImageFileFromUrl({
												imageUrl,
												imageName: `${imageName}.png`,
											});

											const convertedFile = await getImageFileFromUrl({
												imageUrl: (await convertFileToWebpBlob({
													file,
												})) as string,
												imageName: `${imageName}.webp`,
											});

											setValue(`items[${idx}].value.image`, [convertedFile]);
										}}
									/>
								</>
							)} */}
							<div style={{ display: 'none' }}>
								<InputNew readOnly name={`items[${idx}].key`} required defaultValue={item.key} label="key" />
							</div>

							<InputNew
								name={`items[${idx}].value.text`}
								required={!isImageItem}
								defaultValue={item.value.text}
								label={isImageItem ? 'Image description' : 'Text input'}
								placeholder={isImageItem ? 'Image description' : 'Text input'}
								maxLength={isImageItem ? null : 12}
								helpText={isImageItem ? '' : 'Maximum 12 characters'}
							/>

							<FieldTranslation
								name={`items[${idx}].lang`}
								control={control}
								selectWidth="4"
								textWidth="8"
								oldFieldData={item.value.lang}
							/>
							{/* TODO: add audio upload field
							<MultilingualAudioUploadField name={`items[${idx}].lang`} watch={watch} /> */}
						</Col>
						<Box onClick={() => itemsFieldArray.remove(idx)} cursor="pointer" pos="absolute" t="6px" r="6px">
							<span className="lnr lnr-cross" />
						</Box>
					</Row>
				</Box>
			);
		});
	};

	const MatchTheColumnAddItemButton = ({ column }) => {
		return (
			<Button
				disabled={
					itemsFieldArray.fields.filter((item) => item.column === column).length >=
					((column === '1'
						? [QuestionMappingEnum.IMAGES_TO_TEXT, QuestionMappingEnum.IMAGES_TO_IMAGES]
						: [QuestionMappingEnum.TEXT_TO_IMAGES, QuestionMappingEnum.IMAGES_TO_IMAGES]
					).includes(questionMapping && questionMapping.value)
						? 3
						: 4)
				}
				type="button"
				onClick={() => {
					itemsFieldArray.append({
						column,
						key: `${itemsFieldArray.fields.filter((f) => f.column === column).length + 1}`,
						value: {
							text: '',
							...((column === '1'
								? [QuestionMappingEnum.IMAGES_TO_TEXT, QuestionMappingEnum.IMAGES_TO_IMAGES]
								: [QuestionMappingEnum.TEXT_TO_IMAGES, QuestionMappingEnum.IMAGES_TO_IMAGES]
							).includes(questionMapping && questionMapping.value) && {
								image: {},
							}),
						},
					});
				}}
			>
				+ Add New Item
			</Button>
		);
	};

	const getQuestionForm = (question: TMatchColumnExerciseQuestion) => {
		if (!question) return;
		if (question.type !== QuestionTypesEnum.MATCH_COLUMN) return;
		return (
			<>
				<Row>
					<Col>
						<Row>
							<Col sm="6">
								<SelectFieldNew
									label="Select question format"
									name="questionMapping"
									options={MatchColumnMappingTypes}
									defaultValue={{
										label: 'Text to Text',
										value: QuestionMappingEnum.TEXT_TO_TEXT,
									}}
									isDisabled
									// isDisabled={mode === 'edit'}
									required
								/>
							</Col>
						</Row>
						{MatchColumnMappingTypes.map((type) => type.value).includes(questionMapping && questionMapping.value) && (
							<>
								<Row style={{ justifyContent: 'center', padding: '20px 0' }}>
									<h3>Add Matching Items</h3>
								</Row>

								<Row>
									<Col sm="6">
										<h3 style={{ textAlign: 'center' }}>A</h3>
										{getMatchTheFollowingList({ column: '1' })}
										<Row style={{ justifyContent: 'center' }}>
											<MatchTheColumnAddItemButton column="1" />
										</Row>
									</Col>
									<Col sm="6">
										<h3 style={{ textAlign: 'center' }}>B</h3>
										{getMatchTheFollowingList({ column: '2' })}
										<Row style={{ justifyContent: 'center' }}>
											<MatchTheColumnAddItemButton column="2" />
										</Row>
									</Col>
								</Row>
							</>
						)}
						<Row style={{ justifyContent: 'center', padding: '20px 0' }}>
							<MatchTheColumnMobilePreview
								itemList={
									matchTheColumnWatch.items &&
									matchTheColumnWatch.items.length > 0 &&
									itemsFieldArray.fields.map((field, idx) => {
										return {
											column: field.column,
											key: field.key,
											value: {
												...matchTheColumnWatch.items[idx]?.value,
											},
										};
									})
								}
							/>
						</Row>
						<Row>
							<Col>
								<TextAreaNew name="subtitle" label="Narration Text" defaultValue="" />
							</Col>
							{question?.subtitleAudio && (
								<Col style={{ display: 'flex', alignItems: 'center' }}>
									<Box d="flex" ai="center" jc="center" w="100%">
										<p style={{ paddingRight: '15px' }}>{getSanitizedAudioUrl(question.subtitleAudio)}</p>
									</Box>
								</Col>
							)}
						</Row>
						<Row>
							<Col>
								<SelectFieldNew
									isDisabled
									label="Difficulty"
									name="difficulty"
									options={Array.from({ length: 10 }, (_, index) => ({
										label: index + 1,
										value: index + 1,
									}))}
									defaultValue={{
										label: 5,
										value: 5,
									}}
								/>
							</Col>
						</Row>
					</Col>
				</Row>
			</>
		);
	};

	return (
		<Box w="100%" mt="1rem" mr="1rem" ml="0.5rem" mb="2rem">
			{getQuestionForm(({
				type: questionType?.value,
				uiType: uiType?.value,
			} as unknown) as TMatchColumnExerciseQuestion)}
		</Box>
	);
};

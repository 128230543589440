import React, { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { CardBody, Row } from 'reactstrap';
import { getSanitizedVideoUrl, parseQueryParams } from 'utils/commonHelpers';
import VideoJsPlayer from 'reusableComponents/VideoPlayer/VideoJsPlayer';
import { useDispatch, useSelector } from 'react-redux';
import LoadingOverlay from 'reusableComponents/LoadingOverlay';
import { fetchVideoById } from 'containers/Videos/actions/video.actions';

const PreviewVideo = (props) => {
	const hookFormMethods = useForm();
	const { videoId } = parseQueryParams(props.location.search) ?? {};
	const dispatch = useDispatch();

	const { video, isLoading } = useSelector((state) => state.video);

	useEffect(() => {
		dispatch(fetchVideoById(videoId));
	}, [videoId]);

	return (
		<>
			<FormProvider {...hookFormMethods}>
				<CardBody>
					<LoadingOverlay isLoading={isLoading} />
					<Row sm={2}>
						<h4>Preview Video: {video && video.videoName}</h4>
					</Row>
					{!video && !isLoading && (
						<Row>
							<h5>No Video Found</h5>
						</Row>
					)}
					{video?.videoUrl && (
						<a href={getSanitizedVideoUrl(video.videoUrl)} target="_blank" rel="noreferrer" style={{ fontSize: '2rem' }}>
							Open Video
						</a>
					)}
				</CardBody>
			</FormProvider>
		</>
	);
};

export default PreviewVideo;

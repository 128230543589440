import { TEmbeddedQuestion, TLesson } from 'containers/Lessons/utils/types';
import { LessonAdminUiStateEnum, lightBackgroundColors } from '../constants';

// GIVEN LIST OF ALL LESSONS UNDER A SUBTOPIC, THIS FUNCTION SEPARATES THEM IN
// CONTROL AND TEST
export const separateLessons = (lessons, watchMoveToTest) => {
	// group based on rank
	const rankLessonMap = lessons.reduce((acc, lesson) => {
		const rank = `${lesson.rankNumber}.${lesson.rankDecimal}`;

		if (!acc[rank]) {
			acc[rank] = [];
		}

		acc[rank].push(lesson);

		return acc;
	}, {});

	const rankPairedLessons = Object.entries(rankLessonMap)
		.map(([rank, pairedLesson]) => {
			const rankNumber = Number(rank.split('.')[0]);
			const rankDecimal = Number(rank.split('.')[1]);

			let controlLesson;
			let testLesson;

			// find control and test lesson
			(pairedLesson as Array<TLesson>).forEach((lesson) => {
				if (lesson?.bucket === 'A') testLesson = lesson;
				else controlLesson = lesson;
			});

			if (!testLesson) {
				// no test lesson exist, check if we need to copy from control
				const matchingLesson = Object.entries(watchMoveToTest ?? {}).find(([, option]) => {
					if ((option as any)?.value === rankDecimal) {
						return true;
					}
					return false;
				});

				if (matchingLesson) {
					// found the control lesson from which we should copy test lesson
					testLesson = lessons.find((lesson: TLesson) => lesson.adminCode === matchingLesson[0]);
					return {
						rank: rankDecimal,
						controlLesson,
						testLesson: {
							...testLesson,
							uiState: LessonAdminUiStateEnum.REFLECTION,
						},
					};
				}
			}

			return {
				rank: rankDecimal,
				controlLesson,
				testLesson,
			};
		})
		.sort((a, b) => a.rank - b.rank);

	// find the maximum rank in the array
	const maxRank = Math.max(...rankPairedLessons.map((item) => item.rank));

	// fill in the missing ranks
	const splitUp = Array.from({ length: maxRank }, (_, index) => {
		const rank = index + 1;
		const existingItem = rankPairedLessons.find((item) => item.rank === rank);

		const selectedLesson = Object.entries(watchMoveToTest ?? {}).find(([, option]) => {
			if ((option as any)?.value === rank) {
				return true;
			}
			return false;
		});

		let matchingLesson;
		if (selectedLesson) {
			const testLesson = lessons.find((lesson: TLesson) => lesson.adminCode === selectedLesson[0]);

			matchingLesson = {
				rank,
				controlLesson: null,
				testLesson: {
					...testLesson,
					uiState: LessonAdminUiStateEnum.REFLECTION,
				},
			};
		}

		return existingItem ?? matchingLesson ?? { rank, controlLesson: null, testLesson: null };
	});

	// link each lesson with a color
	// associate each adminCode with a color
	const adminCodeColorMap = splitUp.reduce((acc, pairedLesson, idx) => {
		if (pairedLesson.controlLesson) {
			acc[pairedLesson.controlLesson.adminCode] = lightBackgroundColors[idx];
		}
		return acc;
	}, {});

	const updatedUiColors = splitUp.map((pairedLesson, idx) => {
		const updatedPairedLesson = { ...pairedLesson };

		// for UI find matching lessons with admincode
		if (updatedPairedLesson.testLesson) {
			const matchingControlLesson = splitUp.find(
				(lesson) => lesson?.controlLesson?.adminCode === updatedPairedLesson?.testLesson?.adminCode
			);

			if (matchingControlLesson) {
				const { adminCode } = updatedPairedLesson.testLesson;

				updatedPairedLesson.testLesson = { ...updatedPairedLesson.testLesson };
				updatedPairedLesson.testLesson.colorLinked = adminCodeColorMap[adminCode];
			}
		}

		// for UI find matching lessons with admincode
		if (updatedPairedLesson.controlLesson) {
			const matchingTestLesson = splitUp.find((lesson) => lesson?.testLesson?.adminCode === updatedPairedLesson?.controlLesson?.adminCode);

			if (matchingTestLesson) {
				const { adminCode } = updatedPairedLesson.controlLesson;

				updatedPairedLesson.controlLesson = { ...updatedPairedLesson.controlLesson };
				updatedPairedLesson.controlLesson.colorLinked = adminCodeColorMap[adminCode];
			}
		}

		return updatedPairedLesson;
	});

	// give matching colors to control lesson
	const updatedControlLessonColors = updatedUiColors.map((pairedLesson) => {
		const updatedPairedLesson = { ...pairedLesson };
		const { controlLesson } = updatedPairedLesson;

		if (controlLesson?.colorLinked) {
			return pairedLesson;
		}

		if (controlLesson?.multiLingualQuestions) {
			// find a lesson in updatedUiColors that has same questions as controlLesson
			const matchedIndex = updatedUiColors.findIndex((lessonPair) => {
				const { testLesson } = lessonPair;

				if (testLesson?.multiLingualQuestions) {
					const controlQuestions = Object.values(controlLesson.multiLingualQuestions).flat() as Array<TEmbeddedQuestion>;
					const testQuestions = Object.values(testLesson.multiLingualQuestions).flat() as Array<TEmbeddedQuestion>;

					return controlQuestions.every((value, index) => value?.id?.toString() === testQuestions[index]?.id?.toString());
				}

				return false;
			});

			if (matchedIndex !== -1) {
				// there is a match of question between control and test
				const lessonColor = adminCodeColorMap[controlLesson.adminCode];

				// give color to control lesson
				return {
					...updatedPairedLesson,
					controlLesson: {
						...controlLesson,
						colorLinked: lessonColor,
						uiState: LessonAdminUiStateEnum.NO_COPY_ALLOWED,
					},
				};
			}
		}

		return updatedPairedLesson;
	});

	// give matching colors to test lesson
	const updatedTestLessonColors = updatedControlLessonColors.map((pairedLesson) => {
		const updatedPairedLesson = { ...pairedLesson };
		const { testLesson } = updatedPairedLesson;

		if (testLesson?.colorLinked) {
			return pairedLesson;
		}

		if (testLesson?.multiLingualQuestions) {
			// find a lesson in updatedControlLessonColors that has same questions as testLesson
			const matchedIndex = updatedControlLessonColors.findIndex((lessonPair) => {
				const { controlLesson } = lessonPair;

				if (controlLesson?.multiLingualQuestions) {
					const controlQuestions = Object.values(controlLesson.multiLingualQuestions).flat() as Array<TEmbeddedQuestion>;
					const testQuestions = Object.values(testLesson.multiLingualQuestions).flat() as Array<TEmbeddedQuestion>;

					return controlQuestions.every((value, index) => value?.id?.toString() === testQuestions[index]?.id?.toString());
				}

				return false;
			});

			if (matchedIndex !== -1) {
				// there is a match of question between control and test
				const lessonColor = updatedControlLessonColors[matchedIndex].controlLesson.colorLinked;

				// give color to test lesson
				return {
					...updatedPairedLesson,
					testLesson: {
						...testLesson,
						colorLinked: lessonColor,
						uiState: LessonAdminUiStateEnum.COPIED_LESSON,
					},
				};
			}
		}

		return updatedPairedLesson;
	});

	return updatedTestLessonColors;
};

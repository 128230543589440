import React, { useEffect, useState } from 'react';
import { Button } from 'reactstrap';
import Box from 'reusableComponents/Box';

const isWithinISTTimeRange = ({
	startHour,
	startMinute,
	endHour,
	endMinute,
}: {
	startHour: number;
	startMinute: number;
	endHour: number;
	endMinute: number;
}) => {
	if (startHour === -1 && startMinute === -1 && endHour === -1 && endMinute === -1) {
		return true;
	}

	const now = new Date();
	const gmtTime = now.getTime() + now.getTimezoneOffset() * 60000;
	const istTime = new Date(gmtTime + 19800000);

	const hours = istTime.getHours();
	const minutes = istTime.getMinutes();

	// If time range is within the same day
	if (startHour < endHour || (startHour === endHour && startMinute < endMinute)) {
		return (
			(hours > startHour || (hours === startHour && minutes >= startMinute)) &&
			(hours < endHour || (hours === endHour && minutes < endMinute))
		);
	}

	// If time range spans midnight (e.g., 23:50 to 00:00)
	return (
		hours > startHour || (hours === startHour && minutes >= startMinute) || hours < endHour || (hours === endHour && minutes < endMinute)
	);
};

export const TimeRestrictedButton = ({
	text,
	onClick,
	timeRange,
	style,
}: {
	text: string;
	onClick: () => void;
	timeRange: {
		startHour: number;
		startMinute: number;
		endHour: number;
		endMinute: number;
	};
	style?: Record<string, string>;
}) => {
	const [isEnabled, setIsEnabled] = useState(isWithinISTTimeRange(timeRange));

	useEffect(() => {
		const interval = setInterval(() => {
			setIsEnabled(isWithinISTTimeRange(timeRange));
		}, 1000 * 3);

		return () => clearInterval(interval);
	}, []);

	return (
		<>
			<Box
				style={{
					padding: '4px',
				}}
			>
				<Button disabled={!isEnabled} type="button" onClick={onClick} style={style}>
					{text}
				</Button>
				{!isEnabled && (
					<p
						style={{
							color: isEnabled ? 'green' : 'red',
							fontSize: '14px',
							fontWeight: 'bold',
						}}
					>
						Button will be enabled from {timeRange.startHour}:{timeRange.startMinute.toString().padStart(2, '0')} IST to {timeRange.endHour}
						:{timeRange.endMinute.toString().padStart(2, '0')} IST
					</p>
				)}
			</Box>
		</>
	);
};

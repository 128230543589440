import React from 'react';
import { useFieldArray } from 'react-hook-form';
import { Button, Col, Row } from 'reactstrap';
import Box from 'reusableComponents/Box';
import { InputNew } from 'reusableComponents/HookForm/Input';
import theme from 'utils/theme';
import AudioPlayer from 'containers/Lessons/components/audioPlayerQuestion';
import { AnagramTypesEnum } from '../utils/types';

const AnagramItem = ({ label, control, fieldName, anagramItemType, audioData, setAudioData, question }) => {
	const anagramItemFieldArray = useFieldArray({
		control,
		name: fieldName,
	});

	return (
		<Box w="100%">
			<h4 style={{ marginBottom: '1rem' }}>{label}</h4>
			<Box>
				{anagramItemFieldArray.fields.map((field, index) => {
					return (
						<Box key={field?.id} pos="relative">
							<h4 style={{ marginBottom: '1rem' }}>
								{anagramItemType === AnagramTypesEnum.SENTENCE ? 'Word' : 'Alphabet'} {index + 1}
							</h4>
							<Row>
								<Col sm="9">
									<InputNew label="Text" name={`${fieldName}[${index}].value.en.text`} required defaultValue={field?.value?.en?.text} />
									{/* <FieldTranslation
										name={`${fieldName}[${index}].lang`}
										control={control}
										oldFieldData={(oldQuestion as TAnagramLessonQuestion)?.blocks?.[index]?.text}
									/>
									<MultilingualAudioUploadField name={`${fieldName}[${index}].text`} watch={watch} /> */}
								</Col>
								<Col
									sm="2"
									style={{
										margin: '-8px',
									}}
								>
									<AudioPlayer audioData={audioData?.[fieldName]?.[index]?.[question?.voiceId]} text={question?.voiceId} />
								</Col>
							</Row>
							<Box
								onClick={() => {
									anagramItemFieldArray.remove(index);
									const audioDataCopy = { ...audioData };
									audioDataCopy[fieldName].splice(index, 1);
									setAudioData(audioDataCopy);
								}}
								cursor="pointer"
								pos="absolute"
								t="0"
								r="0"
							>
								<span className="lnr lnr-cross" />
							</Box>
						</Box>
					);
				})}
				<Row>
					<Col sm="12">
						<Box d="flex" jc="center">
							<Button
								c={theme.clrs.cWhite}
								fs="1rem"
								bRad="6px"
								d="flex"
								jc="center"
								ai="center"
								bg={theme.clrs.cSkyBlue}
								cursor="pointer"
								onClick={() => anagramItemFieldArray.append({})}
								mb="1rem"
								p=".3rem"
							>
								{anagramItemType === AnagramTypesEnum.SENTENCE ? '+ Add New Word' : '+ Add New Alphabet'}
							</Button>
						</Box>
					</Col>
				</Row>
			</Box>
		</Box>
	);
};

export { AnagramItem };

import React from 'react';
import { Button, Col, Row } from 'reactstrap';
import { TMcqLessonQuestion, UiTypeEnum } from 'containers/Lessons/utils/types';
import { InputNew } from 'reusableComponents/HookForm/Input';
import { Checkbox } from 'reusableComponents/HookForm/Checkbox';
import { DropzoneNew } from 'reusableComponents/HookForm/Dropzone';
import Box from 'reusableComponents/Box';
import { McqTypeEnum, McqTypeOptions } from 'utils/constants';
import { SelectFieldNew } from 'reusableComponents/HookForm/Select';
import AudioPlayer from '../audioPlayerQuestion';
import { MultilingualAudioUploadField } from '../multilingualAudioUploadField';
import { FieldTranslation } from '../fieldTranslations';

export const mcqQuestionForm = ({ control, watch, question, oldQuestion, audioData, uiType, hang, optionsFieldArray, mode }) => {
	const mcqType = watch('mcqType');
	return (
		<>
			<Row>
				<Col>
					<SelectFieldNew
						label="MCQ Type"
						name="mcqType"
						options={McqTypeOptions}
						defaultValue={McqTypeOptions[0]}
						isDisabled={mode === 'edit'}
						required
					/>
				</Col>
				<Col sm="12">
					{uiType === UiTypeEnum.GRAMMAR && (
						<Row>
							<Col sm="11">
								<InputNew name="preTitle" label="Pre Title" defaultValue="" placeholder="Pre Title" />
								<FieldTranslation name="preTitle" control={control} oldFieldData={(oldQuestion as TMcqLessonQuestion).preTitle} />
								<MultilingualAudioUploadField name="preTitle" watch={watch} />
							</Col>
						</Row>
					)}
					<Row>
						<Col sm="11">
							<InputNew name="title" label="Question Text" defaultValue="" placeholder="Question Text" />
							<FieldTranslation name="title" control={control} oldFieldData={oldQuestion.title} />
							<MultilingualAudioUploadField name="title" watch={watch} />
						</Col>
						<Col sm="1">
							<AudioPlayer audioData={audioData?.title?.[question?.voiceId]} text={question?.voiceId} />
						</Col>
					</Row>
					{uiType === UiTypeEnum.GRAMMAR && (
						<Row>
							<Col sm="11">
								<InputNew name="correctSentence" label="Correct Sentence" defaultValue="" placeholder="Correct Sentence text" required />
								<FieldTranslation
									name="correctSentence"
									control={control}
									oldFieldData={(oldQuestion as TMcqLessonQuestion).correctSentence}
								/>
								<MultilingualAudioUploadField name="correctSentence" watch={watch} />
							</Col>
							<Col sm="1">
								<AudioPlayer audioData={audioData?.correctSentence?.[question?.voiceId]} text={question?.voiceId} />
							</Col>
						</Row>
					)}
					{hang !== 'true' && mcqType?.value === McqTypeEnum.STANDARD_MCQ ? (
						<Row>
							<Col>
								<DropzoneNew label="Associated Image: (Range of Aspect ratio 1.95 to 2.05)" name="descriptionImage" />
							</Col>
						</Row>
					) : (
						<></>
					)}
					<Row>
						{optionsFieldArray.fields.map((option, idx) => {
							return (
								<Box
									key={option.id || idx}
									pos="relative"
									style={{
										width: '50%',
									}}
								>
									<Row style={{ border: '1px solid #bababa', padding: '20px 0', margin: '10px 0', position: 'relative' }}>
										<Col sm="12">
											{(option.image || mcqType?.value === McqTypeEnum.OPTION_IMAGE_MCQ) && (
												<>
													<DropzoneNew label="Upload Image (150X150)px" name={`options[${idx}].media`} defaultValue={option.image} />
												</>
											)}
											<Checkbox label="Is Correct?" name={`options[${idx}].isCorrectAnswer`} checked={option?.isCorrectAnswer} />
											<Row>
												<Col sm="11">
													<InputNew
														maxLength={80}
														name={`options[${idx}].value.en.text`}
														defaultValue={option?.value?.en?.text}
														label="Image Text"
														placeholder="Image Text"
													/>
													<FieldTranslation
														name={`options[${idx}].lang`}
														control={control}
														selectWidth="4"
														textWidth="8"
														oldFieldData={(oldQuestion as TMcqLessonQuestion)?.options?.[idx]?.text}
													/>
													<MultilingualAudioUploadField name={`options[${idx}].lang`} watch={watch} widthSpan="100%" />
												</Col>
												<Col sm="1">
													<AudioPlayer audioData={audioData?.options?.[idx]?.[question?.voiceId]} text={question?.voiceId} />
												</Col>
											</Row>
										</Col>
										<Box onClick={() => optionsFieldArray.remove(idx)} cursor="pointer" pos="absolute" t="6px" r="6px">
											<span className="lnr lnr-cross" />
										</Box>
									</Row>
								</Box>
							);
						})}
					</Row>

					<Row
						style={{
							justifyContent: 'center',
						}}
					>
						<Button
							disabled={optionsFieldArray.fields.length >= (hang === 'true' ? 2 : 4)}
							type="button"
							onClick={() => {
								optionsFieldArray.append({});
							}}
						>
							+ Add New Option
						</Button>
					</Row>
				</Col>
			</Row>
		</>
	);
};

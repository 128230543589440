import React from 'react';

export const ProgressOverlay = ({ progress }) => {
	if (progress <= 0) return null;

	return (
		<div
			style={{
				position: 'fixed',
				top: 0,
				left: 0,
				right: 0,
				bottom: 0,
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
				zIndex: 1001,
			}}
		>
			<div
				style={{
					color: 'white',
					fontSize: '.8rem',
					fontWeight: 'bold',
					padding: '1rem',
					backgroundColor: 'rgb(205 198 255)',
					borderRadius: '100px',
					boxShadow: '0 0 10px rgba(140, 140, 140, 0.5)',
				}}
			>
				{progress}%
			</div>
		</div>
	);
};

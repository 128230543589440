import React from 'react';
import { Col, Row } from 'reactstrap';
import { TReadAlongLessonQuestion } from 'containers/Lessons/utils/types';
import { DropzoneNew } from 'reusableComponents/HookForm/Dropzone';
import { TextAreaNew } from 'reusableComponents/HookForm/TextArea';
import AudioPlayer from '../audioPlayerQuestion';
import { MultilingualAudioUploadField } from '../multilingualAudioUploadField';
import { FieldTranslation } from '../fieldTranslations';

export const readAlongQuestionForm = ({ control, watch, question, oldQuestion, audioData }) => {
	return (
		<>
			<Row className="d-flex">
				<Col className=".col-xs-6">
					<Col sm="12">
						<Row>
							<Col sm="11">
								<TextAreaNew name="completeSentence" label="Complete Sentence:" placeholder="Enter the full sentence" />
								<FieldTranslation
									name="completeSentence"
									control={control}
									oldFieldData={(oldQuestion as TReadAlongLessonQuestion).completeSentence}
								/>
								<MultilingualAudioUploadField name="completeSentence" watch={watch} />
							</Col>
							<Col sm="1">
								<AudioPlayer audioData={audioData?.completeSentence?.[question?.voiceId]} text={question?.voiceId} />
							</Col>
						</Row>
					</Col>
					<Col sm="12">
						<Row>
							<Col sm="11">
								<TextAreaNew
									maxLength={200}
									required
									name="title"
									label="Text to Read:"
									placeholder="Enter the primary text that the user will read aloud."
								/>

								<FieldTranslation name="title" control={control} oldFieldData={oldQuestion?.title} />
								<MultilingualAudioUploadField name="title" watch={watch} />
							</Col>
							<Col sm="1">
								<AudioPlayer audioData={audioData?.title?.[question?.voiceId]} text={question?.voiceId} />
							</Col>
						</Row>
					</Col>
					<Col sm="12">
						<DropzoneNew height="600" width="900" label="Associated Image: (Range of Aspect ratio 1.95 to 2.05)" name="descriptionImage" />
					</Col>
				</Col>
			</Row>
		</>
	);
};

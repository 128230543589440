import { useNavigation } from 'context/navigationContext';
import React, { useState } from 'react';
import { Card, CardBody, Row, Col, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Button } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { isNotEmptyArray, ObjectMaybe, parseQueryParams, showToast, stringifyQueryParams } from 'utils/commonHelpers';
import { SelectFieldNew } from 'reusableComponents/HookForm/Select';
import { FormProvider, useForm } from 'react-hook-form';
import LoadingOverlay from 'reusableComponents/LoadingOverlay';
import DataPaginationTable from 'reusableComponents/Tables/DataPaginationTable';
import Pagination from 'reusableComponents/Pagination/Pagination';
import Filter from 'reusableComponents/Filter';
import { format } from 'date-fns';
import { setApiParams, setPage } from '../VideoSlice';
import { fetchVideos } from '../actions/video.actions';
import { TVideo, VideoTypeOptions } from '../utils/types';

const heads = [
	{
		accessor: 'videoName',
		Header: 'Title',
	},
	{
		accessor: 'videoType',
		Header: 'Video Type',
	},
	{
		accessor: 'mediaConvertStatus',
		Header: 'Media Convert Status',
	},
	{
		accessor: 'mediaConvertJobId',
		Header: 'Media Convert Job Id',
	},
	{
		accessor: 'createdAt',
		Header: 'Created At',
	},
	{
		accessor: 'actions',
		Header: 'Actions',
	},
];

const Actions = ({
	id: videoId,
	videoName,
}: Pick<TVideo, 'id'> & {
	videoName: string;
}) => {
	const [dropdownOpen, setDropdownOpen] = useState(false);
	const toggle = () => setDropdownOpen((prevState) => !prevState);
	const { navigate } = useNavigation();

	return (
		<Dropdown isOpen={dropdownOpen} toggle={toggle} onClick={(e) => e.stopPropagation()}>
			<DropdownToggle caret>Actions</DropdownToggle>
			<DropdownMenu>
				<DropdownItem onClick={() => navigate(`/videos/preview${stringifyQueryParams({ videoId, videoName })}`)}>Watch Video</DropdownItem>
			</DropdownMenu>
		</Dropdown>
	);
};

const createNewRows = ({ videos, onSortOrderChange }: { videos: Array<TVideo>; onSortOrderChange: () => void }) => {
	return videos.map((video, idx) => {
		const { id, videoName, videoType, author, videoUrl, createdAt, mediaConvertStatus, mediaConvertJobId } = video;

		return {
			id,
			videoName,
			videoType,
			author,
			videoUrl,
			createdAt: format(new Date(createdAt), 'dd LLL, yyyy hh:mm a'),
			mediaConvertStatus,
			mediaConvertJobId,
			actions: <Actions id={id} videoName={videoName} />,
		};
	});
};

// list of videos
const VideoList = (props) => {
	const { register, handleSubmit, watch, errors, setValue, control, ...hookFormMethods } = useForm();
	const dispatch = useDispatch();
	const videoType = watch('videoType');

	const videoState = useSelector(({ video }) => ({
		videos: video.videos,
		video: video.video,
		isLoading: video.isLoading,
		error: video.error,
		isSubmitting: video.isSubmitting,
		apiParams: video.apiParams,
		total: video.total,
		page: video.page,
	}));

	const [searchTitle, setSearchTitle] = useState<string>(videoState.apiParams.videoName);

	const [videoRow, setVideoRow] = useState(null);
	const [pageOfItems, setPageOfItems] = useState(1);

	React.useEffect(() => {
		dispatch(
			fetchVideos({
				...videoState.apiParams,
				videoType: videoType?.value,
			})
		);
	}, [videoState.apiParams, videoType]);

	React.useEffect(() => {
		setPageOfItems(videoState.page);
	}, [videoState.page]);

	React.useEffect(() => {
		if (isNotEmptyArray(videoState.videos)) {
			const structuredRows = createNewRows({
				videos: videoState.videos,
				onSortOrderChange,
			});
			setVideoRow(structuredRows);
		} else {
			setVideoRow([]);
		}
	}, [videoState.videos]);

	const onSearch = (e) => {
		// eslint-disable-next-line no-unused-expressions
		e && e.preventDefault();

		dispatch(
			setApiParams({
				...videoState.apiParams,
				videoName: searchTitle,
			})
		);
	};

	const onChangePage = (itemsPage: number) => {
		const { apiParams } = videoState;
		if (itemsPage) {
			dispatch(setPage(itemsPage));
			dispatch(
				setApiParams({
					...apiParams,
					page: itemsPage,
				})
			);
		}
	};

	const onSortOrderChange = () => {
		const { apiParams } = videoState;

		const sortKey = '_id';

		dispatch(
			setApiParams({
				...apiParams,
				sortOrder: apiParams.sortOrder === '-1' ? '1' : '-1',
				sortKey,
			})
		);
	};

	const qp = ObjectMaybe(parseQueryParams(props.location.search));

	return (
		<FormProvider {...{ ...hookFormMethods, register, handleSubmit, watch, errors, setValue, control }}>
			<Card>
				<LoadingOverlay isLoading={videoState.isLoading} />

				<CardBody>
					<Row className="mt-1 mb-4 rounded">
						<Col sm={6}>
							<h3>Videos</h3>
						</Col>
					</Row>

					<Row>
						<Col sm={6}>
							<Filter searchTerm={searchTitle} setSearchTerm={setSearchTitle} onSearch={onSearch} placeholder="Search by title" />
						</Col>
					</Row>

					<Row>
						<Col style={{ maxWidth: '250px' }}>
							<SelectFieldNew label="Select Video Type" name="videoType" options={VideoTypeOptions} isClearable />
						</Col>
					</Row>

					{isNotEmptyArray(videoRow) && (
						<>
							<DataPaginationTable heads={heads} rows={videoRow} onSort={onSortOrderChange} />
							<Pagination
								itemsCount={videoState.total}
								itemsToShow={videoState.apiParams.limit}
								pageOfItems={pageOfItems}
								onChangePage={onChangePage}
							/>
						</>
					)}
					{videoState.total === 0 && <div className="">No data</div>}
				</CardBody>
			</Card>
		</FormProvider>
	);
};

export default VideoList;

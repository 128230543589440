import {
	copyControlLessonsToTestLessons,
	fetchSubtopicLessons,
	prepareLessonAddForm,
	removeSelectedLessons,
	scaleControlOrTestForm,
	toggleTestingLessons,
} from 'containers/Subtopics/actions/subtopics.actions';
import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Card, CardBody, Col, Row } from 'reactstrap';
import Box from 'reusableComponents/Box';
import LoadingOverlay from 'reusableComponents/LoadingOverlay';

import { parseQueryParams, showToast } from 'utils/commonHelpers';
import { CenteredStickyTitle } from './StyledComponents';
import './style.css';
import { LessonAdminUiStateEnum, slimBorder } from './constants';
import { separateLessons } from './helpers/separateLessons';
import { formatLessonDetails } from './helpers/formatLessonCellDetails';
import { createCopyLessonOptions } from './helpers';
import Modal from './Modal/Modal';
import { renderAddEditLessonModal } from './Modal/addEditLessonModal';
import { renderScaleModal } from './Modal/renderScaleModal';
import { TimeRestrictedButton } from './helpers/timeRestrictedButton';

const RankColumn = ({ lessons }) => {
	return (
		<Col
			sm="1"
			style={{
				width: '10px',
			}}
		>
			<Row style={{ borderTop: slimBorder, borderLeft: slimBorder, borderRight: slimBorder }}>
				<Col>
					<CenteredStickyTitle>Rank</CenteredStickyTitle>
				</Col>
			</Row>
			{lessons.map((lesson, lessonIdx) => (
				<Row
					style={{
						height: '170px',
						overflow: 'visible',
						borderTop: slimBorder,
						borderLeft: slimBorder,
						backgroundColor: lesson?.controlLesson?.colorLinked ?? 'white',
					}}
				>
					<Col
						style={{
							padding: 0,
							margin: 0,
							borderRight: slimBorder,
							...(lessonIdx === lessons.length - 1 && {
								borderBottom: slimBorder,
							}),
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
						}}
					>
						{lessonIdx + 1}
					</Col>
				</Row>
			))}
		</Col>
	);
};

const ControlLessonOrder = ({ lessons, rankOptions, isTestingPaused, dispatch, setIsOpenAddEditLessonModal, rankNumber }) => {
	return (
		<Col sm="5">
			<Row style={{ borderTop: slimBorder, borderRight: slimBorder }}>
				<Col>
					<CenteredStickyTitle>Control Lessons</CenteredStickyTitle>
				</Col>
			</Row>
			{lessons.map((lesson, lessonIdx) => (
				<Row
					key={`${lesson?.adminCode ?? ''}${lessonIdx}`}
					style={{
						height: '170px',
						overflow: 'visible',
						borderTop: slimBorder,
						backgroundColor: lesson?.controlLesson?.colorLinked ?? 'white',
					}}
				>
					<Col
						style={{
							padding: 0,
							margin: 0,
							borderRight: slimBorder,
							...(lessonIdx === lessons.length - 1 && {
								borderBottom: slimBorder,
							}),
						}}
					>
						{formatLessonDetails(lesson.controlLesson, 'CONTROL', {
							allowedRanks: rankOptions,
							isTestingPaused,
							lessonIdx,
							dispatch,
							setIsOpenAddEditLessonModal,
							rankNumber,
						})}
					</Col>
				</Row>
			))}
		</Col>
	);
};

const TestLessonOrder = ({ lessons, rankOptions, isTestingPaused, dispatch, setIsOpenAddEditLessonModal, rankNumber, watchMoveToTest }) => {
	const addTestLessonHandler = () => {
		// all test lessons adminCode
		const testLessonAdminCode = lessons?.map((lesson) => lesson?.testLesson?.adminCode).filter((code) => code);
		// selected options
		const selectedOptionsAdminCode = Object.entries(watchMoveToTest ?? {}).reduce((acc, [key, option]) => {
			if ((option as Record<string, number>)?.value) {
				acc.push(key);
			}
			return acc;
		}, []);

		const rightColumnLength = lessons.length;
		const extraCopiedLength = selectedOptionsAdminCode
			.filter((code) => !testLessonAdminCode.includes(code))
			.map((code) => {
				return lessons.find((lesson) => lesson?.controlLesson?.adminCode === code)?.controlLesson;
			}).length;

		dispatch(
			prepareLessonAddForm({
				rankNumber,
				rankDecimal: rightColumnLength + extraCopiedLength + 1,
			})
		);
		setIsOpenAddEditLessonModal(true);
	};

	return (
		<Col sm="6">
			<Row style={{ borderTop: slimBorder, borderRight: slimBorder, backgroundColor: '#FCFFAB' }}>
				<Col>
					<CenteredStickyTitle>Test Lessons</CenteredStickyTitle>
				</Col>
			</Row>
			{lessons.map((lesson, lessonIdx) => (
				<Row
					key={`${lesson?.adminCode ?? ''}${lessonIdx}`}
					style={{
						height: '170px',
						overflow: 'visible',
						borderTop: slimBorder,
						borderRight: slimBorder,
						backgroundColor: lesson?.testLesson?.colorLinked ?? 'white',
					}}
				>
					<Col
						style={{
							padding: 0,
							margin: 0,
							...(lessonIdx === lessons.length - 1 && {
								borderBottom: slimBorder,
							}),
						}}
					>
						{formatLessonDetails(lesson.testLesson, 'TEST', {
							allowedRanks: rankOptions,
							isTestingPaused,
							lessonIdx,
							dispatch,
							setIsOpenAddEditLessonModal,
							rankNumber,
						})}
					</Col>
				</Row>
			))}
			{/* extra copied lessons */}
			{(() => {
				// all test lessons adminCode
				const testLessonAdminCode = lessons?.map((lesson) => lesson?.testLesson?.adminCode).filter((code) => code);
				// selected options
				const selectedOptionsAdminCode = Object.entries(watchMoveToTest ?? {}).reduce((acc, [key, option]) => {
					if ((option as Record<string, number>)?.value) {
						acc.push(key);
					}
					return acc;
				}, []);

				return selectedOptionsAdminCode
					.filter((code) => !testLessonAdminCode.includes(code))
					.map((code) => {
						return lessons.find((lesson) => lesson?.controlLesson?.adminCode === code)?.controlLesson;
					})
					.sort((a, b) => watchMoveToTest[a.adminCode].value - watchMoveToTest[b.adminCode].value)
					.map((lesson, lessonIdx) => {
						return (
							<Row
								key={`${lesson?.adminCode ?? ''}${lessonIdx}`}
								style={{
									height: '160px',
									overflow: 'visible',
									border: slimBorder,
									// backgroundColor: lesson?.testLesson?.colorLinked ?? 'white',
								}}
							>
								<Col style={{ padding: 0, margin: 0 }}>
									{formatLessonDetails(
										{
											...(lesson ?? {}),
											uiState: LessonAdminUiStateEnum.REFLECTION,
										},
										'TEST',
										{
											allowedRanks: rankOptions,
											isTestingPaused,
											lessonIdx,
											dispatch,
											setIsOpenAddEditLessonModal,
											rankNumber,
										}
									)}
								</Col>
							</Row>
						);
					});
			})()}
			<Row
				style={{
					padding: '12px',
				}}
			>
				<Col
					style={{
						display: 'flex',
						justifyContent: 'center',
					}}
				>
					<Button disabled={!isTestingPaused} type="submit" onClick={addTestLessonHandler}>
						Add Test Lesson
					</Button>
				</Col>
			</Row>
		</Col>
	);
};

export const SubtopicLessonOrdering = (props) => {
	const dispatch = useDispatch();
	const { subtopicAdminCode } = parseQueryParams(props.location.search) ?? {};
	const hookFormMethods = useForm();
	const watchMoveToTest = hookFormMethods.watch('moveToTest');
	const watchRemoveLesson = hookFormMethods.watch('removeLesson');

	const { lessons, lessonsDb, additionalData, rankNumber, subtopicInfo, isLoading } = useSelector(({ subtopic }) => ({
		lessonsDb: subtopic.lessons,
		lessons: separateLessons(subtopic.lessons, watchMoveToTest),
		isLoading: subtopic.isLoading,
		additionalData: subtopic.additionalData,
		rankNumber: subtopic.rankNumber,
		subtopicInfo: subtopic.additionalData?.subtopic,
	}));
	// states
	const [isOpenAddEditLessonModal, setIsOpenAddEditLessonModal] = useState(false);
	const [isOpenScaleModal, setIsOpenScaleModal] = useState(false);

	const isTestingPaused = !lessonsDb.filter((lesson) => lesson.bucket === 'A').some((lesson) => lesson.status === 'ACTIVE');

	const rankOptions = createCopyLessonOptions({
		lessons,
	});

	useEffect(() => {
		if (subtopicAdminCode) {
			dispatch(fetchSubtopicLessons({ subtopicAdminCode }));
		}
	}, [dispatch, subtopicAdminCode]);

	useEffect(() => {
		// validate move to test lessons selected options
		const selectedOptions = Object.entries(watchMoveToTest ?? {}).reduce((acc, [key, option]) => {
			if ((option as Record<string, number>)?.value) {
				acc.push({
					key,
					value: (option as Record<string, number>).value,
				});
			}
			return acc;
		}, []);

		// check if same rank selected more than once
		const uniqueRanks = Array.from(new Set(selectedOptions.map((option) => option.value)));
		if (selectedOptions.length !== uniqueRanks.length) {
			// find lessons with similar selected rank and clear them from form
			const similarOptions = selectedOptions.sort((a, b) => b.value - a.value);

			for (let i = 0; i < similarOptions.length - 1; i += 1) {
				if (similarOptions[i].value === similarOptions[i + 1].value) {
					hookFormMethods.setValue(`moveToTest[${similarOptions[i + 1].key}]`, null);
				}
			}

			return showToast('error', `Please select a different rank`);
		}
	}, [watchMoveToTest]);

	const onSubmit = async (data) => {
		console.log(308, data);
	};

	const removeLessonsHandler = () => {
		const lessonsToRemove = Object.entries(watchRemoveLesson ?? {})
			.map(([adminCode, _]) => {
				return adminCode;
			})
			.filter((adminCode) => watchRemoveLesson?.[adminCode]);

		dispatch(
			removeSelectedLessons({
				subtopicAdminCode,
				lessonsToRemove,
			})
		);
	};

	const copyLessonsHandler = () => {
		const lessonsCodeAndRank = Object.entries(watchMoveToTest)
			.map(([adminCode, option]) => {
				return {
					adminCode,
					rankDecimal: (option as Record<string, number>)?.value,
				};
			})
			.filter((selectedLesson) => selectedLesson.rankDecimal);

		if (lessonsCodeAndRank.length === 0) {
			return showToast('warn', 'No lessons selected for copy');
		}

		dispatch(
			copyControlLessonsToTestLessons({
				subtopicAdminCode,
				lessonsCodeAndRank,
			})
		);
	};

	const toggleTestingHandler = () => {
		dispatch(
			toggleTestingLessons({
				subtopicAdminCode,
				isTestingPaused,
			})
		);
	};

	return (
		<>
			<Card style={{ minHeight: '90vh' }}>
				<LoadingOverlay isLoading={isLoading} />
				<CardBody>
					<h3>Subtopic Lesson Ordering</h3>
					<Box w="100%" mt="1rem" mr="1rem" ml="0.5rem" mb="2rem">
						<Row>
							<Col sm={5} />
							{subtopicInfo && (
								<Col sm={7}>
									<h5>
										<b>Subtopic : </b> {subtopicInfo?.title?.en}
									</h5>
									<h5>
										<b>Description : </b> {subtopicInfo?.description?.en}
									</h5>
									<h5>
										<b>Admin Code : </b> {subtopicInfo?.adminCode}
									</h5>
									<h5>
										<b>Rank Number : </b> {subtopicInfo?.rankNumber}
									</h5>
								</Col>
							)}
						</Row>
					</Box>
					<Box style={{ marginTop: '20px', display: 'flex', width: '100%', justifyContent: 'end' }}>
						<TimeRestrictedButton
							text={isTestingPaused ? 'Enable Testing' : 'Disable Testing'}
							onClick={toggleTestingHandler}
							timeRange={
								process.env.REACT_APP_CUSTOM_NODE_ENV === 'production'
									? {
											startHour: 23,
											startMinute: 45,
											endHour: 0,
											endMinute: 0,
									  }
									: {
											startHour: -1,
											startMinute: -1,
											endHour: -1,
											endMinute: -1,
									  }
							}
							style={{
								backgroundColor: isTestingPaused ? '#17bd24' : '#f56351',
								color: 'white',
							}}
						/>
					</Box>
					<Box>
						<p
							style={{
								color: isTestingPaused ? 'green' : 'red',
								fontSize: '20px',
								fontWeight: 'bold',
							}}
						>
							{' '}
							{isTestingPaused
								? 'Test lessons are paused, you may make changes to the lessons'
								: 'Testing lessons are live now, you must pause testing before making any changes to lessons'}
						</p>
					</Box>
					<br />
					<br />
					<FormProvider {...hookFormMethods}>
						<form className="form ltr-support" onSubmit={hookFormMethods.handleSubmit(onSubmit)}>
							<Box
								style={{
									width: '100%',
									display: 'flex',
									justifyContent: 'center',
								}}
							>
								<Row
									style={{
										borderBottom: 'none',
										width: '100%',
									}}
								>
									{/* rank */}
									{RankColumn({
										lessons,
									})}

									{/* left half */}
									{ControlLessonOrder({
										lessons,
										rankOptions,
										isTestingPaused,
										dispatch,
										setIsOpenAddEditLessonModal,
										rankNumber,
									})}

									{/* right half */}
									{TestLessonOrder({
										lessons,
										rankOptions,
										isTestingPaused,
										dispatch,
										setIsOpenAddEditLessonModal,
										rankNumber,
										watchMoveToTest,
									})}
								</Row>
							</Box>
							<Box style={{ marginTop: '20px', display: 'flex', width: '100%', justifyContent: 'start' }}>
								<Button
									disabled={!isTestingPaused}
									type="button"
									onClick={removeLessonsHandler}
									style={{
										backgroundColor: '#f56351',
										color: 'white',
									}}
								>
									Remove Selected Lessons
								</Button>
								<Button
									disabled={!isTestingPaused}
									type="button"
									style={{
										backgroundColor: '#d1f1ff',
									}}
									onClick={copyLessonsHandler}
								>
									Copy Selected Lessons
								</Button>
							</Box>

							{!isTestingPaused && (
								<Box style={{ marginTop: '20px', display: 'flex', width: '100%', justifyContent: 'start' }}>
									<TimeRestrictedButton
										text="Scale Control"
										onClick={() => {
											dispatch(
												scaleControlOrTestForm({
													bucket: 'CONTROL',
													subtopicAdminCode,
												})
											);
											setIsOpenScaleModal(true);
										}}
										timeRange={
											process.env.REACT_APP_CUSTOM_NODE_ENV === 'production'
												? {
														startHour: 23,
														startMinute: 45,
														endHour: 0,
														endMinute: 0,
												  }
												: {
														startHour: -1,
														startMinute: -1,
														endHour: -1,
														endMinute: -1,
												  }
										}
									/>
									<br />
									<br />
									<TimeRestrictedButton
										text="Scale Test"
										onClick={() => {
											dispatch(
												scaleControlOrTestForm({
													bucket: 'TEST',
													subtopicAdminCode,
												})
											);
											setIsOpenScaleModal(true);
										}}
										timeRange={
											process.env.REACT_APP_CUSTOM_NODE_ENV === 'production'
												? {
														startHour: 23,
														startMinute: 45,
														endHour: 0,
														endMinute: 0,
												  }
												: {
														startHour: -1,
														startMinute: -1,
														endHour: -1,
														endMinute: -1,
												  }
										}
									/>
								</Box>
							)}

							{/* modals */}
							{/* lesson add or edit model */}
							<Modal isOpen={isOpenAddEditLessonModal} onClose={() => setIsOpenAddEditLessonModal(false)}>
								{renderAddEditLessonModal({
									subtopicAdminCode,
									additionalData,
									setIsOpenAddEditLessonModal,
									dispatch,
								})}
							</Modal>
							{/* scaling modal */}
							<Modal isOpen={isOpenScaleModal} onClose={() => setIsOpenScaleModal(false)}>
								{renderScaleModal({
									lessons,
									subtopicAdminCode,
									additionalData,
									setIsOpenScaleModal,
									dispatch,
								})}
							</Modal>
						</form>
					</FormProvider>
				</CardBody>
			</Card>
		</>
	);
};

import { showToast } from 'utils/commonHelpers';
import { fetchWrapper } from 'utils/fetchWrapper';
import { DocumentStatusEnum } from 'utils/constants';
import { TReel } from './types';

export const addReel = async (data: TReel, createdBy: string) => {
	try {
		const response = await fetchWrapper('/admin/reel/add-reel', {
			method: 'POST',
			body: {
				caption: data.caption,
				videoId: data.video.id,
				creator: data.creator,
				cta: data.cta,
				status: DocumentStatusEnum.DRAFT,
				createdBy,
			},
		});

		if (response.status !== 200) {
			showToast('error', response.errors.map((e) => e.message).join(', '));
			return;
		}

		if (response?.data?.reelId) {
			showToast('success', 'Reel added successfully');
			return response.data.reelId;
		}

		showToast('error', 'Error adding reel');
		return;
	} catch (error) {
		showToast('error', error.message);
	}
};

export const updateReel = async (data: TReel, reelId: string, updatedBy: string) => {
	try {
		const response = await fetchWrapper('/admin/reel/update-reel', {
			method: 'POST',
			body: {
				reelId,
				caption: data?.caption,
				videoId: data?.video?.id,
				status: data?.status,
				creator: data?.creator,
				cta: data?.cta,
				updatedBy,
			},
		});

		if (response.status !== 200) {
			showToast('error', response.errors.map((e) => e.message).join(', '));
			return;
		}

		showToast('success', 'Reel updated successfully');

		return response.data;
	} catch (error) {
		showToast('error', error.message);
	}
};

export const getReelData = async (reelId: string): Promise<TReel | undefined> => {
	try {
		const response = await fetchWrapper(`/admin/reel/fetch-reel?id=${reelId}`, {
			method: 'GET',
		});

		if (response.status !== 200) {
			showToast('error', response.errors.map((e) => e.message).join(', '));
			return;
		}

		const { data } = response;
		return data as TReel;
	} catch (err) {
		showToast('error', err.message);
	}
};

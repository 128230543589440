import { createSlice, CaseReducer, PayloadAction } from '@reduxjs/toolkit';
import { TVideoCourseState } from './utils/types';
import { fetchVideoCourseById, fetchVideoCourses } from './actions/videoCourses.actions';

type TVideoCourseReducerType<T> = CaseReducer<TVideoCourseState, PayloadAction<T>>;

type TVideoCourseReducers = {
	setIsLoading: TVideoCourseReducerType<void>;
	setIsLoaded: TVideoCourseReducerType<void>;
	setError: TVideoCourseReducerType<string>;
	setApiParams: TVideoCourseReducerType<TVideoCourseState['apiParams']>;
	setPage: TVideoCourseReducerType<number>;
	toggleIsSubmitting: TVideoCourseReducerType<{ isSubmitting: boolean }>;
};

const VideoCourseSlice = createSlice<TVideoCourseState, TVideoCourseReducers>({
	name: 'videoCourses',
	initialState: {
		videoCourses: [],
		videoCourse: null,
		isLoading: false,
		error: null,
		page: 1,
		total: 10,
		isSubmitting: false,
		apiParams: {
			limit: 10,
			page: 1,
			title: '',
			sortKey: 'createdAt',
			sortOrder: '-1',
		},
	},
	reducers: {
		setIsLoading: (state) => {
			state.isLoading = true;
		},
		setIsLoaded: (state) => {
			state.isLoading = false;
		},
		setError: (state, action) => {
			state.error = action?.payload;
		},
		setApiParams: (state, action) => {
			state.apiParams = {
				...(action.payload.limit && { limit: action.payload.limit }),
				...(action.payload.page !== undefined && { page: action.payload.page }),
				...(action.payload.sortKey !== undefined && { sortKey: action.payload.sortKey }),
				...(action.payload.sortOrder !== undefined && { sortOrder: action.payload.sortOrder }),
				...(typeof action.payload.title !== 'undefined' && { title: action.payload.title }),
				...(action.payload.filters && { filters: action.payload.filters }),
			};
		},
		setPage: (state, action) => {
			state.page = action.payload;
		},
		toggleIsSubmitting: (state, action) => {
			state.isSubmitting = action.payload.isSubmitting;
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(fetchVideoCourses.pending, (state) => {
				state.isLoading = true;
			})
			.addCase(fetchVideoCourses.fulfilled, (state, { payload }) => {
				if (payload?.videoCourses && Array.isArray(payload.videoCourses)) {
					state.videoCourses = payload.videoCourses;
					state.total = payload.totalDocuments;
				}
				state.isLoading = false;
			})

			.addCase(fetchVideoCourseById.pending, (state) => {
				state.isLoading = true;
			})
			.addCase(fetchVideoCourseById.fulfilled, (state, { payload }) => {
				if (payload) {
					state.videoCourse = payload;
				}
				state.isLoading = false;
			});
	},
});

export const { setApiParams, setIsLoading, setIsLoaded, setPage, toggleIsSubmitting } = VideoCourseSlice.actions;

export default VideoCourseSlice.reducer;

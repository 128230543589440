export const removeNumbers = (arr1: number[], arr2: number[]): number[] => {
	const set2 = new Set(arr2);
	return arr1.filter((num) => !set2.has(num));
};

export const createCopyLessonOptions = ({ lessons }) => {
	const maxRank = Math.max(...lessons.map((item) => item.rank));

	return Array.from(
		new Set([
			...lessons.map((lesson) => (!lesson?.testLesson ? lesson.rank : -1)).filter((rank) => rank !== -1),
			...Array.from({ length: 6 }, (_, i) => maxRank + i + 1),
		])
	).sort((a, b) => a - b);
};

import { createAsyncThunk } from '@reduxjs/toolkit';
import { setError } from 'containers/App/globalSlice';
import { showToast, stringifyQueryParams } from 'utils/commonHelpers';
import { fetchWrapper } from 'utils/fetchWrapper';
import { getLessonQuestion } from '../utils/helpers';
import { ContentDocumentStatusEnum, TLessonsState, TQuestion } from '../utils/types';

export const fetchLessonQuestions = createAsyncThunk(
	'/lessons/fetchLessonQuestions',
	async (
		apiParams: TLessonsState['apiParams'] & { lessonId?: string; subtopicId?: string; questionType?: string } & {
			parentId?: string;
			siblingId?: string;
		},
		{ dispatch }
	) => {
		try {
			const response = await fetchWrapper(`/admin/get-lesson-questions`, {
				method: 'POST',
				body: apiParams,
			});

			const { data } = response;
			if (response.status !== 200) {
				dispatch({ type: setError.type, payload: { errors: response.errors } });
			}

			return data;
		} catch (error) {
			showToast('error', error.message);
		}
	}
);

export const reorderLessonQuestions = createAsyncThunk(
	'/lessons/reorderLessonQuestions',
	async ({ currentIdx, move, lessonId, lang }: { currentIdx: number; move: string; lessonId?: string; lang: string }, { dispatch }) => {
		try {
			const response = await fetchWrapper(`/admin/reorder-lesson-questions`, {
				method: 'POST',
				body: { currentIdx, move, lessonId, lang },
			});
			const { data } = response;
			dispatch(
				fetchLessonQuestions({
					limit: 50,
					skip: 0,
					query: '',
					sortKey: 'score',
					sortOrder: '-1',
					lessonId,
				})
			);
			if (response.status !== 200) {
				dispatch({ type: setError.type, payload: { errors: response.errors } });
			}

			return data;
		} catch (error) {
			showToast('error', error.message);
		}
	}
);

export const setLessonStatus = createAsyncThunk(
	'/lessons/setLessonStatus',
	async (
		apiParams: {
			lessonId: string;
			status: ContentDocumentStatusEnum;
			postToggleStatus;
		},
		{ dispatch }
	) => {
		try {
			const response = await fetchWrapper('/admin/set-lesson-status', {
				method: 'PUT',
				body: apiParams,
			});
			const { data } = response;
			if (response.status !== 200) {
				dispatch({ type: setError.type, payload: { errors: response.errors } });
				return null;
			}

			showToast('success', `Updated status of Lesson to ${apiParams.status}`);
			apiParams.postToggleStatus({
				lessonId: apiParams.lessonId,
			});
			if (data) return { isUpdated: data.isUpdated, status: data.isUpdated ? apiParams.status : null };
			return null;
		} catch (error) {
			showToast('error', error.message);
		}
	}
);

export const fetchLessonQuestion = createAsyncThunk(
	'/lessons/fetchLessonQuestion',
	async (
		apiParams: {
			questionId?: string;
			questionAdminCode?: string;
		},
		{ dispatch }
	) => {
		try {
			const response = await getLessonQuestion({ apiParams });
			const { error, lessonQuestion } = response;
			if (response.error) {
				dispatch({ type: setError.type, payload: { errors: error } });
				return null;
			}
			return { lessonQuestion };
		} catch (error) {
			showToast('error', error.message);
		}
	}
);

export const fetchLesson = createAsyncThunk(
	'/lessons/fetchLesson',
	async (
		apiParams: {
			lessonId: string;
		},
		{ dispatch }
	) => {
		try {
			const response = await fetchWrapper('/admin/get-lesson-data', {
				method: 'POST',
				body: apiParams,
			});
			const { data } = response;
			if (response.status !== 200) {
				dispatch({ type: setError.type, payload: { errors: response.errors } });
				return null;
			}
			return { lesson: data };
		} catch (error) {
			showToast('error', error.message);
		}
	}
);

export const updateLessonQuestion = createAsyncThunk(
	'/lessons/updateLessonQuestion',
	async (
		apiParams: {
			questionId: string;
			lessonId: string;
			hang: string;
			updatedQuestion: Partial<TQuestion>;
			mode: string;
			userId: string;
			navigate: any;
		},
		{ dispatch }
	) => {
		try {
			const isEditMode = apiParams.mode === 'edit';
			delete apiParams.mode;
			const response = await fetchWrapper('/admin/add-or-update-lesson-question', {
				method: 'PUT',
				body: {
					...apiParams,
					edit: isEditMode,
					hang: Boolean(apiParams.hang),
				},
			});

			const { data } = response;

			if (response.status !== 200) {
				dispatch({ type: setError.type, payload: { errors: response.errors } });
				return null;
			}

			showToast('success', 'Lesson question successfully updated');

			if (apiParams.hang !== 'true') {
				if (!isEditMode) {
					apiParams.navigate(`/lessons/questions/list${stringifyQueryParams({ lessonId: apiParams.lessonId })}`);
				} else {
					dispatch(fetchLesson({ lessonId: apiParams.lessonId }));
					dispatch(fetchLessonQuestion({ questionId: apiParams.questionId }));
				}
			} else if (!isEditMode) {
				apiParams.navigate(`/lessons/hanging-questions/list`);
			}

			return { updatedQuestion: data };
		} catch (error) {
			showToast('error', error.message);
		}
	}
);

export const toggleLessonQuestionStatus = createAsyncThunk(
	'/lessons/toggleLessonQuestionStatus',
	async (
		apiParams: {
			questionId: string;
			lessonId: string;
			postStatusChange;
		},
		{ dispatch }
	) => {
		try {
			const response = await fetchWrapper('/admin/toggle-lesson-question-status', {
				method: 'PUT',
				body: apiParams,
			});

			if (response.status !== 200) {
				dispatch({ type: setError.type, payload: { errors: response.errors } });
				return false;
			}

			apiParams.postStatusChange();
			showToast('success', 'Lesson question successfully updated');
			return true;
		} catch (error) {
			showToast('error', error.message);
		}
	}
);

export const getAllCourses = createAsyncThunk('/lessons/getAllCourses', async (apiParams, { dispatch }) => {
	try {
		const response = await fetchWrapper(`/v1/admin/get-courses`, {
			method: 'GET',
		});
		const { data } = response;
		if (response.status !== 200) {
			dispatch({ type: setError.type, payload: { errors: response.errors } });
		}

		return data;
	} catch (error) {
		showToast('error', error.message);
	}
});

export const deleteLessonQuestion = createAsyncThunk(
	'/lessons/deleteLessonQuestion',
	async (
		apiParams: {
			questionId: string;
			lessonId: string;
			lang: string;
		},
		{ dispatch }
	) => {
		try {
			// eslint-disable-next-line no-restricted-globals, no-alert
			const userConfirmed = confirm('Are you sure you want to delete this question? \n\n Note: This action cannot be reverted');

			// Check the user's response
			if (userConfirmed) {
				const response = await fetchWrapper('/admin/delete-lesson-question', {
					method: 'POST',
					body: apiParams,
				});

				if (response.status !== 200) {
					dispatch({ type: setError.type, payload: { errors: response.errors } });
					showToast('error', 'Some trouble deleting the question');
					return false;
				}

				showToast('success', 'Question deleted successfully');
			}

			dispatch(
				fetchLessonQuestions({
					limit: 50,
					skip: 0,
					query: '',
					sortKey: 'score',
					sortOrder: '-1',
					lessonId: apiParams.lessonId,
				})
			);
			return true;
		} catch (error) {
			showToast('error', error.message);
		}
	}
);

import { LanguageCodesEnum } from 'containers/Lessons/utils/constants';
import { TMultilingualVideo } from 'containers/Lessons/utils/types';
import { fetchVideoById } from 'utils/videoHelpers/fetchVideoApiWrapper';

export type TFieldVideo = {
	languageCode: {
		label: string;
		value: LanguageCodesEnum;
	};
	video: {
		label: string; // video title
		value: string; // video id
	};
};

// convert field videos to multilingual video
export const convertFieldVideoToMultilingualVideo = (fieldVideos: Array<TFieldVideo>): TMultilingualVideo => {
	if (!fieldVideos) {
		return {};
	}

	const multilingualVideo: TMultilingualVideo = {};

	fieldVideos.forEach((fieldVideo) => {
		const { languageCode, video } = fieldVideo;

		if (languageCode?.value && video?.value) {
			multilingualVideo[languageCode.value] = {
				videoId: video?.value,
			};
		}
	});

	return multilingualVideo;
};

// convert multilingual video to field videos
// fetches video details for each video id
// USAGE: for Field Video component
export const convertMultilingualVideoToFieldVideo = async (multilingualVideo: TMultilingualVideo): Promise<Array<TFieldVideo>> => {
	let fieldVideos: Array<TFieldVideo> = [];

	if (!multilingualVideo) {
		return fieldVideos;
	}

	const languageCodes = Object.keys(multilingualVideo);

	fieldVideos = await Promise.all(
		languageCodes.map(async (languageCode) => {
			const video = multilingualVideo[languageCode];

			// Fetch video details in parallel
			const videoDetails = await fetchVideoById(video?.videoId);
			if (!videoDetails) {
				throw new Error(`Failed to fetch video details for ${video?.videoId}`); // Proper error handling
			}

			return {
				languageCode: {
					label: languageCode,
					value: languageCode as LanguageCodesEnum,
				},
				video: {
					label: videoDetails?.videoName,
					value: video?.videoId,
				},
			};
		})
	);

	return fieldVideos;
};

import { showToast } from 'utils/commonHelpers';
import { fetchWrapper } from 'utils/fetchWrapper';
import { DocumentStatusEnum } from 'utils/constants';
import { TVideoCourse } from './types';

export const addVideoCourse = async (data: TVideoCourse, createdBy: string) => {
	try {
		const response = await fetchWrapper('/admin/video-course/add-video-course', {
			method: 'POST',
			body: {
				title: data.title,
				videoId: data.video.id,
				status: DocumentStatusEnum.DRAFT,
				description: data.description,
				createdBy,
			},
		});

		if (response.status !== 200) {
			showToast('error', response.errors.map((e) => e.message).join(', '));
			return;
		}

		if (response?.data?.videoCourseId) {
			showToast('success', 'video course added successfully');
			return response.data.videoCourseId;
		}

		showToast('error', 'Error adding video course');
		return;
	} catch (error) {
		showToast('error', error.message);
	}
};

export const updateVideoCourse = async (data: TVideoCourse, videoCourseId: string, updatedBy: string) => {
	try {
		const response = await fetchWrapper('/admin/video-course/update-video-course', {
			method: 'POST',
			body: {
				videoCourseId,
				title: data?.title,
				description: data?.description,
				videoId: data?.video?.id,
				status: data?.status,
				updatedBy,
			},
		});

		if (response.status !== 200) {
			showToast('error', response.errors.map((e) => e.message).join(', '));
			return;
		}

		showToast('success', 'Video course updated successfully');

		return response.data;
	} catch (error) {
		showToast('error', error.message);
	}
};

export const getVideoCourseData = async (videoCourseId: string): Promise<TVideoCourse | undefined> => {
	try {
		const response = await fetchWrapper(`/admin/video-course/fetch-video-course?id=${videoCourseId}`, {
			method: 'GET',
		});

		if (response.status !== 200) {
			showToast('error', response.errors.map((e) => e.message).join(', '));
			return;
		}

		const { data } = response;
		return data as TVideoCourse;
	} catch (err) {
		showToast('error', err.message);
	}
};

/* eslint-disable @typescript-eslint/ban-types */
import React, { useState, useMemo } from 'react';
import { Column } from 'react-table';
import styled from 'styled-components';

const Wrapper = styled.div`
	transform: scale(0.9);
	transform-origin: top left;
	width: 111.11%; /* Compensate for the scaling */
	height: 111.11%; /* Compensate for the scaling */
	min-height: 60vh;
	overflow: auto;
	table {
		width: 100%;
		border-collapse: collapse;
		table-layout: fixed; /* Fixes column width across rows */
	}
	td,
	th {
		border: 1px solid #ddd;
		padding: 8px;
		word-wrap: break-word;
	}
	.nested-table {
		width: 100%;
		border-collapse: collapse;
	}
	.nested-table td,
	.nested-table th {
		// border: none; /* Remove borders for nested table */
		padding-left: 0;
		padding-right: 0;
	}
`;

interface CustomColKeys {
	disableSort?: boolean;
}

export type SortOrder = 'ASC' | 'DESC';
export type TableHeads<T extends object = {}> = Array<Column<T> & CustomColKeys>;
export type TableRows<T extends object = {}> = Array<Record<keyof T, React.ReactNode>>;

export interface DataPaginationTableProps<T extends object = {}> {
	heads: TableHeads<T>;
	rows: TableRows<T>;
	onSort?: (sortColumn: keyof T, sortDirection: SortOrder) => any;
	excludedSortColumns?: string[];
}

const LessonListPaginationTable: React.FC<DataPaginationTableProps<Record<string, any>>> = ({
	heads: columns,
	rows: data,
	onSort,
	excludedSortColumns = [],
}) => {
	const [sortOrder, setSortOrder] = useState<SortOrder>('ASC');
	const [sortedCol, setSortedCol] = useState('');

	// Group data by rank
	const groupedData = useMemo(() => {
		const groups = new Map();
		data.forEach((row) => {
			const { rank, courseAdminCode, rankNumber, rankDecimal } = row;

			const key = { unique: `${courseAdminCode}-${rank}`, courseAdminCode, rankNumber, rankDecimal };

			let existingKey = Array.from(groups.keys()).find((k) => k.unique === key.unique);

			if (!existingKey) {
				groups.set(key, []);
				existingKey = key;
			}
			groups.get(existingKey).push(row);
		});
		return Array.from(groups.entries()).sort((a, b) => a[0].rankNumber - b[0].rankNumber || a[0].rankDecimal - b[0].rankDecimal); // sort based on rank
	}, [data]);

	const sortCol = (col: string) => {
		if (onSort && !excludedSortColumns.includes(col)) {
			onSort(col, sortOrder);
			setSortOrder(sortOrder === 'ASC' ? 'DESC' : 'ASC');
			setSortedCol(col);
		}
	};

	return (
		<Wrapper>
			<div className="table">
				<table>
					<thead>
						<tr>
							{columns.map((column: any, colIdx: number) => (
								<th
									style={{
										...(colIdx === 0 && {
											width: '40px',
											margin: 0,
											padding: '3px',
										}),
									}}
									key={column.accessor}
									onClick={() => !column.disableSort && sortCol(column.accessor)}
								>
									{column.Header}
									{sortedCol === column.accessor && <span>{sortOrder === 'ASC' ? ' \u2191' : ' \u2193'}</span>}
								</th>
							))}
						</tr>
					</thead>
					<tbody>
						{groupedData.map(([key, rows], idx) => (
							<tr
								key={key.unique}
								style={{
									...(idx !== 0
										? {
												borderLeft: '2px solid #ff90bd',
												borderRight: '2px solid #ff90bd',
												borderBottom: '2px solid #ff90bd',
												borderTop: 'none',
										  }
										: {
												border: '2px solid #ff90bd',
												borderTop: '3px solid #ff90bd',
										  }),
								}}
							>
								<td colSpan={columns.length} style={{ padding: 0, margin: 0 }}>
									<div className="nested-table">
										<table>
											<tbody>
												{rows.map((row, i) => {
													if (i === 0) {
														row.no = idx + 1;
													} else {
														row.no = '';
													}
													return (
														<tr key={row.adminCode} style={{ backgroundColor: row?.bucket === 'A' ? '#FCFFAB' : '#fff' }}>
															{columns.map((col: any, colIdx: number) => (
																<td
																	style={{
																		padding: '10px',
																		...(colIdx === 0 && {
																			border: 'none',
																			width: '40px',
																			backgroundColor: 'white',
																		}),
																	}}
																	key={col.accessor}
																>
																	{row[col.accessor]}
																</td>
															))}
														</tr>
													);
												})}
											</tbody>
										</table>
									</div>
								</td>
							</tr>
						))}
					</tbody>
				</table>
			</div>
		</Wrapper>
	);
};

export default LessonListPaginationTable;

import { useNavigation } from 'context/navigationContext';
import React, { useState } from 'react';
import { Card, CardBody, Row, Col, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Button } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { isNotEmptyArray, ObjectMaybe, parseQueryParams, showToast, stringifyQueryParams } from 'utils/commonHelpers';
import { FormProvider, useForm } from 'react-hook-form';
import LoadingOverlay from 'reusableComponents/LoadingOverlay';
import DataPaginationTable from 'reusableComponents/Tables/DataPaginationTable';
import Pagination from 'reusableComponents/Pagination/Pagination';
import Filter from 'reusableComponents/Filter';
import { format } from 'date-fns';
import { DocumentStatusEnum } from 'utils/constants';
import { setApiParams, setPage } from '../ReelSlice';
import { TReel } from '../utils/types';
import { fetchReels } from '../actions/reels.actions';
import { updateReel } from '../utils/reelEditFetchHelpers';
import StatusDropDownItem from './StatusDropDownItem';

const heads = [
	{
		accessor: 'caption',
		Header: 'Caption',
	},
	{
		accessor: 'viewCount',
		Header: 'View Count',
	},
	{
		accessor: 'likesCount',
		Header: 'Like Count',
	},
	{
		accessor: 'status',
		Header: 'Status',
	},
	{
		accessor: 'actions',
		Header: 'Actions',
	},
];

// Reel Status Update Wrapper
async function UpdateReelStatus({
	reelId,
	status,
	updatedBy,
	dispatch,
	apiParams,
}: {
	reelId: string;
	status: DocumentStatusEnum;
	updatedBy: string;
	dispatch: any;
	apiParams: any;
}) {
	await updateReel(
		{
			status,
		},
		reelId,
		updatedBy
	);

	// dispatch action to update the reels
	dispatch(
		fetchReels({
			...apiParams,
		})
	);
}

const Actions = ({
	videoId,
	id,
	status,
	loggedInUserId,
	totalReels,
	dispatch,
	apiParams,
}: Pick<TReel, 'id'> & {
	videoId: string;
	status: DocumentStatusEnum;
	loggedInUserId: string;
	totalReels: number;
	dispatch: any;
	apiParams: any;
}) => {
	const [dropdownOpen, setDropdownOpen] = useState(false);
	const toggle = () => setDropdownOpen((prevState) => !prevState);
	const { navigate } = useNavigation();

	return (
		<Dropdown isOpen={dropdownOpen} toggle={toggle} onClick={(e) => e.stopPropagation()}>
			<DropdownToggle caret>Actions</DropdownToggle>
			<DropdownMenu>
				<>
					<DropdownItem
						onClick={() => navigate(`/reels/add-edit${stringifyQueryParams({ id, hang: 'true', mode: 'edit' })}`)}
						disabled={false}
					>
						Edit Reel
					</DropdownItem>
					<DropdownItem onClick={() => navigate(`/videos/preview${stringifyQueryParams({ videoId })}`)} disabled={false}>
						Watch Reel
					</DropdownItem>

					<StatusDropDownItem
						status={status}
						updateReelStatus={UpdateReelStatus}
						reelId={id}
						updatedBy={loggedInUserId}
						dispatch={dispatch}
						apiParams={apiParams}
						disabled={false}
					/>
				</>
			</DropdownMenu>
		</Dropdown>
	);
};

const createNewRows = ({
	reels,
	loggedInUserId,
	dispatch,
	apiParams,
}: {
	reels: Array<TReel>;
	loggedInUserId: string;
	dispatch: any;
	apiParams: any;
}) => {
	return reels.map((reel, idx) => {
		const { id, caption, video, status, viewCount, likesCount, createdAt } = reel;

		return {
			id,
			caption,
			status,
			video,
			createdAt: format(new Date(createdAt), 'dd LLL, yyyy hh:mm a'),
			viewCount,
			likesCount,
			actions: (
				<Actions
					id={id}
					videoId={video.id}
					totalReels={reels.length}
					status={status}
					loggedInUserId={loggedInUserId}
					dispatch={dispatch}
					apiParams={apiParams}
				/>
			),
		};
	});
};

// list of videos
const ReelList = (props) => {
	const { register, handleSubmit, watch, errors, setValue, control, ...hookFormMethods } = useForm();
	const dispatch = useDispatch();

	const reelState = useSelector(({ reel, auth }) => ({
		reels: reel.reels,
		reel: reel.reel,
		isLoading: reel.isLoading,
		error: reel.error,
		isSubmitting: reel.isSubmitting,
		apiParams: reel.apiParams,
		total: reel.total,
		page: reel.page,
		loggedInUser: auth.user,
	}));

	const [searchTitle, setSearchTitle] = useState<string>(reelState.apiParams.caption);

	const { navigate } = useNavigation();

	const [reelsRow, setReelsRow] = useState(null);
	const [pageOfItems, setPageOfItems] = useState(1);

	React.useEffect(() => {
		dispatch(
			fetchReels({
				...reelState.apiParams,
			})
		);
	}, [reelState.apiParams]);

	React.useEffect(() => {
		setPageOfItems(reelState.page);
	}, [reelState.page]);

	React.useEffect(() => {
		if (isNotEmptyArray(reelState.reels)) {
			const structuredRows = createNewRows({
				reels: reelState.reels,
				loggedInUserId: reelState.loggedInUser.id,
				dispatch,
				apiParams: reelState.apiParams,
			});
			setReelsRow(structuredRows);
		} else {
			setReelsRow([]);
		}
	}, [reelState.reels]);

	const onSearch = (e) => {
		// eslint-disable-next-line no-unused-expressions
		e && e.preventDefault();

		dispatch(
			setApiParams({
				...reelState.apiParams,
				caption: searchTitle,
			})
		);
	};

	const onChangePage = (itemsPage: number) => {
		const { apiParams } = reelState;
		if (itemsPage) {
			dispatch(setPage(itemsPage));
			dispatch(
				setApiParams({
					...apiParams,
					page: itemsPage,
				})
			);
		}
	};

	const qp = ObjectMaybe(parseQueryParams(props.location.search));

	return (
		<FormProvider {...{ ...hookFormMethods, register, handleSubmit, watch, errors, setValue, control }}>
			<Card>
				<LoadingOverlay isLoading={reelState.isLoading} />

				<CardBody>
					<Row className="mt-1 mb-4 rounded">
						<Col sm={6}>
							<h3>Reels</h3>
						</Col>
						<Col sm={6}>
							<Button onClick={() => navigate(`/reels/add-edit${stringifyQueryParams({ hang: 'true', mode: 'add', set: qp.set })}`)}>
								Add a new Reel
							</Button>
						</Col>

						<Col sm={6}>
							<Filter searchTerm={searchTitle} setSearchTerm={setSearchTitle} onSearch={onSearch} placeholder="Search by caption" />
						</Col>
					</Row>

					{isNotEmptyArray(reelsRow) && (
						<>
							<DataPaginationTable heads={heads} rows={reelsRow} />
							<Pagination
								itemsCount={reelState.total}
								itemsToShow={reelState.apiParams.limit}
								pageOfItems={pageOfItems}
								onChangePage={onChangePage}
							/>
						</>
					)}
					{reelState.total === 0 && <div className="">No data</div>}
				</CardBody>
			</Card>
		</FormProvider>
	);
};

export default ReelList;

import styled from 'styled-components';

export const CenteredTitle = styled.h3`
	display: flex;
	justify-content: center;
`;

export const CenteredStickyTitle = styled.h3`
	display: flex;
	justify-content: center;
	position: sticky;
	padding: '50px';
`;

export const LessonDetails = styled.div`
	display: flex;
	flex-direction: column;
	gap: 4px;
	padding: 8px 12px;
	width: 100%;
	height: 100%;
`;

export const LessonLabel = styled.span`
	font-weight: bold;
	color: #333;
`;

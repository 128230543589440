/* eslint-disable no-lonely-if */
import React, { useState } from 'react';
import { useEffect } from 'react';
import { FormProvider, useForm, useFieldArray } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Card, CardBody, Col, Row } from 'reactstrap';
import { actionSpreader, isNotEmptyObject, parseQueryParams, showToast, stringifyQueryParams } from 'utils/commonHelpers';
import { SelectFieldNew } from 'reusableComponents/HookForm/Select';
import { getPreviewImages, uploadImagesAndConvertToDatabaseFormat } from 'utils/hookFormImageHelpers';
import { AnagramTypeOptions, McqTypeOptions, gptModelOptions, roleVoiceOptions, roleVoiceRealtimeOptions } from 'utils/constants';
import Box from 'reusableComponents/Box';
import LoadingOverlay from 'reusableComponents/LoadingOverlay';
import { setBreadcrumbs } from 'containers/App/globalSlice';
import Breadcrumbs from 'components/Breadcrumbs';
import { useNavigation } from 'context/navigationContext';
import { HangingQuestionTypes } from 'containers/Exercise/utils/helpers';
import { MatchColumnMappingTypes } from 'containers/Exercise/utils/types';
import { setIsLoading } from '../LessonsSlice';
import { validateImage } from '../utils/validateImage';
import { fetchLesson, fetchLessonQuestion, updateLessonQuestion } from '../actions/lessons.actions';
import {
	checkImageInMcq,
	convertTranslationAndAudioToKeyValuePair,
	crosswordInvalidWordsMeaningAndExamples,
	filterAndMapCustomUploadedAudio,
	filterAndMapItems,
	filterAndMapTranslations,
	formatEnglishAudio,
	makeCustomAudioKeysMap,
	QuestionTypeFields,
	uploadCustomAudioToAzure,
	validateMcqQuestion,
} from '../utils/helpers';
import {
	MediaTypeEnum,
	QuestionTypesEnum,
	UiTypeEnum,
	TQuestion,
	TMcqLessonQuestion,
	TReadAlongLessonQuestion,
	TContentOnlyLessonQuestion,
	TConversationLessonQuestion,
	TAudioData,
	TAnagramLessonQuestion,
} from '../utils/types';
import { questionSetTitleSpan } from '../utils/helperComponents';
import { conversationQuestionForm } from './formComponents/conversationQuestionForm';
import { mcqQuestionForm } from './formComponents/mcqQuestionForm';
import { readAlongQuestionForm } from './formComponents/readAlongQuestionForm';
import { contentOnlyQuestionForm } from './formComponents/contentOnlyQuestionForm';
import { crosswordQuestionForm } from './formComponents/crosswordQuestionForm';
import { anagramQuestionForm } from './formComponents/anagramQuestionForm';
import { matchColumnQuestionForm } from './formComponents/matchColumnQuestionForm';
import { LanguageCodesEnum, questionLanguageOptions } from '../utils/constants';

const formatQuestionData = ({ question, uiType }) => {
	const options =
		question.type === QuestionTypesEnum.MCQ
			? question.options?.map((option) => {
					return {
						isCorrectAnswer: option.isCorrectAnswer,
						value: option.text,
						lang_translations: filterAndMapTranslations(option.text),
						lang_customAudio: filterAndMapCustomUploadedAudio(option.text),
						image:
							option?.media?.mediaPath && getPreviewImages({ id: question.id, mediaType: 'image', url: option.media.mediaPath }, false),
					};
			  })
			: [];

	const anagramCorrectWords = [];
	const anagramExtraWords = [];
	let matchColumnData;

	if (question.type === QuestionTypesEnum.MATCH_COLUMN) {
		matchColumnData = filterAndMapItems(question.items);
	}
	if (question.type === QuestionTypesEnum.ANAGRAM) {
		question?.blocks.forEach((block) => {
			if (block?.isAnswer) {
				anagramCorrectWords.push({
					value: block.text,
					lang_translations: filterAndMapTranslations(block.text),
					lang_customAudio: filterAndMapCustomUploadedAudio(block.text),
				});
			} else {
				anagramExtraWords.push({
					value: block.text,
					lang_translations: filterAndMapTranslations(block.text),
					lang_customAudio: filterAndMapCustomUploadedAudio(block.text),
				});
			}
		});
	}

	return {
		...question,
		...(typeof question.title?.en?.text === 'string' && {
			title: question.title?.en?.text,
			title_translations: filterAndMapTranslations(question.title),
			title_customAudio: filterAndMapCustomUploadedAudio(question.title),
		}),
		...(typeof question.subtitle?.en?.text === 'string' && {
			subtitle: question.subtitle?.en?.text,
			subtitle_translations: filterAndMapTranslations(question.subtitle),
			subtitle_customAudio: filterAndMapCustomUploadedAudio(question.subtitle),
		}),
		...(question.type === QuestionTypesEnum.CONTENT_ONLY &&
			typeof question.meaning?.en?.text === 'string' && {
				meaning: question.meaning?.en?.text,
				meaning_translations: filterAndMapTranslations(question.meaning),
				meaning_customAudio: filterAndMapCustomUploadedAudio(question.meaning),
			}),
		...(question.type === QuestionTypesEnum.CONVERSATION && {
			scene: question.scene?.en?.text,
			scene_translations: filterAndMapTranslations(question.scene),
			scene_customAudio: filterAndMapCustomUploadedAudio(question.scene),

			firstBotDialogue: question.firstBotDialogue?.en?.text,
			firstBotDialogue_translations: filterAndMapTranslations(question.firstBotDialogue),
			firstBotDialogue_customAudio: filterAndMapCustomUploadedAudio(question.firstBotDialogue),

			firstBotDialogueHint: question.firstBotDialogueHint?.en?.text,
			firstBotDialogueHint_translations: filterAndMapTranslations(question.firstBotDialogueHint),
			firstBotDialogueHint_customAudio: filterAndMapCustomUploadedAudio(question.firstBotDialogueHint),

			...(question.systemVoiceId && { systemVoiceId: roleVoiceOptions.find((option) => option.value === question.systemVoiceId) }),
			...(question.botVoiceId && {
				botVoiceId: [...roleVoiceOptions, ...roleVoiceRealtimeOptions].find((option) => option.value === question.botVoiceId),
			}),
			...(question.gptModel && { gptModel: gptModelOptions.find((option) => option.value === question.gptModel) }),
		}),
		...(question.type === QuestionTypesEnum.READ_ALONG && {
			voiceId: roleVoiceOptions.find((option) => option.value === question.voiceId),
			completeSentence: question.completeSentence?.en?.text,
			completeSentence_translations: filterAndMapTranslations(question.completeSentence),
			completeSentence_customAudio: filterAndMapCustomUploadedAudio(question.completeSentence),
		}),
		...(question.type === QuestionTypesEnum.MCQ && {
			correctSentence: question.correctSentence?.en?.text,
			correctSentence_translations: filterAndMapTranslations(question.correctSentence),
			correctSentence_customAudio: filterAndMapCustomUploadedAudio(question.correctSentence),
			mcqType: McqTypeOptions.find((option) => option.value === question.uiType),

			preTitle: question.preTitle?.en?.text,
			preTitle_translations: filterAndMapTranslations(question.preTitle),
			preTitle_customAudio: filterAndMapCustomUploadedAudio(question.preTitle),
		}),
		...(question.type === QuestionTypesEnum.ANAGRAM && {
			correctWords: anagramCorrectWords,
			extraWords: anagramExtraWords,
			voiceId: question.voiceId,
			anagramType: AnagramTypeOptions.find((option) => option.value === question.anagramType),
		}),
		...(question.type === QuestionTypesEnum.MATCH_COLUMN && {
			title: {
				en: {
					text: 'Match the following',
				},
			},
			items: matchColumnData,
		}),
		...(question.type === QuestionTypesEnum.MATCH_COLUMN &&
			question?.questionMapping && {
				questionMapping: MatchColumnMappingTypes.find((option) => option.value === question?.questionMapping),
			}),
		...(question.type === QuestionTypesEnum.MATCH_COLUMN &&
			!question.questionMapping && {
				questionMapping: MatchColumnMappingTypes[0],
			}),
		...(question?.voiceId && {
			roleVoiceId: roleVoiceOptions.find((option) => option.value === question?.voiceId),
		}),
		descriptionImage: question.descriptionImage?.mediaPath
			? getPreviewImages({ id: question.id, mediaType: 'image', url: question.descriptionImage?.mediaPath }, false)
			: null,
		options,
		type: QuestionTypeFields.find((type) => type.value === question.type),
		uiType,
		questionLang: questionLanguageOptions.find((option) => option.value === (question?.lang ?? LanguageCodesEnum.EN)),
	};
};

const formatUpdatedQuestion = ({ question, uiType, data, oldQuestion, nameToAudioRelativePathMap, imagesUploadData }) => {
	const anagramCorrectWords = data?.blocks?.filter((block) => block.isAnswer);
	const anagramExtraWords = data?.blocks?.filter((block) => !block.isAnswer);

	return {
		...question,
		lang: data?.questionLang?.value ?? LanguageCodesEnum.EN,
		...(data.difficulty?.value && { difficulty: data.difficulty.value }),
		uiType,
		...((question?.type === QuestionTypesEnum.MCQ || data?.type?.value === QuestionTypesEnum.MCQ) && {
			imageDescription: data.imageDescription,
		}),
		...((question?.type === QuestionTypesEnum.CONTENT_ONLY || data?.type?.value === QuestionTypesEnum.CONTENT_ONLY) && {
			roleName: data?.roleName?.trim(),
		}),
		voiceId: data.roleVoiceId?.value,
		title: {
			en: {
				...question?.title?.en,
				text: data.title?.trim(),
				audio: formatEnglishAudio('title', data.title_customAudio, oldQuestion?.title, nameToAudioRelativePathMap),
			},
			...convertTranslationAndAudioToKeyValuePair(
				'title',
				data.title_translations,
				data.title_customAudio,
				oldQuestion?.title,
				nameToAudioRelativePathMap
			),
		},
		subtitle: {
			en: {
				...question?.subtitle?.en,
				text: data.subtitle?.trim(),
				audio: formatEnglishAudio('subtitle', data.subtitle_customAudio, oldQuestion?.subtitle, nameToAudioRelativePathMap),
			},
			...convertTranslationAndAudioToKeyValuePair(
				'subtitle',
				data.subtitle_translations,
				data.subtitle_customAudio,
				oldQuestion?.subtitle,
				nameToAudioRelativePathMap
			),
		},
		...(data?.type?.value === QuestionTypesEnum.READ_ALONG && {
			completeSentence: {
				en: {
					...(question as TReadAlongLessonQuestion)?.completeSentence?.en,
					text: data.completeSentence?.trim(),
					audio: formatEnglishAudio(
						'completeSentence',
						data.completeSentence_customAudio,
						(oldQuestion as TReadAlongLessonQuestion)?.completeSentence,
						nameToAudioRelativePathMap
					),
				},
				...convertTranslationAndAudioToKeyValuePair(
					'completeSentence',
					data.completeSentence_translations,
					data.completeSentence_customAudio,
					(oldQuestion as TReadAlongLessonQuestion)?.completeSentence,
					nameToAudioRelativePathMap
				),
			},
		}),
		...(data?.type?.value === QuestionTypesEnum.MCQ && {
			correctSentence: {
				en: {
					...(question as TMcqLessonQuestion)?.correctSentence?.en,
					text: data.correctSentence?.trim(),
					audio: formatEnglishAudio(
						'correctSentence',

						data.correctSentence_customAudio,
						(oldQuestion as TMcqLessonQuestion)?.correctSentence,
						nameToAudioRelativePathMap
					),
				},
				...convertTranslationAndAudioToKeyValuePair(
					'correctSentence',
					data.correctSentence_translations,
					data.correctSentence_customAudio,
					(oldQuestion as TMcqLessonQuestion)?.correctSentence,
					nameToAudioRelativePathMap
				),
			},
			preTitle: {
				en: {
					...(question as TMcqLessonQuestion)?.preTitle?.en,
					text: data.preTitle?.trim(),
					audio: formatEnglishAudio(
						'preTitle',
						data.preTitle_customAudio,
						(oldQuestion as TMcqLessonQuestion)?.preTitle,
						nameToAudioRelativePathMap
					),
				},
				...convertTranslationAndAudioToKeyValuePair(
					'preTitle',
					data.preTitle_translations,
					data.preTitle_customAudio,
					(oldQuestion as TMcqLessonQuestion)?.preTitle,
					nameToAudioRelativePathMap
				),
			},
			questionUiType: data.mcqType?.value,
		}),
		...(imagesUploadData?.descriptionImage?.url
			? {
					descriptionImage: {
						mediaId: imagesUploadData?.descriptionImage?.id,
						mediaPath: imagesUploadData?.descriptionImage?.url,
						mediaType: MediaTypeEnum.IMAGE,
					},
			  }
			: { descriptionImage: null }),
		options: data.options?.map((option, index) => {
			return {
				_id: question?.type === QuestionTypesEnum.MCQ ? question?.options?.[index]?.['_id'] : undefined,
				text: {
					en: {
						...(question as TMcqLessonQuestion)?.options[index]?.text?.en,
						text: option.value?.en?.text?.trim(),
						audio: formatEnglishAudio(
							`option${index}`,
							option.lang_customAudio,
							(oldQuestion as TMcqLessonQuestion)?.options?.[index]?.text,
							nameToAudioRelativePathMap
						),
					},
					...convertTranslationAndAudioToKeyValuePair(
						`option${index}`,
						option.lang_translations,
						option.lang_customAudio,
						(oldQuestion as TMcqLessonQuestion)?.options?.[index]?.text,
						nameToAudioRelativePathMap
					),
				},
				isCorrectAnswer: option.isCorrectAnswer,
				...(imagesUploadData?.[`options_${index}_image`]?.url
					? {
							media: {
								mediaId: imagesUploadData?.[`options_${index}_image`]?.id,
								mediaType: MediaTypeEnum.IMAGE,
								mediaPath: imagesUploadData?.[`options_${index}_image`]?.url,
							},
					  }
					: {}),
			};
		}),
		type: data.type.value,
		...(data.type.value === QuestionTypesEnum.CONVERSATION && {
			scene: {
				en: {
					...data.scene?.en,
					text: data.scene?.trim(),
					audio: formatEnglishAudio(
						'scene',
						data.scene_customAudio,
						(oldQuestion as TConversationLessonQuestion)?.scene,
						nameToAudioRelativePathMap
					),
				},
				...convertTranslationAndAudioToKeyValuePair(
					'scene',
					data.scene_translations,
					data.scene_customAudio,
					(oldQuestion as TConversationLessonQuestion)?.scene,
					nameToAudioRelativePathMap
				),
			},
			systemPrompt: data.systemPrompt?.trim(),
			userPrompt: data.userPrompt?.trim(),
			botName: data.botName?.trim(),
			firstBotDialogue: {
				en: {
					...data?.firstBotDialogue?.en,
					text: data.firstBotDialogue?.trim(),
					audio: formatEnglishAudio(
						'firstBotDialogue',
						data.firstBotDialogue_customAudio,
						(oldQuestion as TConversationLessonQuestion)?.firstBotDialogue,
						nameToAudioRelativePathMap
					),
				},
				...convertTranslationAndAudioToKeyValuePair(
					'firstBotDialogue',
					data.firstBotDialogue_translations,
					data.firstBotDialogue_customAudio,
					(oldQuestion as TConversationLessonQuestion)?.firstBotDialogue,
					nameToAudioRelativePathMap
				),
			},
			firstBotDialogueHint: {
				en: {
					...data?.firstBoDialogue?.en,
					text: data.firstBotDialogueHint?.trim(),
					audio: formatEnglishAudio(
						'firstBotDialogueHint',
						data.firstBotDialogueHint_customAudio,
						(oldQuestion as TConversationLessonQuestion)?.firstBotDialogueHint,
						nameToAudioRelativePathMap
					),
				},
				...convertTranslationAndAudioToKeyValuePair(
					'firstBotDialogueHint',
					data.firstBotDialogueHint_translations,
					data.firstBotDialogueHint_customAudio,
					(oldQuestion as TConversationLessonQuestion)?.firstBotDialogueHint,
					nameToAudioRelativePathMap
				),
			},
			systemVoiceId: data?.systemVoiceId?.value,
			botVoiceId: data?.botVoiceId?.value,
			voiceId: data.voiceId?.value ?? roleVoiceOptions[0].value,
			gptModel: data.gptModel?.value,
			maxLength: data.maxLength,
		}),
		...((data?.type?.value === QuestionTypesEnum.MATCH_COLUMN || question?.type === QuestionTypesEnum.MATCH_COLUMN) && {
			matchColumnType: data.matchColumnType || 'STANDARD',
			numColumns: data.numColumns ? data.numColumns : 2,
			questionMapping: data.questionMapping.value,
			voiceId: roleVoiceOptions[0].value,
			items: data.items.map(
				(
					item: {
						key: string;
						value: { text: string }; // eslint-disable-next-line camelcase
						lang_translations: { translation: string; languageCode: { label: string; value: LanguageCodesEnum } }[];
					},
					idx: number
				) => ({
					key: item.key,
					value: {
						en: {
							text: item.value.text.trim(),
						},
						...convertTranslationAndAudioToKeyValuePair(
							`items[${idx}]`,
							item.lang_translations,
							null,
							oldQuestion?.items?.find((i) => i?.en?.text === item),
							null
						),
					},
				})
			),
		}),
		...(data.type.value === QuestionTypesEnum.READ_ALONG && {
			voiceId: data.voiceId?.value ?? roleVoiceOptions[0].value,
		}),
		...(data.type.value === QuestionTypesEnum.MCQ && {
			voiceId: data.voiceId?.value ?? roleVoiceOptions[0].value,
		}),
		...(data.type.value === QuestionTypesEnum.CONTENT_ONLY &&
			!data.roleVoiceId && {
				voiceId: data.voiceId?.value ?? roleVoiceOptions[0].value,
			}),
		...(data.type.value === QuestionTypesEnum.CROSSWORD && {
			title: {
				en: {
					text: 'Crossword',
				},
				...convertTranslationAndAudioToKeyValuePair(
					'title',
					data.title_translations,
					data.title_customAudio,
					oldQuestion?.title,
					nameToAudioRelativePathMap
				),
			},
			subtitle: {
				en: {
					text: 'Crossword',
				},
				...convertTranslationAndAudioToKeyValuePair(
					'subtitle',
					data.subtitle_translations,
					data.subtitle_customAudio,
					oldQuestion?.subtitle,
					nameToAudioRelativePathMap
				),
			},
			letterGrid: data.letterGrid,
			level: data.level,
		}),
		...(data.meaning && {
			meaning: {
				en: {
					...(question as TContentOnlyLessonQuestion)?.meaning?.en,
					text: data.meaning,
					audio: formatEnglishAudio(
						'meaning',
						data.meaning_customAudio,
						(oldQuestion as TContentOnlyLessonQuestion)?.meaning,
						nameToAudioRelativePathMap
					),
				},
				...convertTranslationAndAudioToKeyValuePair(
					'meaning',
					data.meaning_translations,
					data.meaning_customAudio,
					(oldQuestion as TContentOnlyLessonQuestion)?.meaning,
					nameToAudioRelativePathMap
				),
			},
		}),
		...(data.partsOfSpeech && {
			partsOfSpeech: data.partsOfSpeech,
		}),
		...(data.type.value === QuestionTypesEnum.MATCH_COLUMN && {
			title: {
				en: {
					text: 'Match the following',
				},
			},
		}),
		...(data.type.value === QuestionTypesEnum.ANAGRAM && {
			blocks: [
				anagramCorrectWords.map((block, index) => {
					const oldQuestionBlock = (oldQuestion as TAnagramLessonQuestion)?.blocks?.find(
						(oldBlock) => oldBlock?.text?.en?.text?.trim() === block.value?.en?.text?.trim()
					);
					if (block.value?.en?.text?.trim()) {
						return {
							text: {
								en: {
									...oldQuestionBlock?.text?.en,
									text: block.value?.en?.text?.trim(),
									audio: formatEnglishAudio(
										`correctWords${index}`,
										data.correctWords_customAudio,
										oldQuestionBlock?.text,
										nameToAudioRelativePathMap
									),
								},
							},
							isAnswer: block.isAnswer,
						};
					}
					return null;
				}),
				anagramExtraWords.map((block, index) => {
					const oldQuestionBlock = (oldQuestion as TAnagramLessonQuestion)?.blocks?.find(
						(oldBlock) => oldBlock?.text?.en?.text?.trim() === block.value?.en?.text?.trim()
					);
					if (block.value?.en?.text?.trim()) {
						return {
							text: {
								en: {
									...oldQuestionBlock?.text?.en,
									text: block.value?.en?.text?.trim(),
									audio: formatEnglishAudio(
										`extraWords${index}`,
										data.extraWords_customAudio,
										oldQuestionBlock?.text,
										nameToAudioRelativePathMap
									),
								},
							},
							isAnswer: block.isAnswer,
						};
					}
					return null;
				}),
			]
				.flat()
				.filter((b) => b),
			voiceId: roleVoiceOptions[0].value,
			anagramType: data?.anagramType?.value,
		}),
	};
};

const LessonQuestionAddEditForm = (props) => {
	const dispatch = useDispatch();
	const { navigate } = useNavigation();
	const { register, handleSubmit, watch, errors, setValue, control, ...hookFormMethods } = useForm();
	const { questionId, mode, hang, set } = parseQueryParams(props.location.search) ?? {};
	const { lessonId } = parseQueryParams(props.location.search) ?? {};
	const {
		lessons: { questions, isLoading, lesson },
		userId,
	} = useSelector(({ lessons, auth }) => ({ lessons, userId: auth?.user?.id }));

	const [audioData, setAudioData]: [TAudioData, any] = useState({});

	const breadcrumbsData = useSelector(({ global }) => ({
		breadcrumbs: global.breadcrumbs,
	}));

	const { uiType } = (lesson ?? {}) as TQuestion & {
		uiType: UiTypeEnum;
	};
	const question = questions?.find((question) => question?.id === questionId);
	const oldQuestion = {
		...question,
	};

	const [areImagesValid, setAreImagesValid] = useState(true); // to keep track of whether all images are valid
	const questionType = watch('type');
	const mcqWatch = watch(['options', 'title']);

	const optionsFieldArray = useFieldArray({
		control,
		name: 'options',
	});
	const itemsFieldArray = useFieldArray({
		control,
		name: 'items',
	});

	useEffect(() => {
		checkImageInMcq({
			mcqWatch,
		});
	}, [mcqWatch.options]);

	const descriptionImageText = watch('descriptionImage');
	const [invalidWordsData, setInvalidWordsData] = useState([]);
	const [wordsMeaningSentences, setWordsMeaningSentences] = useState([]);
	useEffect(() => {
		const minimumAspectRatio = 1.76;
		const maximumAspectRatio = 2.05;

		(async () => {
			if (!descriptionImageText) {
				return;
			}

			const flag = await validateImage(descriptionImageText, {
				minimumAspectRatio,
				maximumAspectRatio,
			});

			if (flag) {
				setAreImagesValid(true);
			} else {
				setAreImagesValid(false);
				showToast('error', 'Image is of wrong dimension');
			}
		})();
	}, [descriptionImageText]);

	useEffect(() => {
		dispatch(
			actionSpreader(setBreadcrumbs.type, {
				breadcrumbs: [
					{
						text: 'Lesson',
						url: `/lessons/list${stringifyQueryParams({})}`,
					},
					{
						text: 'Questions',
						url: `/lessons/questions/list${stringifyQueryParams({ lessonId, set })}`,
					},
					{
						text: 'Question form',
						url: `/lessons/questions/add-edit${stringifyQueryParams({ lessonId, mode, questionId, set })}`,
					},
				],
			})
		);

		setAudioData({
			title: question?.title?.en?.audio,
			completeSentence: (question as TReadAlongLessonQuestion)?.completeSentence?.en?.audio,
			correctSentence: (question as TMcqLessonQuestion)?.correctSentence?.en?.audio,
			meaning: (question as TContentOnlyLessonQuestion)?.meaning?.en?.audio,
			scene: (question as TConversationLessonQuestion)?.scene?.en?.audio,
			firstBotDialogue: (question as TConversationLessonQuestion)?.firstBotDialogue?.en?.audio,
			options: (question as TMcqLessonQuestion)?.options?.map((option) => option?.text?.en?.audio),
			correctWords: (question as TAnagramLessonQuestion)?.blocks?.filter((block) => block.isAnswer).map((block) => block?.text?.en?.audio),
			extraWords: (question as TAnagramLessonQuestion)?.blocks?.filter((block) => !block.isAnswer).map((block) => block?.text?.en?.audio),
		});

		if (question && question.type === QuestionTypesEnum.MATCH_COLUMN) {
			const { items } = question;
			if (items && items.length > 0) {
				itemsFieldArray.remove();
				items.forEach((item) => {
					itemsFieldArray.append({
						key: item.key,
						value: {
							text: item.value.text,
						},
					});
				});
			}
		}

		if (isNotEmptyObject(question)) {
			hookFormMethods.reset(
				formatQuestionData({
					question,
					uiType,
				})
			);
		} else {
			if (hang !== 'true') {
				dispatch(fetchLesson({ lessonId }));
			}
			if (mode !== 'add') {
				dispatch(fetchLessonQuestion({ questionId }));
			}
		}
	}, [question, mode, questionId]);

	const onSubmit = async (data) => {
		if (!data.type) {
			return showToast('error', 'Please select question type');
		}

		if (data.type.value === QuestionTypesEnum.MCQ) {
			if (!validateMcqQuestion(data)) {
				return;
			}
		} else if (data.type.value === QuestionTypesEnum.MEDIA) {
			if (!data?.allowedMediaTypes?.length) {
				return showToast('error', 'Please select atleast one media type');
			}
		}
		if (data.type.value === QuestionTypesEnum.MATCH_COLUMN) {
			if (!data.items || data.items.length === 0) return showToast('error', 'Please enter atleast 1 item');
		}
		if (data.items && data.items.length > 0) {
			// make the columns alternative in list
			const colA = [];
			const colB = [];
			let key = 1;
			for (let i = 0; i < data.items.length; i += 1) {
				const item = data.items[i];
				item.value.text = item.value.text.toString().trim();

				if (item.value.text === '') {
					return showToast('error', 'Please enter a valid item');
				}

				if (parseFloat(item.key) === key) {
					colA.push(item);
					key += 1;
				} else {
					colB.push(item);
				}
			}

			// check if there are equal number of items to match
			if (colA.length !== colB.length) return showToast('error', 'There must be equal number of items in the columns');

			const items = [];
			const maxLength = Math.max(colA.length, colB.length);

			for (let i = 0; i < maxLength; i += 1) {
				if (i < colA.length) {
					items.push(colA[i]);
				}
				if (i < colB.length) {
					items.push(colB[i]);
				}
			}

			data.items = items;
		}

		// validate all images
		const mcqImageFlag = await checkImageInMcq({
			mcqWatch,
		});
		if (!mcqImageFlag) return;

		const entityType = 'EXERCISE_QUESTION';

		const optionsWithImages = [];

		if (data.type.value === QuestionTypesEnum.MCQ) {
			for (let i = 0; i < data.options.length; i += 1) {
				if (data.options[i].media && data.options[i].media.length !== 0) {
					optionsWithImages.push({
						key: `options_${i}_image`,
						isMulti: false,
						withCaption: false,
						entityId: questionId ?? lessonId, // in add form, questionId is not available, so we send lessonId as entityId
						entityType,
						image: data.options[i].media,
					});
					data[`options_${i}_image`] = data.options[i].media;
				}
			}
		}

		if (data.type.value === QuestionTypesEnum.MCQ && optionsWithImages.length > 0 && data.options.length !== optionsWithImages.length) {
			return showToast('error', 'Either all or none options should have images');
		}

		const imagesKeys = [
			{ key: 'descriptionImage', isMulti: false, withCaption: false, entityId: questionId ?? lessonId, entityType },
			...optionsWithImages,
		];

		const imagesUploadData: any = await uploadImagesAndConvertToDatabaseFormat({ imagesKeys, data });

		// custom audios
		const audioToKeysMap = makeCustomAudioKeysMap(data);
		// uploading custom audio
		const nameToAudioRelativePathMap = await uploadCustomAudioToAzure({
			audioToKeysMap,
			lessonId,
			questionId,
			entityType,
		});

		if (data.type.value === QuestionTypesEnum.CROSSWORD) {
			const res = await crosswordInvalidWordsMeaningAndExamples({
				data,
				invalidWordsData,
				wordsMeaningSentences,
			});

			if (!res) {
				return;
			}
		}

		if (data.type.value === QuestionTypesEnum.ANAGRAM) {
			const correctBlocks =
				data?.correctWords?.map((word) => {
					return {
						...word,
						isAnswer: true,
					};
				}) || [];

			const extraBlocks =
				data?.extraWords?.map((word) => {
					return {
						...word,
						isAnswer: false,
					};
				}) || [];

			data['blocks'] = [...correctBlocks, ...extraBlocks];
		}

		dispatch(setIsLoading());

		dispatch(
			updateLessonQuestion({
				userId,
				questionId,
				lessonId,
				hang,
				updatedQuestion: (formatUpdatedQuestion({
					question,
					uiType,
					data,
					oldQuestion,
					nameToAudioRelativePathMap,
					imagesUploadData,
				}) as unknown) as Partial<TQuestion>,
				mode,
				navigate,
			})
		);
	};

	const getQuestionForm = (question: TQuestion) => {
		if (!question) return;
		switch (question.type) {
			case QuestionTypesEnum.CONVERSATION:
				return conversationQuestionForm({
					control,
					watch,
					question,
					oldQuestion,
					audioData,
					uiType,
				});

			case QuestionTypesEnum.MCQ:
				return mcqQuestionForm({
					control,
					watch,
					question,
					oldQuestion,
					audioData,
					uiType,
					hang,
					optionsFieldArray,
					mode,
				});
			case QuestionTypesEnum.READ_ALONG:
				return readAlongQuestionForm({
					control,
					watch,
					question,
					oldQuestion,
					audioData,
				});
			case QuestionTypesEnum.CROSSWORD:
				return crosswordQuestionForm({
					watch,
					setValue,
					setInvalidWordsData,
					setWordsMeaningSentences,
					hang,
				});
			case QuestionTypesEnum.ANAGRAM:
				return anagramQuestionForm({
					audioData,
					control,
					mode,
					watch,
					oldQuestion,
					setAudioData,
					question,
				});
			case QuestionTypesEnum.MATCH_COLUMN:
				return matchColumnQuestionForm({
					watch,
					itemsFieldArray,
					control,
					audioData,
				});
			default:
				// content only questions
				return contentOnlyQuestionForm({
					control,
					watch,
					question,
					oldQuestion,
					audioData,
					uiType,
					questionType,
				});
		}
	};

	return (
		<Card>
			<LoadingOverlay isLoading={isLoading} />
			<CardBody>
				{hang !== 'true' ? (
					<Row className="mt-1 mb-4 rounded">
						<Col sm={12}>
							<Breadcrumbs breadcrumbs={breadcrumbsData.breadcrumbs} />
						</Col>
					</Row>
				) : (
					<></>
				)}
				<h3>
					{mode === 'edit' ? 'Question Edit' : 'Create Question'} {questionSetTitleSpan(set)}
				</h3>
				<FormProvider {...{ ...hookFormMethods, register, handleSubmit, watch, errors, setValue, control }}>
					<form className="form ltr-support" onSubmit={handleSubmit(onSubmit)}>
						<Box w="100%" mt="1rem" mr="1rem" ml="0.5rem" mb="2rem">
							<Row>
								<Col sm={5} />
								{hang !== 'true' ? (
									<Col sm={7}>
										<h5>
											<b>Lesson : </b> {lesson?.info?.title?.en}
										</h5>
										<h5>
											<b>UI Type : </b> {lesson?.uiType}
										</h5>
										<h5>
											<b>Course :</b> {lesson?.course?.title?.en} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <b>Admin Code :</b>{' '}
											{lesson?.course?.adminCode}
										</h5>
										<h5>
											<b>Topic :</b> {lesson?.topic?.title?.en} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <b>Admin Code :</b>{' '}
											{lesson?.topic?.adminCode}
										</h5>
										<h5>
											<b>Subtopic :</b> {lesson?.subtopic?.title?.en} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <b>Admin Code :</b>{' '}
											{lesson?.subtopic?.adminCode}
										</h5>
									</Col>
								) : (
									<Col sm={7} />
								)}
								<Col style={{ maxWidth: '250px' }}>
									{hang !== 'true' ? (
										<>
											<SelectFieldNew
												label="Select Question Type"
												name="type"
												options={QuestionTypeFields.filter(
													(option) => uiType === UiTypeEnum.CROSSWORD_GAME || option.label !== 'Crossword'
												)}
												defaultValue={QuestionTypeFields.find((option) => option.value === question?.type)}
												isDisabled={mode === 'edit'}
												required
											/>
											<SelectFieldNew
												label="Select Question Language"
												name="questionLang"
												options={questionLanguageOptions}
												isDisabled={mode === 'edit'}
												required
											/>
										</>
									) : (
										<>
											<SelectFieldNew
												label="Select Question Type"
												name="type"
												options={HangingQuestionTypes}
												defaultValue={HangingQuestionTypes.find((option) => option.value === question?.type)}
												isDisabled={mode === 'edit'}
												required
											/>
										</>
									)}
								</Col>
							</Row>
							{getQuestionForm(({
								type: questionType?.value,
								uiType,
								voiceId: question?.voiceId,
								botVoiceId: (question as TConversationLessonQuestion)?.botVoiceId,
								systemVoiceId: (question as TConversationLessonQuestion)?.systemVoiceId,
							} as unknown) as TQuestion)}
						</Box>
						<Button type="submit" disabled={!areImagesValid}>
							{mode === 'edit' ? 'Update' : 'Add'}
						</Button>
					</form>
				</FormProvider>
			</CardBody>
		</Card>
	);
};

export default LessonQuestionAddEditForm;

// enum type for video status
export enum HlsStreamStatusEnum {
	S3_UPLOAD_START = 'S3_UPLOAD_START',
	S3_UPLOAD_ERROR = 'S3_UPLOAD_ERROR',
	HLS_STREAM_CONVERT_START = 'HLS_STREAM_CONVERT_START',
	HLS_STREAM_CONVERT_COMPLETE = 'HLS_STREAM_CONVERT_COMPLETE',
	HLS_STREAM_CONVERT_ERROR = 'HLS_STREAM_CONVERT_ERROR',
}

export type TVideo = {
	id: string;
	videoName: string;
	author: string;
	createdAt: string;
	videoUrl: string;
	videoType: string;
	mediaConvertStatus: string;
	mediaConvertJobId: string;
};

export type TVideoState = {
	video?: TVideo;
	videos?: Array<TVideo>;
	isLoading: boolean;
	error: string;
	page: number;
	total: number;
	isSubmitting: boolean;
	apiParams: {
		limit: number;
		page: number;
		videoName?: string;
		videoType?: string;
		sortKey: string;
		sortOrder: '-1' | '1';
		filters?: Record<string, string>;
	};
};

export enum VideoTypesEnum {
	REEL = 'REEL',
	VIDEO_COURSE = 'VIDEO_COURSE',
	ADS = 'ADS',
	LESSON = 'LESSON',
	MISC = 'MISC',
}

export const VideoTypeOptions = [
	{
		label: 'Reel',
		value: VideoTypesEnum.REEL,
	},
	{
		label: 'Video Course',
		value: VideoTypesEnum.VIDEO_COURSE,
	},
	{
		label: 'Ads',
		value: VideoTypesEnum.ADS,
	},
	{
		label: 'Lesson',
		value: VideoTypesEnum.LESSON,
	},
	{
		label: 'Misc',
		value: VideoTypesEnum.MISC,
	},
];

// reel type defs //
export enum DocumenStatusEnum {
	ACTIVE = 'ACTIVE',
	INACTIVE = 'INACTIVE',
	DRAFT = 'DRAFT',
}

// embeddedMedia type def
export type TEmbeddedMedia = {
	aspectRatio: string;
	description: string;
	duration: number;
	mediaId: string;
	mediaType: string;
	mediaPath: string;
};

export type TReel = {
	id: string;
	caption: string;
	videoURL: string;
	thumbnail: TEmbeddedMedia;
	likesCount: number;
	viewCount: number;
	createdAt: string;
	status: DocumenStatusEnum;
	videoId: string;
};

export type TReelState = {
	reel?: TReel;
	reels?: Array<TReel>;
	isLoading: boolean;
	error: string;
	page: number;
	total: number;
	isSubmitting: boolean;
	apiParams: {
		limit: number;
		page: number;
		caption?: string;
		sortKey: string;
		sortOrder: '-1' | '1';
		filters?: Record<string, string>;
	};
};

import React from 'react';
import { useFieldArray } from 'react-hook-form';
import { Button, Col, Row } from 'reactstrap';
import { AsyncSelectField, SelectFieldNew } from 'reusableComponents/HookForm/Select';
import Box from 'reusableComponents/Box';
import { fetchVideos } from 'utils/videoHelpers/fetchVideoApiWrapper';
import { lessonVideoLanguageOptions } from '../utils/constants';

export const FieldVideo = ({ name, control, selectWidth = '2', textWidth = '9' }) => {
	const videoFields = useFieldArray({
		control,
		name: `${name}_video`,
	});

	const getAvailableLanguages = (selectedLanguages) => {
		return lessonVideoLanguageOptions.filter((language) => !selectedLanguages.includes(language.value));
	};

	const selectedLanguages = videoFields.fields.map((field) => field.languageCode.value);
	const availableLanguages = getAvailableLanguages(selectedLanguages);

	const fetchAllVideos = async ({ query = '' }) => {
		const videos = await fetchVideos({ query });
		if (videos) {
			return videos.map((video) => ({
				label: video.videoName,
				value: video.id,
			}));
		}
		return [];
	};

	return (
		<div
			style={{
				backgroundColor: videoFields.fields.length > 0 ? '#fdb1b122' : '#fff',
				padding: '10px 16px',
				borderRadius: '0 0 8px 8px',
				display: 'flex',
				alignItems: 'center',
				flexDirection: 'column',
				width: '100%',
			}}
		>
			{videoFields.fields.length > 0 && (
				<div
					style={{
						width: '100%',
					}}
				>
					<h4
						style={{
							textAlign: 'left',
						}}
					>
						Videos
					</h4>
					<br />
				</div>
			)}

			{videoFields.fields.map((videoField, idx) => {
				return (
					<div
						key={videoField.id}
						style={{
							width: '100%',
							position: 'relative',
						}}
					>
						<Row
							style={{
								flex: 1,
								justifyItems: 'center',
								alignItems: 'center',
								height: '100%',
							}}
						>
							<Col sm={selectWidth}>
								<SelectFieldNew
									placeholder="Language"
									label="Language"
									name={`${name}_video[${idx}].languageCode`}
									options={availableLanguages}
									defaultValue={lessonVideoLanguageOptions.find((language) => language.value === videoField?.languageCode?.value)}
									required
								/>
							</Col>
							<Col sm={textWidth}>
								<AsyncSelectField
									label="Video Name"
									name={`${name}_video[${idx}].video`}
									defaultValue={videoField?.video}
									required
									isClearable
									key={videoField?.video?.value}
									fetchOptions={fetchAllVideos}
								/>
							</Col>

							<Box onClick={() => videoFields.remove(idx)} cursor="pointer">
								<span className="lnr lnr-cross" />
							</Box>
						</Row>
					</div>
				);
			})}
			<Button
				style={{
					backgroundColor: '#fdb1b1',
					color: '#fff',
					border: 'none',
					borderRadius: videoFields.fields.length === 0 ? '8px' : '100px',
					padding: '10px 16px',
					margin: '0',
					cursor: 'pointer',
				}}
				onClick={() => {
					videoFields.append({
						languageCode: availableLanguages[0],
						video: null,
					});
				}}
			>
				{videoFields.fields.length === 0 ? '+ Add Video' : '╋'}
			</Button>
		</div>
	);
};

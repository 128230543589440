import React from 'react';
import { Switch, Route } from 'react-router-dom';
import VideoCourseList from './components/VideoCourseList';
import VideoCourseAddEditForm from './components/VideoCourseAddEditForm';

const Courses = () => {
	return (
		<Switch>
			<Route path="/video-course/manage" component={VideoCourseList} />
			<Route path="/video-course/add-edit" component={VideoCourseAddEditForm} />
		</Switch>
	);
};

export default Courses;

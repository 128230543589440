import { createAsyncThunk } from '@reduxjs/toolkit';
import { setError } from 'containers/App/globalSlice';
import { showToast } from 'utils/commonHelpers';
import { fetchWrapper } from 'utils/fetchWrapper';
import { TVideoCourseState } from '../utils/types';

export const fetchVideoCourses = createAsyncThunk(
	'/videoCourses/fetchVideoCourses',
	async (apiParams: TVideoCourseState['apiParams'], { dispatch }) => {
		try {
			const response = await fetchWrapper(`/admin/video-course/fetch-video-courses`, {
				method: 'POST',
				body: apiParams,
			});

			const { data } = response;
			if (response.status !== 200) {
				dispatch({ type: setError.type, payload: { errors: response.errors } });
			}

			return data;
		} catch (error) {
			showToast('error', error.message);
		}
	}
);

export const fetchVideoCourseById = createAsyncThunk('/videoCourses/fetchVideoCourseById', async (videoCourseId: string, { dispatch }) => {
	try {
		const response = await fetchWrapper(`/admin/video-course/fetch-video-course?id=${videoCourseId}`, {
			method: 'GET',
		});

		const { data } = response;
		if (response.status !== 200) {
			dispatch({ type: setError.type, payload: { errors: response.errors } });
		}

		return data;
	} catch (error) {
		showToast('error', error.message);
	}
});

import React, { useEffect, useState } from 'react';
import { Button, Card, CardBody, Col, Row } from 'reactstrap';
import { FormProvider, useForm } from 'react-hook-form';
import { showToast } from 'utils/commonHelpers';
import Box from 'reusableComponents/Box';
import { InputNew } from 'reusableComponents/HookForm/Input';
import { SelectFieldNew } from 'reusableComponents/HookForm/Select';
import { TextAreaNew } from 'reusableComponents/HookForm/TextArea';
import { FieldVideo } from 'containers/Lessons/components/fieldVideo';
import { convertFieldVideoToMultilingualVideo, convertMultilingualVideoToFieldVideo } from 'utils/lessonHelpers/lessonHelpers';
import LoadingOverlay from 'reusableComponents/LoadingOverlay';
import { createOrUpdateSubtopicLesson } from '../actions/subtopics.actions';
import { LessonUiTypeOptions } from '../constants';

export const renderAddEditLessonModal = ({ subtopicAdminCode, additionalData, setIsOpenAddEditLessonModal, dispatch }) => {
	const { lesson } = additionalData;
	const [isLoading, setIsLoading] = useState(false);

	const editMode = !!lesson?.adminCode;
	const hookFormMethods = useForm();

	useEffect(() => {
		async function prefillData() {
			let lessonVideos = [];
			if (editMode) {
				if (lesson?.video) {
					lessonVideos = await convertMultilingualVideoToFieldVideo(lesson?.video);
				}
			}
			hookFormMethods.reset({
				...lesson,
				title: lesson?.topic?.title?.en,
				description: lesson?.topic?.description?.en,
				topic: lesson?.topic?.title?.en ?? additionalData?.topic?.title?.en,
				subtopic: lesson?.subtopic?.title?.en ?? additionalData?.subtopic?.title?.en,
				course: lesson?.course?.title?.en ?? additionalData?.course?.title?.en,
				uiType: LessonUiTypeOptions.find((option) => option.value === lesson?.uiType),
				lesson_video: lessonVideos,
			});
		}

		const loadData = async () => {
			setIsLoading(true); // Start loading
			try {
				await prefillData(); // Ensure it completes before continuing
			} catch (error) {
				showToast('error', error.message);
			} finally {
				setIsLoading(false); // Stop loading after async function completes
			}
		};

		loadData();
	}, [additionalData]);

	const onSubmit = async () => {
		const formState = hookFormMethods.getValues();

		const { title, description, rankNumber, rankDecimal, uiType, lesson_video: lessonVideo } = formState;

		if (!title) {
			return showToast('error', 'Title is required');
		}

		if (!description) {
			return showToast('error', 'Description is required');
		}

		dispatch(
			createOrUpdateSubtopicLesson({
				subtopicAdminCode,
				lessonId: lesson.id,
				editMode,
				formFields: {
					title,
					description,
					uiType: uiType?.value,
					rankNumber,
					rankDecimal,
					lessonVideo: convertFieldVideoToMultilingualVideo(lessonVideo),
				},
			})
		);

		setIsOpenAddEditLessonModal(false);
	};
	const { control } = hookFormMethods;

	return (
		<>
			<Card>
				<CardBody>
					<LoadingOverlay isLoading={isLoading} />

					<FormProvider {...hookFormMethods}>
						<form className="form ltr-support" onSubmit={hookFormMethods.handleSubmit(onSubmit)}>
							<Box w="100%" mt="1rem" mr="1rem" ml="0.5rem" mb="2rem">
								<h3>{editMode ? 'Edit Lesson' : 'Add Lesson'}</h3>
								<br />
								<Row>
									<Col style={{ height: '200' }}>
										<InputNew label="Title" name="title" defaultValue="" type="text" required />
									</Col>
								</Row>
								<Row>
									<Col>
										<TextAreaNew label="Description" name="description" defaultValue="" required />
									</Col>
								</Row>
								<Row>
									<Col>
										<SelectFieldNew label="Select Lesson Type" name="uiType" options={LessonUiTypeOptions} isDisabled={editMode} required />
									</Col>
								</Row>
								{['VOCAB', 'READING'].includes(hookFormMethods.watch('uiType')?.value) && (
									<Row>
										<FieldVideo name="lesson" control={control} />
									</Row>
								)}
								<Row>
									<Col>
										<InputNew disabled type="number" label="Rank Number" name="rankNumber" defaultValue="" required />
									</Col>
									<Col>
										<InputNew disabled type="number" label="Rank Decimal" name="rankDecimal" defaultValue="" required />
									</Col>
								</Row>
								<Row>
									<Col>
										<InputNew disabled label="Topic" name="topic" defaultValue="" />
									</Col>
								</Row>
								<Row>
									<Col>
										<InputNew disabled label="Subtopic" name="subtopic" defaultValue="" />
									</Col>
								</Row>
								<Row>
									<Col>
										<InputNew disabled label="Course" name="course" defaultValue="" />
									</Col>
								</Row>
							</Box>
							<Button type="button" onClick={onSubmit}>
								{editMode ? 'Update' : 'Create'}
							</Button>
						</form>
					</FormProvider>
				</CardBody>
			</Card>
		</>
	);
};

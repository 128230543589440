import React, { useState, useEffect, useRef } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { Button, Card, CardBody, Col, Row } from 'reactstrap';
import Box from 'reusableComponents/Box';
import LoadingOverlay from 'reusableComponents/LoadingOverlay';
import { showToast, generateMongoId } from 'utils/commonHelpers';
import { InputNew } from 'reusableComponents/HookForm/Input';
import { TextAreaNew } from 'reusableComponents/HookForm/TextArea';
import { fetchWrapperGoMedia } from 'utils/fetchWrapper';
import { useDispatch, useSelector } from 'react-redux';
import { SelectFieldNew } from 'reusableComponents/HookForm/Select';
import { DropzoneNew } from 'reusableComponents/HookForm/Dropzone';
import { validateImage } from 'containers/Lessons/utils/validateImage';
import { useNavigation } from 'context/navigationContext';
import { uploadImagesAndConvertToDatabaseFormat } from 'utils/hookFormImageHelpers';

import { getSignedUrls } from 'utils/fetchHelpers';
import VideoDropZone from './VideoDropZone';
import { isValidFolderName } from './utils/helpers';
import { setIsLoaded, setIsLoading } from './VideoSlice';
import { VideoTypeOptions, VideoTypesEnum } from './utils/types';
import { ProgressOverlay } from './components/progressOverlay';

enum MediaEntityTypeEnum {
	REEL_VIDEO = 'REEL_VIDEO',
	LESSON_VIDEO = 'LESSON_VIDEO',
	ADS_VIDEO = 'ADS_VIDEO',
	MISC_VIDEO = 'MISC_VIDEO',
	VIDEO_COURSE = 'VIDEO_COURSE',
}

const convertVideoTypeToMediaEntityType = (videoType) => {
	switch (videoType) {
		case VideoTypesEnum.REEL:
			return MediaEntityTypeEnum.REEL_VIDEO;
		case VideoTypesEnum.VIDEO_COURSE:
			return MediaEntityTypeEnum.VIDEO_COURSE;
		case VideoTypesEnum.ADS:
			return MediaEntityTypeEnum.ADS_VIDEO;
		case VideoTypesEnum.LESSON:
			return MediaEntityTypeEnum.LESSON_VIDEO;
		case VideoTypesEnum.MISC:
			return MediaEntityTypeEnum.MISC_VIDEO;
		default:
			return MediaEntityTypeEnum.MISC_VIDEO;
	}
};

// conditionally render thumbnail input fields based on video type
const RenderThumbnailInputConditionally = ({ watchVideoType }) => {
	return (
		<>
			<Col sm="6">
				{[VideoTypesEnum.REEL, VideoTypesEnum.VIDEO_COURSE, VideoTypesEnum.ADS, VideoTypesEnum.LESSON].includes(watchVideoType) && (
					<DropzoneNew label="Thumbnail: (Aspect ratio 9:16)" name="thumbnail9b16" />
				)}
			</Col>
			<Col sm="6">
				{[VideoTypesEnum.REEL, VideoTypesEnum.VIDEO_COURSE].includes(watchVideoType) && (
					<DropzoneNew label="Thumbnail: (Aspect ratio 4:5)" name="thumbnail4b5" />
				)}
			</Col>
			<Col sm="12">
				{[VideoTypesEnum.MISC].includes(watchVideoType) && <DropzoneNew label="Thumbnail: (Any aspect ratio)" name="thumbnailMisc" />}
			</Col>
		</>
	);
};

export const UploadVideo = () => {
	const dispatch = useDispatch();
	const hookFormMethods = useForm();
	const [metadata, setMetadata] = useState(null);
	const [validThumbnails, setValidThumbnails] = useState(false);
	const watchUploadedVideo = hookFormMethods.watch('uploadVideo');
	const { loggedInUser, isLoading } = useSelector((state) => ({
		loggedInUser: state.auth.user,
		isSubmitting: state.program.isSubmitting,
		isLoading: state.video.isLoading,
	}));
	const { navigate } = useNavigation();
	const [progress, setProgress] = useState(0);
	const [underProgress, setUnderProgress] = useState(false);

	useEffect(() => {
		extractMetadata(watchUploadedVideo);
	}, [watchUploadedVideo]);

	const watchVideoType = hookFormMethods.watch('videoType')?.value;

	const watchThumbnail4b5 = hookFormMethods.watch('thumbnail4b5');
	const watchThumbnail9b16 = hookFormMethods.watch('thumbnail9b16');
	const watchThumbnailMisc = hookFormMethods.watch('thumbnailMisc');

	const checkAspectRatio = async ({ aspectRatio, watchRef }) => {
		if (!watchRef) return true; // If no file is selected, skip validation

		const flag = await validateImage(watchRef, {
			minimumAspectRatio: aspectRatio - 0.05,
			maximumAspectRatio: aspectRatio + 0.05,
		});

		if (!flag) {
			showToast('error', 'Invalid aspect ratio');
		}

		return flag;
	};

	useEffect(() => {
		(async () => {
			let isValid = true;

			if ([VideoTypesEnum.REEL, VideoTypesEnum.VIDEO_COURSE].includes(watchVideoType)) {
				const flag4b5 = await checkAspectRatio({ aspectRatio: 4 / 5, watchRef: watchThumbnail4b5 });
				const flag9b16 = await checkAspectRatio({ aspectRatio: 9 / 16, watchRef: watchThumbnail9b16 });
				isValid = (flag4b5 && flag9b16) as boolean;
			} else if ([VideoTypesEnum.ADS, VideoTypesEnum.LESSON].includes(watchVideoType)) {
				const flag9b16 = await checkAspectRatio({ aspectRatio: 9 / 16, watchRef: watchThumbnail9b16 });
				isValid = flag9b16 as boolean;
			} else if (watchVideoType === VideoTypesEnum.MISC) {
				isValid = !!watchThumbnailMisc; // Misc can have any aspect ratio, check if it is added in the form
			}

			setValidThumbnails(isValid);
		})();
	}, [watchVideoType, watchThumbnail4b5, watchThumbnail9b16, watchThumbnailMisc]);

	const uploadFile = (file, uploadUrl) => {
		return new Promise((resolve, reject) => {
			const xhr = new XMLHttpRequest();
			xhr.open('PUT', uploadUrl, true);

			xhr.setRequestHeader('x-ms-blob-type', 'BlockBlob');
			xhr.setRequestHeader('Content-Type', file.type);

			xhr.upload.onprogress = (event) => {
				if (event.lengthComputable) {
					const percentCompleted = Math.round((event.loaded / event.total) * 100);
					setProgress(percentCompleted);
				}
			};

			xhr.onload = () => {
				setUnderProgress(false);
				if (xhr.status === 201) {
					resolve(xhr.response);
				} else {
					// eslint-disable-next-line prefer-promise-reject-errors
					reject(`Upload failed with status ${xhr.status}`);
				}
			};

			xhr.onerror = () => {
				setUnderProgress(false);
				// eslint-disable-next-line prefer-promise-reject-errors
				reject('Upload failed');
			};
			setProgress(0);
			setUnderProgress(true);
			xhr.send(file);
		});
	};

	// to get video dimenstion and duration
	const extractMetadata = (file) => {
		if (!file) {
			return;
		}

		const videoElement = document.createElement('video');
		videoElement.preload = 'metadata';

		videoElement.onloadedmetadata = () => {
			window.URL.revokeObjectURL(videoElement.src);

			setMetadata({
				width: videoElement.videoWidth,
				height: videoElement.videoHeight,
				duration: Math.floor(videoElement.duration * 1000),
				type: file.type,
			});
		};

		videoElement.src = URL.createObjectURL(file);
	};

	const handleFormSubmit = async (data) => {
		dispatch(setIsLoading());

		try {
			if (!data.uploadVideo) {
				return showToast('error', 'Please upload video');
			}

			if (!metadata) {
				return showToast('error', 'Failed to extract video metadata');
			}

			if (!validThumbnails) {
				return showToast('error', 'Please upload valid thumbnails');
			}

			// eslint-disable-next-line prefer-template
			const fileName = data.uploadVideo.name.replace(/\.[^/.]+$/, ''); // remove extension from file

			const entityId = generateMongoId();
			const customPath = `videos/${fileName}/${data.uploadVideo.name}`;

			// get upload and download signed urls
			const signedUrls = await getSignedUrls({
				mediaParams: [
					{
						mimeType: metadata.type,
						entityId,
						entityType: convertVideoTypeToMediaEntityType(watchVideoType),
						customPath,
						mediaType: 'VIDEO',
					},
				],
			});

			if (!signedUrls?.uploadUrl) {
				showToast('error', 'Failed to get signed urls');
				return;
			}

			// upload the video to upload url
			await uploadFile(data.uploadVideo, signedUrls.uploadUrl);

			const imagesUploadData: any = await uploadImagesAndConvertToDatabaseFormat({
				imagesKeys: [
					{
						key: 'thumbnail4b5',
						isMulti: false,
						withCaption: false,
						entityId,
						entityType: convertVideoTypeToMediaEntityType(watchVideoType),
					},
					{
						key: 'thumbnail9b16',
						isMulti: false,
						withCaption: false,
						entityId,
						entityType: convertVideoTypeToMediaEntityType(watchVideoType),
					},
					{
						key: 'thumbnailMisc',
						isMulti: false,
						withCaption: false,
						entityId,
						entityType: convertVideoTypeToMediaEntityType(watchVideoType),
					},
				],
				data,
			});

			const formData = new FormData();
			// formData.append('file', data.uploadVideo);
			formData.append('customPath', customPath);
			formData.append('videoName', data.videoName);
			formData.append('videoDesc', data.videoDesc);

			formData.append('createdBy', loggedInUser.id);
			formData.append('author', data.author);
			formData.append('videoDuration', metadata.duration);
			formData.append('videoWidth', metadata.width);
			formData.append('videoHeight', metadata.height);
			formData.append('mimeType', metadata.type);
			formData.append('videoType', data.videoType?.value);
			formData.append(
				'thumbnails',
				JSON.stringify({
					url9b16: imagesUploadData.thumbnail9b16.url,
					url4b5: imagesUploadData.thumbnail4b5.url,
				})
			);

			// build the thumbnails object conditionally
			const thumbnails = {};
			if (imagesUploadData.thumbnail4b5?.url) {
				thumbnails['url4b5'] = imagesUploadData.thumbnail4b5.url;
			}
			if (imagesUploadData.thumbnail9b16?.url) {
				thumbnails['url9b16'] = imagesUploadData.thumbnail9b16.url;
			}
			if (imagesUploadData.thumbnailMisc?.url) {
				thumbnails['urlmisc'] = imagesUploadData.thumbnailMisc.url;
			}

			formData.append('thumbnails', JSON.stringify(thumbnails));

			if (!isValidFolderName(fileName)) {
				return showToast('error', 'Invalid file name');
			}
			const res = await fetchWrapperGoMedia({
				entityId,
				entityType: 'VIDEO',
				caption: data.videoName,
				location: `videos/${fileName}`,
				body: formData,
			});

			if (res.status) {
				showToast('success', 'Video submitted successfully');

				hookFormMethods.reset({});
				navigate(`/videos/manage`);
			} else {
				showToast('error', 'Failed to upload video');
			}
		} catch (error) {
			showToast('error', `Something went wrong : ${error.toString()}`);
		} finally {
			dispatch(setIsLoaded());
		}
	};

	return (
		<>
			<LoadingOverlay isLoading={isLoading}>
				<Card>
					<CardBody>
						<h3>Upload Video</h3>
						{underProgress && <ProgressOverlay progress={progress} />}
						<FormProvider {...hookFormMethods}>
							<Box w="100%" mt="1rem" mr="1rem" ml="0.5rem" mb="2rem">
								<form className="form ltr-support" onSubmit={hookFormMethods.handleSubmit(handleFormSubmit)}>
									<Row
										style={{
											width: '100%',
										}}
									>
										<Col sm="6">
											<Controller
												as={<VideoDropZone />}
												name="uploadVideo"
												control={hookFormMethods.control}
												accept="video/*"
												maxFiles={1}
											/>
										</Col>
										<Col
											sm="6"
											style={{
												display: 'flex',
												justifyContent: 'center',
												alignItems: 'center',
											}}
										>
											<VideoPlayer file={watchUploadedVideo} />
										</Col>

										<Col sm="12">
											<br />
											<br />
											<InputNew name="videoName" label="Video Title" defaultValue="" required placeholder="Video title" />
										</Col>
										<Col sm="12">
											<TextAreaNew name="videoDesc" label="Video Description" defaultValue="" required placeholder="Video decription" />
										</Col>
										<Col sm="12">
											<InputNew name="author" label="Author Name" defaultValue="" required placeholder="Author Name" />
										</Col>
										<Col sm="12">
											<SelectFieldNew label="Select Video Type" name="videoType" options={VideoTypeOptions} required />
										</Col>

										<RenderThumbnailInputConditionally watchVideoType={watchVideoType} />

										<Col>
											<br />
											<br /> <Button type="submit">Upload</Button>
										</Col>
									</Row>
								</form>
							</Box>
						</FormProvider>
					</CardBody>
				</Card>
			</LoadingOverlay>
		</>
	);
};

interface VideoPlayerProps {
	file?: File | null;
}

const VideoPlayer: React.FC<VideoPlayerProps> = ({ file }) => {
	const videoRef = useRef<HTMLVideoElement>(null);
	const [dimensions, setDimensions] = useState({ width: 240, height: 540 });
	const [error, setError] = useState<string | null>(null);

	useEffect(() => {
		if (!file) {
			setError('No file provided');
			return;
		}

		if (!file.type.startsWith('video/')) {
			setError('Invalid file type. Please upload a video.');
			showToast('error', 'Invalid file type. Please upload a video.');
			return;
		}

		setError(null);

		const videoElement = videoRef.current;
		if (videoElement) {
			videoElement.onloadedmetadata = () => {
				setDimensions({
					width: videoElement.videoWidth || 240,
					height: videoElement.videoHeight || 540,
				});
			};

			videoElement.onerror = () => {
				setError('Failed to load the video. The file may be corrupted.');
				showToast('error', 'Failed to load the video. The file may be corrupted.');
			};
		}
	}, [file]);

	if (error) {
		return <></>;
	}

	return (
		<div>
			<video ref={videoRef} controls width={dimensions.width} height={dimensions.height} style={{ display: 'block' }}>
				<source src={file ? URL.createObjectURL(file) : ''} type={file?.type} />
				Your browser does not support the video tag.
			</video>
		</div>
	);
};

import WordMatrix from 'containers/Exercise/components/Grid/wordMatrix';
import React from 'react';
import { Row, Col } from 'reactstrap';
import { InputNew } from 'reusableComponents/HookForm/Input';

export const crosswordQuestionForm = ({ watch, setValue, setInvalidWordsData, setWordsMeaningSentences, hang }) => {
	return (
		<>
			<Row className="d-flex">
				<Col className=".col-xs-12">
					<Col sm="12">
						<InputNew label="Level" name="level" required={!hang} disabled={hang === 'true'} />
					</Col>
				</Col>
			</Row>
			<Row className="d-flex">
				<Col className=".col-xs-12">
					<Col sm="12">
						<WordMatrix
							watch={watch}
							setValue={setValue}
							setInvalidWordsData={setInvalidWordsData}
							setWordsMeaningSentences={setWordsMeaningSentences}
						/>
					</Col>
				</Col>
			</Row>
		</>
	);
};

import { IMedia } from 'types/Media';
import { stringifyQueryParams } from './commonHelpers';

const localStorageKey = 'jwtToken';

interface FetchOptions {
	method: 'GET' | 'POST' | 'PUT' | 'DELETE';
	body?: any;
	headers?: any;
}

type UpdateMediaArgs = Partial<IMedia>;

export async function fetchWrapper(endpoint: string, { body, ...customConfig }: FetchOptions) {
	const token = window.localStorage.getItem(localStorageKey);

	const headers: any = { 'content-type': 'application/json' };

	if (token) {
		headers.Authorization = `Bearer ${token}`;
	}

	const config: any = {
		...customConfig,
		headers: {
			...headers,
			...customConfig.headers,
		},
	};

	if (body) {
		config.body = JSON.stringify(body);
	}

	const response = await window.fetch(`${process.env.REACT_APP_API_URL}${endpoint}`, config);
	const data = await response.json();
	return data;
}

export const fetchWrapperMedia = async ({ entityId, entityType, caption = '', location, body }) => {
	const token = window.localStorage.getItem(localStorageKey);

	const qp = {
		...(entityId && { entityId }),
		...(entityType && { entityType }),
		...(caption && { caption }),
		...(location && { fileUploadLocation: location }),
	};
	// for s3 upload : change the url : /media/v1/upload
	return fetch(`${process.env.REACT_APP_API_URL}/media/v2/azure/upload${stringifyQueryParams(qp)}`, {
		method: 'POST',
		credentials: 'same-origin',
		headers: {
			Authorization: `Bearer ${token}`,
		},
		body,
	})
		.then((res) => res.json())
		.then((res) => res)
		.catch((err) => err);
};

export const fetchWrapperGoMedia = async ({ entityId, entityType, caption = '', location, body }) => {
	const token = window.localStorage.getItem(localStorageKey);

	const qp = {
		...(entityId && { entityId }),
		...(entityType && { entityType }),
		...(caption && { caption }),
		...(location && { fileUploadLocation: location }),
	};

	const response = await fetch(`${process.env.REACT_APP_API_URL}/gomedia/azure/upload${stringifyQueryParams(qp)}`, {
		method: 'POST',
		credentials: 'same-origin',
		headers: {
			Authorization: `Bearer ${token}`,
		},
		body,
	});

	const data = await response.json();
	return data;
};

export const uploadMedias = async ({ medias, entityType, entityId, mediaKey = '' }): Promise<any> => {
	return Promise.all(
		medias.map(async ({ file, caption, location }) => {
			const formData = new FormData();
			formData.append('file', file);
			const imageRes = await fetchWrapperMedia({
				entityId,
				entityType,
				caption,
				location,
				body: formData,
			});

			return { mediaKey, ...imageRes };
		})
	);
};

export const updateMedia = async (params: UpdateMediaArgs) => {
	const token = window.localStorage.getItem(localStorageKey);
	const qp = stringifyQueryParams(params);

	return fetch(`${process.env.REACT_APP_API_URL}/media/v1/update-media${qp}`, {
		method: 'PUT',
		headers: {
			Authorization: `Bearer ${token}`,
		},
	})
		.then((res) => res.json())
		.then((res) => res)
		.catch((err) => err);
};

import React, { useEffect, useRef, useState } from 'react';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { Button, Col } from 'reactstrap';
import Box from 'reusableComponents/Box';
import SmallDropZone from 'reusableComponents/Form/SmallDropZone';
import { ErrorMessage } from 'reusableComponents/HookForm/ErrorMessage';
import { SelectFieldNew } from 'reusableComponents/HookForm/Select';
import { getCFSanitizedImageUrl, getSanitizedAudioUrl } from 'utils/commonHelpers';
import { audioLanguageOptions } from '../utils/constants';

export const MultilingualAudioUploadField = ({ name, watch, widthSpan = '50%' }) => {
	const { control } = useFormContext();
	const audioUploadFields = useFieldArray({
		control,
		name: `${name}_customAudio`,
	});

	const audioUploadWatch = watch(`${name}_customAudio`);

	return (
		<div
			style={{
				backgroundColor: audioUploadFields.fields.length === 0 ? '#fff' : '#FFE74C22',
				padding: '10px 16px',
				borderRadius: '0 0 8px 8px',
				width: '100%',
			}}
		>
			{audioUploadFields.fields.length > 0 && (
				<div>
					<h4
						style={{
							textAlign: 'left',
						}}
					>
						Audios
					</h4>
					<br />
				</div>
			)}

			<div
				style={{
					display: 'flex',
					flexWrap: 'wrap',
				}}
			>
				{audioUploadFields.fields.map((audioUploadField, idx) => (
					<div
						key={audioUploadField.id}
						style={{
							minWidth: widthSpan,
							position: 'relative',
						}}
					>
						<div
							style={{
								position: 'relative',
								display: 'flex',
								justifyContent: 'center',
								alignContent: 'center',
							}}
						>
							<Col sm="4">
								<SelectFieldNew
									placeholder="Language"
									label=""
									name={`${name}_customAudio[${idx}].languageCode`}
									options={audioLanguageOptions}
									style={{
										margin: '0',
										padding: '0',
									}}
									defaultValue={audioLanguageOptions.find((language) => language.value === audioUploadField?.languageCode?.value)}
								/>
							</Col>
							<Col sm="8">
								<div
									className="form__form-group"
									style={{
										pointerEvents: 'all',
										display: 'flex',
										justifyContent: 'space-between',
										alignItems: 'center',
										marginTop: '-7px',
									}}
								>
									<Controller
										as={<SmallDropZone />}
										name={`${name}_customAudio[${idx}].audio`}
										control={control}
										accept="audio/*"
										maxFiles={1}
									/>
									{audioUploadWatch?.[idx]?.audio && <UploadedAudioPlayer file={audioUploadWatch?.[idx]?.audio} />}
									{typeof audioUploadWatch?.[idx]?.audio === 'string' && (
										<OpenUrlButton url={getSanitizedAudioUrl(audioUploadWatch?.[idx]?.audio)} />
									)}
									<ErrorMessage
										{...{ label: name, name }}
										render={(message) => {
											return <span className="form__form-group-error">{message}</span>;
										}}
									/>
								</div>
							</Col>
							<Box onClick={() => audioUploadFields.remove(idx)} cursor="pointer" pos="absolute" t="12px" r="6px">
								<span className="lnr lnr-cross" />
							</Box>
						</div>
					</div>
				))}
			</div>
			<div
				style={{
					display: 'flex',
					alignItems: 'center',
					flexDirection: 'column',
				}}
			>
				<Button
					style={{
						backgroundColor: '#FFE74C',
						color: '#fff',
						border: 'none',
						borderRadius: audioUploadFields.fields.length === 0 ? '8px' : '100px',
						padding: '10px 16px',
						margin: '0',
						cursor: 'pointer',
					}}
					onClick={() => {
						audioUploadFields.append({});
					}}
				>
					{audioUploadFields.fields.length === 0 ? '+ Add Audio' : '╋'}
				</Button>
			</div>
		</div>
	);
};

const UploadedAudioPlayer = ({ file }) => {
	const [audioUrl, setAudioUrl] = useState(null);
	const audioRef = useRef(null);
	const [isPlaying, setIsPlaying] = useState(false);

	useEffect(() => {
		if (typeof file === 'string') {
			const audio = new Audio(file);
			audio.addEventListener('canplaythrough', () => setAudioUrl(file), { once: true });
			audio.addEventListener('error', () => setAudioUrl(null), { once: true });
			return () => {
				setIsPlaying(false);
				audio.src = '';
			};
		}

		if (file instanceof Blob) {
			const url = URL.createObjectURL(file);
			setAudioUrl(url);
			return () => URL.revokeObjectURL(url);
		}
	}, [file]);

	const togglePlay = () => {
		if (!audioRef.current) return;

		if (isPlaying) {
			audioRef.current.pause();
		} else {
			audioRef.current.play();
		}
		setIsPlaying(!isPlaying);
	};

	useEffect(() => {
		const audio = audioRef.current;
		if (!audio) return;

		const handleAudioEnd = () => setIsPlaying(false);
		audio.addEventListener('ended', handleAudioEnd);

		return () => audio.removeEventListener('ended', handleAudioEnd);
	}, [audioRef.current]);

	if (!audioUrl) {
		return <></>;
	}

	return (
		<button
			style={{
				border: 'none',
				borderRadius: '10px',
				backgroundColor: '#FFE74C',
				color: '#fff',
			}}
			type="button"
			onClick={togglePlay}
			className="p-2 rounded-full bg-gray-200"
		>
			{isPlaying ? (
				<img src={getCFSanitizedImageUrl('assets/admin/soundOnUploadedAudioWhite.png')} alt="soundOnIcon" className="upload-icon" />
			) : (
				<img src={getCFSanitizedImageUrl('assets/admin/soundOffUploadedAudioWhite.png')} alt="soundOffIcon" className="upload-icon" />
			)}
			{audioUrl && <audio ref={audioRef} src={audioUrl} />}
		</button>
	);
};

const OpenUrlButton = ({ url }) => {
	const handleClick = () => {
		window.open(url, '_blank', 'noopener,noreferrer');
	};

	return (
		<button
			style={{
				border: 'none',
				borderRadius: '10px',
				cursor: 'pointer',
				backgroundColor: '#FFE74C00',
				color: '#fff',
			}}
			type="button"
			onClick={handleClick}
		>
			<img src={getCFSanitizedImageUrl('assets/admin/downloadCloud.png')} alt="soundOnIcon" className="upload-icon" />
		</button>
	);
};

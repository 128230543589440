import React, { useEffect } from 'react';
import { Col, Row } from 'reactstrap';
import { AnagramTypesEnum } from 'containers/Lessons/utils/types';
import { InputNew } from 'reusableComponents/HookForm/Input';
import { TextAreaNew } from 'reusableComponents/HookForm/TextArea';
import { SelectFieldNew } from 'reusableComponents/HookForm/Select';
import { AnagramItem } from 'containers/Exercise/components/AnagramItem';
import { AnagramTypeOptions } from 'utils/constants';
import { FieldTranslation } from '../fieldTranslations';
import { MultilingualAudioUploadField } from '../multilingualAudioUploadField';
import AudioPlayer from '../audioPlayerQuestion';

const AnagramSentenceFrame = ({ watch, fieldName }) => {
	const watchAnagramBlocks = watch(fieldName);

	return (
		<div>
			<ul className=" d-flex flex-row justify-content-center align-items-center list-group mt-5 pt-5">
				{watchAnagramBlocks &&
					watchAnagramBlocks.map((word: any, index: any) => (
						<li
							key={`${index}-${word?.value?.en?.text}`}
							className="list-group-item mb-2"
							style={{ border: '2px solid orange', borderRadius: '10px' }}
						>
							{word?.value?.en?.text}
						</li>
					))}
			</ul>
		</div>
	);
};

export const anagramQuestionForm = ({ audioData, control, mode, watch, oldQuestion, setAudioData, question }) => {
	const anagramItemType = watch('anagramType');
	return (
		<>
			<Row className="d-flex">
				<Col>
					<SelectFieldNew
						label="Content Type"
						name="anagramType"
						options={AnagramTypeOptions}
						// defaultValue={AnagramTypeOptions[0]}
						isDisabled={mode === 'edit'}
						required
					/>
					<Row>
						<Col>
							<InputNew name="title" label="Title" required defaultValue="" />
							<FieldTranslation name="title" control={control} oldFieldData="test" />
							<MultilingualAudioUploadField name="title" watch={watch} />
						</Col>
						<Col sm="1">
							<AudioPlayer audioData={oldQuestion?.title?.en?.audio?.[question?.voiceId]} text={question?.voiceId} />
						</Col>
					</Row>
					<Col className=".col-6">
						<AnagramSentenceFrame watch={watch} fieldName="correctWords" />
					</Col>
					<Row>
						<Col sm="6">
							<AnagramItem
								label="Correct Words"
								control={control}
								fieldName="correctWords"
								anagramItemType={anagramItemType && anagramItemType.value}
								audioData={audioData}
								setAudioData={setAudioData}
								question={question}
							/>
						</Col>
						<Col sm="6">
							<AnagramItem
								label="Extra Words"
								control={control}
								fieldName="extraWords"
								anagramItemType={anagramItemType && anagramItemType.value}
								audioData={audioData}
								setAudioData={setAudioData}
								question={question}
							/>
						</Col>
					</Row>
					<Col sm="12">
						<TextAreaNew
							name="subtitle"
							label="Narration Text"
							defaultValue="Rearrange the words to form a sentence"
							placeholder="This is optional and is used for narration in the background."
						/>
					</Col>
					<Col sm="12">
						<SelectFieldNew
							label="Difficulty Level"
							name="difficulty"
							options={Array.from({ length: 10 }, (_, index) => ({
								label: index + 1,
								value: index + 1,
							}))}
							defaultValue={{
								label: 5,
								value: 5,
							}}
							required
						/>
					</Col>
				</Col>
			</Row>
		</>
	);
};

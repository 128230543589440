//   Validates whether a filename is safe to use as a folder name.
//   Returns true if it's valid, false otherwise.
export const isValidFolderName = (fileName: string): boolean => {
	const trimmedName = fileName.trim();

	if (!trimmedName) {
		return false;
	}

	// Disallow any spaces (including middle)
	if (/\s/.test(trimmedName)) {
		return false;
	}

	// Disallowed characters on Windows and often problematic elsewhere
	// eslint-disable-next-line no-control-regex
	const forbiddenCharsRegex = /[<>:"/\\|?*\x00-\x1F]/;
	if (forbiddenCharsRegex.test(trimmedName)) {
		return false;
	}

	// Disallow names ending with a space or dot (common Windows issue)
	if (/[. ]$/.test(trimmedName)) {
		return false;
	}

	const reservedNames = [
		'CON',
		'PRN',
		'AUX',
		'NUL',
		'COM1',
		'COM2',
		'COM3',
		'COM4',
		'COM5',
		'COM6',
		'COM7',
		'COM8',
		'COM9',
		'LPT1',
		'LPT2',
		'LPT3',
		'LPT4',
		'LPT5',
		'LPT6',
		'LPT7',
		'LPT8',
		'LPT9',
	];

	if (reservedNames.includes(trimmedName.toUpperCase())) {
		return false;
	}

	const maxLength = 255;
	if (trimmedName.length > maxLength) {
		return false;
	}

	return true;
};

import { useNavigation } from 'context/navigationContext';
import React, { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Card, CardBody, Col, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Pagination, Row } from 'reactstrap';
import Filter from 'reusableComponents/Filter';
import LoadingOverlay from 'reusableComponents/LoadingOverlay';
import DataPaginationTable from 'reusableComponents/Tables/DataPaginationTable';
import { isNotEmptyArray, ObjectMaybe, parseQueryParams, stringifyQueryParams } from 'utils/commonHelpers';
import { DocumentStatusEnum } from 'utils/constants';
import { format } from 'date-fns';
import { fetchVideoCourses } from '../actions/videoCourses.actions';
import { setApiParams, setPage } from '../VideoCourseSlice';
import { TVideoCourse } from '../utils/types';
import { updateVideoCourse } from '../utils/videoCourseEditFetchHelpers';
import StatusDropDownItem from './StatusDropDownItem';

const heads = [
	{
		accessor: 'title',
		Header: 'Title',
	},
	{
		accessor: 'viewCount',
		Header: 'View Count',
	},
	{
		accessor: 'status',
		Header: 'Status',
	},
	{
		accessor: 'createdAt',
		Header: 'Created At',
	},
	{
		accessor: 'actions',
		Header: 'Actions',
	},
];

// Status Update Wrapper
async function UpdateVideoCourseStatus({
	videoCourseId,
	status,
	updatedBy,
	dispatch,
	apiParams,
}: {
	videoCourseId: string;
	status: DocumentStatusEnum;
	updatedBy: string;
	dispatch: any;
	apiParams: any;
}) {
	await updateVideoCourse(
		{
			status,
		},
		videoCourseId,
		updatedBy
	);

	// dispatch action to update the list of video courses
	dispatch(
		fetchVideoCourses({
			...apiParams,
		})
	);
}

const Actions = ({
	videoId,
	id,
	status,
	loggedInUserId,
	totalVideosCourses,
	dispatch,
	apiParams,
}: Pick<TVideoCourse, 'id'> & {
	videoId: string;
	status: DocumentStatusEnum;
	loggedInUserId: string;
	totalVideosCourses: number;
	dispatch: any;
	apiParams: any;
}) => {
	const [dropdownOpen, setDropdownOpen] = useState(false);
	const toggle = () => setDropdownOpen((prevState) => !prevState);
	const { navigate } = useNavigation();

	return (
		<Dropdown isOpen={dropdownOpen} toggle={toggle} onClick={(e) => e.stopPropagation()}>
			<DropdownToggle caret>Actions</DropdownToggle>
			<DropdownMenu>
				<>
					<DropdownItem
						onClick={() => navigate(`/video-course/add-edit${stringifyQueryParams({ id, hang: 'true', mode: 'edit' })}`)}
						disabled={false}
					>
						Edit Video Course
					</DropdownItem>
					<DropdownItem onClick={() => navigate(`/videos/preview${stringifyQueryParams({ videoId })}`)} disabled={false}>
						Watch Video Course
					</DropdownItem>

					<StatusDropDownItem
						status={status}
						updateVideoCourseStatus={UpdateVideoCourseStatus}
						videoCourseId={id}
						updatedBy={loggedInUserId}
						dispatch={dispatch}
						apiParams={apiParams}
						disabled={false}
					/>
				</>
			</DropdownMenu>
		</Dropdown>
	);
};

const createNewRows = ({
	videoCourses,
	loggedInUserId,
	dispatch,
	apiParams,
}: {
	videoCourses: Array<TVideoCourse>;
	loggedInUserId: string;
	dispatch: any;
	apiParams: any;
}) => {
	return videoCourses.map((videoCourse, idx) => {
		const { id, title, video, status, viewCount, createdAt } = videoCourse;

		return {
			id,
			title,
			status,
			video,
			createdAt: format(new Date(createdAt), 'dd LLL, yyyy hh:mm a'),
			viewCount,
			actions: (
				<Actions
					id={id}
					videoId={video.id}
					totalVideosCourses={videoCourses.length}
					status={status}
					loggedInUserId={loggedInUserId}
					dispatch={dispatch}
					apiParams={apiParams}
				/>
			),
		};
	});
};

// list of video courses
const CourseList = (props) => {
	const { register, handleSubmit, watch, errors, setValue, control, ...hookFormMethods } = useForm();
	const dispatch = useDispatch();

	const videoCourseState = useSelector(({ videoCourse, auth }) => ({
		videoCourses: videoCourse.videoCourses,
		videoCourse: videoCourse.videoCourse,
		isLoading: videoCourse.isLoading,
		error: videoCourse.error,
		isSubmitting: videoCourse.isSubmitting,
		apiParams: videoCourse.apiParams,
		total: videoCourse.total,
		page: videoCourse.page,
		loggedInUser: auth.user,
	}));

	const [searchTitle, setSearchTitle] = useState<string>(videoCourseState.apiParams.title);

	const { navigate } = useNavigation();

	const [videoCoursesRow, setVideoCoursesRow] = useState(null);
	const [pageOfItems, setPageOfItems] = useState(1);

	React.useEffect(() => {
		dispatch(
			fetchVideoCourses({
				...videoCourseState.apiParams,
			})
		);
	}, [videoCourseState.apiParams]);

	React.useEffect(() => {
		setPageOfItems(videoCourseState.page);
	}, [videoCourseState.page]);

	React.useEffect(() => {
		if (isNotEmptyArray(videoCourseState.videoCourses)) {
			const structuredRows = createNewRows({
				videoCourses: videoCourseState.videoCourses,
				loggedInUserId: videoCourseState.loggedInUser.id,
				dispatch,
				apiParams: videoCourseState.apiParams,
			});
			setVideoCoursesRow(structuredRows);
		} else {
			setVideoCoursesRow([]);
		}
	}, [videoCourseState.videoCourses]);

	const onSearch = (e) => {
		// eslint-disable-next-line no-unused-expressions
		e && e.preventDefault();

		dispatch(
			setApiParams({
				...videoCourseState.apiParams,
				title: searchTitle,
			})
		);
	};

	const onChangePage = (itemsPage: number) => {
		const { apiParams } = videoCourseState;
		if (itemsPage) {
			dispatch(setPage(itemsPage));
			dispatch(
				setApiParams({
					...apiParams,
					page: itemsPage,
				})
			);
		}
	};

	const qp = ObjectMaybe(parseQueryParams(props.location.search));

	return (
		<FormProvider {...{ ...hookFormMethods, register, handleSubmit, watch, errors, setValue, control }}>
			<Card>
				<LoadingOverlay isLoading={videoCourseState.isLoading} />

				<CardBody>
					<Row className="mt-1 mb-4 rounded">
						<Col sm={6}>
							<h3>Video Courses</h3>
						</Col>
						<Col sm={6}>
							<Button onClick={() => navigate(`/video-course/add-edit${stringifyQueryParams({ hang: 'true', mode: 'add', set: qp.set })}`)}>
								Add a new video course
							</Button>
						</Col>

						<Col sm={6}>
							<Filter searchTerm={searchTitle} setSearchTerm={setSearchTitle} onSearch={onSearch} placeholder="Search by caption" />
						</Col>
					</Row>

					{isNotEmptyArray(videoCoursesRow) && (
						<>
							<DataPaginationTable heads={heads} rows={videoCoursesRow} />
							<Pagination
								itemsCount={videoCourseState.total}
								itemsToShow={videoCourseState.apiParams.limit}
								pageOfItems={pageOfItems}
								onChangePage={onChangePage}
							/>
						</>
					)}
					{videoCourseState.total === 0 && <div className="">No data</div>}
				</CardBody>
			</Card>
		</FormProvider>
	);
};

export default CourseList;

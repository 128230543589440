import { createAsyncThunk } from '@reduxjs/toolkit';
import { setError } from 'containers/App/globalSlice';
import { showToast } from 'utils/commonHelpers';
import { fetchWrapper } from 'utils/fetchWrapper';
import { TVideoState } from '../utils/types';

export const fetchVideos = createAsyncThunk('/videos/fetchVideos', async (apiParams: TVideoState['apiParams'], { dispatch }) => {
	try {
		const response = await fetchWrapper(`/admin/fetch-new-videos`, {
			method: 'POST',
			body: apiParams,
		});

		const { data } = response;
		if (response.status !== 200) {
			dispatch({ type: setError.type, payload: { errors: response.errors } });
		}

		return data;
	} catch (error) {
		showToast('error', error.message);
	}
});

export const fetchVideoById = createAsyncThunk('/videos/fetchVideoById', async (id: string, { dispatch }) => {
	try {
		const response = await fetchWrapper(`/admin/fetch-new-video?videoId=${id}`, {
			method: 'GET',
		});

		const { data } = response;
		if (response.status !== 200) {
			dispatch({ type: setError.type, payload: { errors: response.errors } });
		}

		return data;
	} catch (error) {
		showToast('error', error.message);
	}
});

import React, { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Row, Col, Card, CardBody, Button } from 'reactstrap';
import { InputNew as Input } from 'reusableComponents/HookForm/Input';
import LoadingOverlay from 'reusableComponents/LoadingOverlay';
import { isAllowedEmail, showToast } from 'utils/commonHelpers';

import { fetchWrapper } from 'utils/fetchWrapper';
import Box from 'reusableComponents/Box';
import { userHasDevSettingAccess } from '../utils/helpers';

const PaymentServices = ({ loggedInUser }) => {
	const [refundRes, setRefundRes] = useState(null);
	const [subscriptionRes, setSubscriptionRes] = useState(null);
	const [loading, setLoading] = useState(false);
	const { register, handleSubmit, errors, setValue, ...hookFormMethods } = useForm();

	const handledRefund = async (value) => {
		setRefundRes(null);
		if (!value.pgOrderId) {
			return null;
		}

		setLoading(true);
		const { amount } = value;
		const res = await fetchWrapper('/v1/admin/create-refund', {
			method: 'POST',
			body: {
				pgOrderId: value.pgOrderId,
				pgSubscriptionId: value.pgSubscriptionId,
				...(amount ? { amount: Number(amount) } : {}),
				token: process.env.REACT_APP_REFUND_AUTH_TOKEN,
			},
		});
		setRefundRes(res);
		if (res.status !== 200 || !res.data.status) {
			showToast('error', res?.data?.msg ?? 'Something going wrong', 1000);
			setLoading(false);
		} else {
			showToast('success', 'Request Refund submitted successfully!', 1000);
			setLoading(false);
		}
	};
	const handledSubscriptionCancel = async (value) => {
		const consent = prompt("Please type 'CANCEL' to cancel subscription", '');
		if (consent === 'CANCEL') {
			setSubscriptionRes(null);
			if (!value.pgSubscriptionId) {
				return null;
			}

			setLoading(true);
			const res = await fetchWrapper('/v2/admin/cancel-subscription', {
				method: 'POST',
				body: value,
			});
			setSubscriptionRes(res);
			if (res.status !== 200 || !res.data.status) {
				showToast('error', res?.data?.msg ?? 'Something going wrong', 1000);
				setLoading(false);
			} else {
				showToast('success', 'Request Refund submitted successfully!', 1000);
				setLoading(false);
			}
		}
	};
	let grantSubscriptionAccess = false;
	let devSettingAccess = false;
	if (loggedInUser && loggedInUser.email[0]) {
		grantSubscriptionAccess = isAllowedEmail('REACT_APP_GRANT_SUBSCRIPTION_ADMINS', loggedInUser.email[0]);
		devSettingAccess = userHasDevSettingAccess(loggedInUser.email[0]);
	}
	return (
		<>
			{grantSubscriptionAccess && (
				<LoadingOverlay isLoading={loading} msg="Submitting your request...">
					<FormProvider {...{ register, handleSubmit, errors, setValue, ...hookFormMethods }}>
						<div>
							<Card>
								<CardBody>
									<Box w="100%">
										<Row>
											<Col sm="12">
												<h3>Create Refund</h3>
											</Col>
										</Row>
									</Box>
									<Box w="100%" mt="1rem">
										<form onSubmit={handleSubmit(handledRefund)} className="form ltr-support">
											<Col sm="12">
												<Input label="Enter the PG Order Id to be refunded" name="pgOrderId" placeholder="asfdk42332refd" />
											</Col>
											<Col sm="12">
												<Input type="number" min={1} label="Amount" name="amount" placeholder="amount (in paisa)" />
											</Col>
											<Col sm="12">
												<Button size="sm" color="primary" type="submit">
													Refund
												</Button>
											</Col>
										</form>
									</Box>
									<Box>
										<Col sm="12">{refundRes && <code>{JSON.stringify(refundRes)}</code>}</Col>
									</Box>
								</CardBody>
							</Card>
						</div>
					</FormProvider>
				</LoadingOverlay>
			)}
			{devSettingAccess && (
				<LoadingOverlay isLoading={loading} msg="Submitting your request...">
					<FormProvider {...{ register, handleSubmit, errors, setValue, ...hookFormMethods }}>
						<div>
							<Card>
								<CardBody>
									<Box w="100%">
										<Row>
											<Col sm="12">
												<h3>Subscription Cancel</h3>
											</Col>
										</Row>
									</Box>
									<Box w="100%" mt="1rem">
										<form onSubmit={handleSubmit(handledSubscriptionCancel)} className="form ltr-support">
											<Col sm="12">
												<Input label="Enter the PG Subscription Id to be Cancel" name="pgSubscriptionId" placeholder="238942394782" />
											</Col>
											<Col sm="12">
												<Button size="sm" color="primary" type="submit">
													Cancel Subscription
												</Button>
											</Col>
										</form>
									</Box>
									<Box>
										<Col sm="12">{subscriptionRes && <code>{JSON.stringify(subscriptionRes)}</code>}</Col>
									</Box>
								</CardBody>
							</Card>
						</div>
					</FormProvider>
				</LoadingOverlay>
			)}
		</>
	);
};

export default PaymentServices;

import { TVideo } from 'containers/Videos/utils/types';
import { showToast } from 'utils/commonHelpers';
import { fetchWrapper } from 'utils/fetchWrapper';

type FetchVideosParams = {
	query?: string;
	videoType?: string;
	mediaConvertStatus?: string;
};

type VideoOption = {
	id: string;
	videoName: string;
};

// helper function that fetches video and returns
// for VideoSelect component
export const fetchVideos = async ({ query = '', videoType = '' }: FetchVideosParams): Promise<VideoOption[]> => {
	try {
		const response = await fetchWrapper(`/admin/fetch-new-videos`, {
			method: 'POST',
			body: {
				videoName: query,
				videoType,
				mediaConvertStatus: 'COMPLETE',
				limit: 10,
				page: 1,
			},
		});
		if (response.status !== 200) {
			showToast('error', response.errors.map((e) => e.message).join(', '));
		}

		const { data } = response;

		return data.videos;
	} catch (error) {
		showToast('error', error.message);
		return [];
	}
};

// helper functtion to fetch video by id
export const fetchVideoById = async (id: string): Promise<TVideo> => {
	try {
		const response = await fetchWrapper(`/admin/fetch-new-video?videoId=${id}`, {
			method: 'GET',
		});

		const { data } = response;
		if (response.status !== 200) {
			showToast('error', response.errors.map((e) => e.message).join(', '));
		}

		return data;
	} catch (error) {
		showToast('error', error.message);
		return null;
	}
};

import { createAsyncThunk } from '@reduxjs/toolkit';
import { setError } from 'containers/App/globalSlice';
import { showToast } from 'utils/commonHelpers';
import { fetchWrapper } from 'utils/fetchWrapper';
import { TReelState } from '../utils/types';

export const fetchReels = createAsyncThunk('/reels/fetchReels', async (apiParams: TReelState['apiParams'], { dispatch }) => {
	try {
		const response = await fetchWrapper(`/admin/reel/fetch-reels`, {
			method: 'POST',
			body: apiParams,
		});

		const { data } = response;
		if (response.status !== 200) {
			dispatch({ type: setError.type, payload: { errors: response.errors } });
		}

		return data;
	} catch (error) {
		showToast('error', error.message);
	}
});

export const fetchReelById = createAsyncThunk('/reels/fetchReelById', async (reelId: string, { dispatch }) => {
	try {
		const response = await fetchWrapper(`/admin/reel/fetch-reel?id=${reelId}`, {
			method: 'GET',
		});

		const { data } = response;
		if (response.status !== 200) {
			dispatch({ type: setError.type, payload: { errors: response.errors } });
		}

		return data;
	} catch (error) {
		showToast('error', error.message);
	}
});

import React from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { Card, CardBody, Row, Col, Button } from 'reactstrap';
import Box from 'reusableComponents/Box';
import { InputNew } from 'reusableComponents/HookForm/Input';
import { scaleSubtopicLessons } from '../actions/subtopics.actions';

export const renderScaleModal = ({ subtopicAdminCode, lessons, additionalData, setIsOpenScaleModal, dispatch }) => {
	const hookFormMethods = useForm();
	const { bucket } = additionalData;

	if (!bucket) {
		return <></>;
	}

	const scaleInputValue = hookFormMethods.watch('scale', '');

	const onSubmit = () => {
		const { scale } = hookFormMethods.getValues();

		if (scale !== bucket) {
			return;
		}

		dispatch(
			scaleSubtopicLessons({
				subtopicAdminCode,
				bucket,
			})
		);

		setIsOpenScaleModal(false);
	};

	return (
		<>
			<Card>
				<CardBody>
					<FormProvider {...hookFormMethods}>
						<form
							className="form ltr-support"
							onSubmit={hookFormMethods.handleSubmit(onSubmit)}
							style={{
								height: '100%',
							}}
						>
							<Box
								w="100%"
								mt="1rem"
								mr="1rem"
								ml="0.5rem"
								mb="2rem"
								style={{
									height: '100%',
									display: 'flex',
									flexDirection: 'column',
									justifyContent: 'space-between',
								}}
							>
								<h2>Scale {bucket}</h2>
								<br />
								<br />
								<h4>Subtopic flow</h4>
								<br />
								{lessons
									.map((pairedLesson) => {
										if (bucket === 'CONTROL') {
											return pairedLesson.controlLesson;
										}
										return pairedLesson.testLesson;
									})
									.filter((lesson) => lesson?.adminCode)
									.map((lesson, idx) => {
										const { uiType, adminCode } = lesson;

										return (
											<Row key={adminCode}>
												<Col>
													<p>
														{idx + 1}. {uiType}
													</p>
												</Col>
											</Row>
										);
									})}
								<br />
								<br />

								<Row>
									<Col sm="9">
										<InputNew placeholder={`Type ${bucket} to confirm.`} label="" name="scale" defaultValue="" type="text" required />
									</Col>
									<Col sm="3">
										<Button
											style={{
												height: '36px',
												fontSize: '.7rem',
											}}
											type="button"
											onClick={onSubmit}
											disabled={scaleInputValue !== bucket}
										>
											SCALE {bucket}
										</Button>
									</Col>
								</Row>
							</Box>
						</form>
					</FormProvider>
				</CardBody>
			</Card>
		</>
	);
};

import React, { useEffect, useRef, useState } from 'react';
import { useFieldArray } from 'react-hook-form';
import { SelectFieldNew } from 'reusableComponents/HookForm/Select';
import { Button, Col, Row } from 'reactstrap';
import { InputNew } from 'reusableComponents/HookForm/Input';
import Box from 'reusableComponents/Box';
import { getCFSanitizedImageUrl, getSanitizedAudioUrl } from 'utils/commonHelpers';
import { languageOptions } from '../utils/constants';

export const FieldTranslation = ({ name, control, selectWidth = '2', textWidth = '9', oldFieldData }) => {
	const translationsFields = useFieldArray({
		control,
		name: `${name}_translations`,
	});

	return (
		<div
			style={{
				backgroundColor: translationsFields.fields.length === 0 ? '#fff' : '#fdb1b122',
				padding: '10px 16px',
				borderRadius: '0 0 8px 8px',
				display: 'flex',
				alignItems: 'center',
				flexDirection: 'column',
			}}
		>
			{translationsFields.fields.length > 0 && (
				<div
					style={{
						width: '100%',
					}}
				>
					<h4
						style={{
							textAlign: 'left',
						}}
					>
						Translations
					</h4>
					<br />
				</div>
			)}
			{translationsFields.fields.map((translationField, idx) => (
				<div
					key={translationField.id}
					style={{
						width: '100%',
						position: 'relative',
					}}
				>
					<Row>
						<Col sm={selectWidth}>
							<SelectFieldNew
								placeholder="Language"
								label=""
								name={`${name}_translations[${idx}].languageCode`}
								options={languageOptions}
								style={{
									margin: '0',
									padding: '0',
								}}
								defaultValue={languageOptions.find((language) => language.value === translationField?.languageCode?.value)}
							/>
						</Col>
						<Col
							sm={textWidth}
							style={{
								marginTop: '6px',
							}}
						>
							<InputNew
								style={{
									margin: '0',
									padding: '0',
									height: '100%',
								}}
								placeholder="Translation"
								name={`${name}_translations[${idx}].translation`}
								label=""
								defaultValue={translationField.translation}
							/>
						</Col>
						<Col sm="1">
							{oldFieldData?.[translationField?.languageCode?.value]?.audio?.['CUSTOM']?.audioUrls?.[0]?.audioUrl && (
								<URLAudioPlayer
									url={getSanitizedAudioUrl(
										oldFieldData?.[translationField?.languageCode?.value]?.audio?.['CUSTOM']?.audioUrls?.[0]?.audioUrl ?? ''
									)}
								/>
							)}
						</Col>
						<Box onClick={() => translationsFields.remove(idx)} cursor="pointer" pos="absolute" t="12px" r="0">
							<span className="lnr lnr-cross" />
						</Box>
					</Row>
				</div>
			))}
			<Button
				style={{
					backgroundColor: '#fdb1b1',
					color: '#fff',
					border: 'none',
					borderRadius: translationsFields.fields.length === 0 ? '8px' : '100px',
					padding: '10px 16px',
					margin: '0',
					cursor: 'pointer',
				}}
				onClick={() => {
					translationsFields.append({});
				}}
			>
				{translationsFields.fields.length === 0 ? '+ Add Translation' : '╋'}
			</Button>
		</div>
	);
};

const URLAudioPlayer = ({ url }) => {
	const [isPlaying, setIsPlaying] = useState(false);
	const [hasError, setHasError] = useState(false);
	const audioRef = useRef(null);

	const togglePlay = async () => {
		if (audioRef.current) {
			try {
				if (isPlaying) {
					audioRef.current.pause();
				} else {
					await audioRef.current.play();
				}
				setIsPlaying(!isPlaying);
				setHasError(false);
			} catch (error) {
				console.error('Playback error:', error);
				setHasError(true);
				setIsPlaying(false);
			}
		}
	};

	if (!url) {
		return null;
	}

	if (hasError) {
		return <></>;
	}

	return (
		<div className="flex items-center">
			<button
				style={{
					border: 'none',
					borderRadius: '10px',
					backgroundColor: '#FDB1B1',
					color: '#fff',
				}}
				type="button"
				onClick={togglePlay}
				className="p-2 rounded-full hover:bg-gray-100 transition-colors"
				aria-label={isPlaying ? 'Pause audio' : 'Play audio'}
			>
				{isPlaying ? (
					<img src={getCFSanitizedImageUrl('assets/admin/soundOnUploadedAudioWhite.png')} alt="soundOnIcon" className="upload-icon" />
				) : (
					<img src={getCFSanitizedImageUrl('assets/admin/soundOffUploadedAudioWhite.png')} alt="soundOffIcon" className="upload-icon" />
				)}
			</button>
			<audio
				ref={audioRef}
				src={url}
				onEnded={() => setIsPlaying(false)}
				onError={() => {
					setHasError(true);
					setIsPlaying(false);
				}}
			/>
		</div>
	);
};

import React, { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Row, Col, Card, CardBody, Button } from 'reactstrap';
import Box from 'reusableComponents/Box';
import { DropzoneNew } from 'reusableComponents/HookForm/Dropzone';
import { InputNew } from 'reusableComponents/HookForm/Input';
import { TextAreaNew } from 'reusableComponents/HookForm/TextArea';
import { actionSpreader, parseQueryParams, showToast, stringifyQueryParams } from 'utils/commonHelpers';
import { fetchWrapper } from 'utils/fetchWrapper';
import { getPreviewImages, uploadImagesAndConvertToDatabaseFormat } from 'utils/hookFormImageHelpers';
import { setBreadcrumbs } from 'containers/App/globalSlice';
import { useDispatch, useSelector } from 'react-redux';
import Breadcrumbs from 'components/Breadcrumbs';
import { MediaTypeEnum } from '../utils/types';
import { FieldVideo } from './fieldVideo';

export const LessonAddEditForm = (props) => {
	const { setValue, control, ...hookFormMethods } = useForm();
	const { lessonId } = parseQueryParams(props.location.search) ?? {};
	const dispatch = useDispatch();
	const breadcrumbsData = useSelector(({ global }) => ({
		breadcrumbs: global.breadcrumbs,
	}));
	const getLessonDetails = async () => {
		const lessonData = await fetchWrapper('/admin/get-lesson-data', {
			method: 'POST',
			body: {
				lessonId,
			},
		});
		return lessonData?.data;
	};

	const getLessonVideoById = async (videoId) => {
		const lessonVideo = await fetchWrapper(`/admin/fetch-new-video?videoId=${videoId}`, {
			method: 'GET',
		});
		return lessonVideo?.data;
	};

	// INITIAL LOAD AND PREFILL
	useEffect(() => {
		dispatch(
			actionSpreader(setBreadcrumbs.type, {
				breadcrumbs: [
					{
						text: 'Lesson',
						url: `/lessons/list${stringifyQueryParams({})}`,
					},
				],
			})
		);

		const preFillLessonData = async () => {
			try {
				const lessonData = await getLessonDetails();
				if (!lessonData) {
					window.open('/404', '_self');
				}

				const lessonVideo = (lessonData?.video as { string: { videoId: string; url: string; type: string } }) || null;
				const lessonVideoPrefill: Array<{
					languageCode: { label: string; value: string };
					video: { label: string; value: string };
				}> = lessonVideo
					? await Promise.all(
							Object.entries(lessonVideo).map(async ([langEnum, obj]) => {
								const video = await getLessonVideoById(obj.videoId);
								return {
									languageCode: { label: langEnum, value: langEnum },
									video: {
										label: video?.videoName,
										value: obj.videoId,
									},
								};
							})
					  )
					: [];

				hookFormMethods.reset({
					title: lessonData?.info?.title?.en,
					description: lessonData?.info?.description?.en,
					coverImage: lessonData?.info?.coverImage?.mediaPath
						? getPreviewImages(
								{ id: lessonData?.info?.coverImage?.mediaId, mediaType: 'image', url: lessonData?.info?.coverImage?.mediaPath },
								false
						  )
						: null,
					lesson_video: lessonVideoPrefill,
				});
			} catch (error) {
				showToast('error', 'Error fetching lesson details');
				console.error('Error fetching lesson details:', error);
			}
		};

		preFillLessonData();
	}, []);

	// ON SUBMIT BUTTON CLICK
	const onSubmit = async (data) => {
		const imagesKeys = [{ key: 'coverImage', isMulti: false, withCaption: false, entityId: lessonId, entityType: 'LESSON' }];

		const uploadFileData: any = await uploadImagesAndConvertToDatabaseFormat({ imagesKeys, data });

		const coverImage = {
			mediaId: uploadFileData?.coverImage?.id,
			mediaType: MediaTypeEnum.IMAGE,
			mediaPath: uploadFileData?.coverImage?.url,
		};

		const isImageUploaded = !!uploadFileData?.coverImage?.url;

		if (isImageUploaded) {
			showToast('success', 'Image Uploaded Successfully');
		}

		const updatedLessonData = await fetchWrapper('/admin/update-lesson-details', {
			method: 'POST',
			body: {
				lessonId,
				title: data.title,
				description: data.description,
				coverImage: isImageUploaded ? coverImage : {},
				lessonVideos: data.lesson_video,
			},
		});

		if (updatedLessonData.message === 'Success') {
			showToast('success', 'Lesson details updated successfully');
		} else {
			showToast('error', 'Error updating lesson details');
		}
	};

	return (
		<Card>
			<CardBody>
				<Row className="mt-1 mb-4 rounded">
					<Col sm={12}>
						<Breadcrumbs breadcrumbs={breadcrumbsData.breadcrumbs} />
					</Col>
				</Row>

				<FormProvider {...{ ...hookFormMethods, setValue, control }}>
					<form className="form ltr-support" onSubmit={hookFormMethods.handleSubmit(onSubmit)}>
						<Box w="100%" mt="1rem" mr="1rem" ml="0.5rem" mb="2rem">
							<Row>
								<Col style={{ height: '200' }}>
									<InputNew label="Title" name="title" defaultValue="" type="text" required />
								</Col>
							</Row>

							<Row>
								<Col>
									<TextAreaNew label="Description" name="description" defaultValue="" required />
								</Col>
							</Row>

							<Row>
								<FieldVideo name="lesson" control={control} />
							</Row>
							<Row>
								<Col>
									<DropzoneNew
										name="coverImage"
										label="Image"
										accept="image/jpeg, image/png, image/webp, image/svg+xml"
										height="300px"
										width="300px"
										defaultValue={null}
									/>
								</Col>
							</Row>
							<Button type="submit">Update</Button>
						</Box>
					</form>
				</FormProvider>
			</CardBody>
		</Card>
	);
};

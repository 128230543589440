import React from 'react';
import { Col, Row } from 'reactstrap';
import { QuestionTypesEnum, TContentOnlyLessonQuestion, UiTypeEnum } from 'containers/Lessons/utils/types';
import { DropzoneNew } from 'reusableComponents/HookForm/Dropzone';
import { TextAreaNew } from 'reusableComponents/HookForm/TextArea';
import { InputNew } from 'reusableComponents/HookForm/Input';
import { SelectFieldNew } from 'reusableComponents/HookForm/Select';
import { roleVoiceOptions } from 'utils/constants';
import Box from 'reusableComponents/Box';
import AudioPlayer from '../audioPlayerQuestion';
import { MultilingualAudioUploadField } from '../multilingualAudioUploadField';
import { FieldTranslation } from '../fieldTranslations';

export const contentOnlyQuestionForm = ({ control, watch, question, oldQuestion, audioData, uiType, questionType }) => {
	if (uiType === UiTypeEnum.READING) {
		return (
			<>
				<Row>
					<Col sm="11">
						<InputNew name="title" label="Word to Read" defaultValue="" placeholder="Word to Read" required />
						<FieldTranslation name="title" control={control} oldFieldData={oldQuestion?.title} />
						<MultilingualAudioUploadField name="title" watch={watch} />
					</Col>
					<Col sm="1">
						<AudioPlayer audioData={audioData?.title?.[question?.voiceId]} text={question?.voiceId} />
					</Col>

					<Col sm="12">
						<DropzoneNew height="600" width="900" label="Associated Image: (Range of Aspect ratio 1.95 to 2.05)" name="descriptionImage" />
					</Col>
				</Row>
			</>
		);
	}

	if (uiType === UiTypeEnum.VOCAB) {
		return (
			<>
				<Row>
					<Col sm="11">
						<InputNew name="title" label="Word" defaultValue="" placeholder="Question Text" required />
						<FieldTranslation name="title" control={control} oldFieldData={oldQuestion?.title} />
						<MultilingualAudioUploadField name="title" watch={watch} />
					</Col>
					<Col sm="1">
						<AudioPlayer audioData={audioData?.title?.[question?.voiceId]} text={question?.voiceId} />
					</Col>

					<Col sm="11">
						<TextAreaNew name="meaning" label="Meaning" defaultValue="" />
						<FieldTranslation name="meaning" control={control} oldFieldData={(oldQuestion as TContentOnlyLessonQuestion).meaning} />
						<MultilingualAudioUploadField name="meaning" watch={watch} />
					</Col>
					<Col sm="1">
						<AudioPlayer audioData={audioData?.meaning?.[question?.voiceId]} text={question?.voiceId} />
					</Col>

					<Col sm="12">
						<SelectFieldNew label="Role Voice" name="roleVoiceId" options={roleVoiceOptions} defaultValue={roleVoiceOptions[0]} required />
					</Col>
					<Col sm="12">
						<TextAreaNew name="partsOfSpeech" label="Parts of Speech" defaultValue="" />
					</Col>
					<Col>
						<DropzoneNew label="Associated Image: (Range of Aspect ratio 1.95 to 2.05)" name="descriptionImage" />
					</Col>
				</Row>
			</>
		);
	}

	return (
		<>
			<Row>
				<Col sm="11">
					<TextAreaNew name="title" label="Title" defaultValue="" />
					<FieldTranslation name="title" control={control} oldFieldData={oldQuestion?.title} />
					<MultilingualAudioUploadField name="title" watch={watch} />
				</Col>
				<Col sm="1">
					<AudioPlayer audioData={audioData?.title?.[question?.voiceId]} text={question?.voiceId} />
				</Col>
			</Row>
			<Row>
				<Box w="100%" d="flex" jc="space-between">
					{questionType?.value === QuestionTypesEnum.CONTENT_ONLY && (
						<Col sm="6">
							<InputNew label="Role Name" name="roleName" />
						</Col>
					)}
					<Col sm="6">
						<SelectFieldNew label="Role Voice" name="roleVoiceId" options={roleVoiceOptions} defaultValue={roleVoiceOptions[0]} />
					</Col>
				</Box>
			</Row>
			<Row>
				<Col>
					<DropzoneNew label="Description Image" name="descriptionImage" />
				</Col>
			</Row>
		</>
	);
};

import React from 'react';
import { Switch, Route } from 'react-router-dom';
import QuestionsList from 'containers/Questions';
import LessonsList from './components/LessonsList';
import { LessonAddEditForm } from './components/LessonAddEditForm';
import LessonQuestionList from './components/LessonQuestionList';
import LessonQuestionAddEditForm from './components/LessonQuestionAddEditForm';
import { SubtopicLessonOrdering } from '../Subtopics/SubtopicLessonOrdering';

const Lessons = () => {
	return (
		<div>
			<Switch>
				<Route path="/lessons/list" component={LessonsList} />
				<Route path="/lessons/hanging-questions/list" component={QuestionsList} />
				<Route path="/lessons/add-edit" component={LessonAddEditForm} />
				<Route path="/lessons/questions/list" component={LessonQuestionList} />
				<Route path="/lessons/questions/add-edit" component={LessonQuestionAddEditForm} />
			</Switch>
		</div>
	);
};

const Subtopics = () => {
	return (
		<div>
			<Switch>
				<Route path="/subtopic/lesson-ordering" component={SubtopicLessonOrdering} />
			</Switch>
		</div>
	);
};

export { Lessons, Subtopics };

import { getSanitizedAudioUrl, isNotEmptyArray, isNotEmptyObject, showToast } from 'utils/commonHelpers';
import { fetchWrapper, uploadMedias } from 'utils/fetchWrapper';
import { isEmptyArray } from 'formik';
import { v4 as uuid } from 'uuid';
import { hasRepeatedWords } from 'containers/Exercise/components/Grid/helpers';
import { QuestionTypesEnum, QuestionUiTypeEnum } from './types';
import { audioLanguageOptions, LanguageCodesEnum, languageOptions, languages } from './constants';
import { validateImage } from './validateImage';

export const statusList = [
	{ label: 'Active', value: 'ACTIVE' },
	{ label: 'Inactive', value: 'INACTIVE' },
	{ label: 'Draft', value: 'DRAFT' },
];

export const QuestionUiTypeFields = [
	{ label: 'Vocab', value: QuestionUiTypeEnum.VOCAB },
	{ label: 'Sentence', value: QuestionUiTypeEnum.SENTENCE },
	{ label: 'Default', value: QuestionUiTypeEnum.DEFAULT },
];

export const QuestionTypeFields = [
	{
		label: 'MCQ',
		value: QuestionTypesEnum.MCQ,
	},
	{
		label: 'Read Along',
		value: QuestionTypesEnum.READ_ALONG,
	},
	{
		label: 'Crossword',
		value: QuestionTypesEnum.CROSSWORD,
	},
	{
		label: 'Content Only',
		value: QuestionTypesEnum.CONTENT_ONLY,
	},
	{
		label: 'Conversation',
		value: QuestionTypesEnum.CONVERSATION,
	},
	{
		label: 'Anagram',
		value: QuestionTypesEnum.ANAGRAM,
	},
	{
		label: 'Match the column',
		value: QuestionTypesEnum.MATCH_COLUMN,
	},
];

export const getLessonQuestion = async ({ apiParams }: { apiParams: { questionId?: string; questionAdminCode?: string } }) => {
	const response = await fetchWrapper('/admin/get-lesson-question', {
		method: 'POST',
		body: apiParams,
	});
	const { data } = response;
	if (response.status !== 200) {
		return { error: response.data };
	}
	return { lessonQuestion: data };
};

export const getOptionType = (option) => {
	const isImageNotEmpty = isNotEmptyArray(option?.image);
	if (option?.value && isImageNotEmpty) {
		return null;
	}
	return option?.value ? 'text' : isImageNotEmpty ? 'image' : null;
};

export const createSubtopic = async (payload) => {
	const response = await fetchWrapper('/v1/admin/add-subtopic', {
		method: 'POST',
		body: payload,
	});

	return response;
};

export const getImageFileFromUrl = async ({ imageUrl, imageName }: { imageUrl: string; imageName: string }) => {
	const response = await fetch(imageUrl);
	const blob = await response.blob();
	const file = new File([blob], imageName, { type: blob.type });
	(file as any).path = imageName;
	(file as any).preview = imageUrl;
	return file;
};

export const convertFileToWebpBlob = async ({ file }) => {
	try {
		if (!file) {
			return;
		}

		// Load the data into an image
		const rawImage = (await new Promise(function (resolve, reject) {
			const rawImage = new Image();

			rawImage.addEventListener('load', function () {
				resolve(rawImage);
			});

			rawImage.src = URL.createObjectURL(file);
		})) as any;

		// Convert image to webp ObjectURL via a canvas blob
		const convertedBlob = await new Promise(function (resolve, reject) {
			const canvas = document.createElement('canvas');
			const ctx = canvas.getContext('2d');

			canvas.width = rawImage.width;
			canvas.height = rawImage.height;
			ctx.drawImage(rawImage, 0, 0);

			canvas.toBlob(function (blob) {
				resolve(URL.createObjectURL(blob));
			}, 'image/webp');
		});

		return convertedBlob;
	} catch (err) {
		showToast('error', `Unable to convert file to webp ${err.messsage}`);
	}
};

export const addOrUpdateCourse = async (payload) => {
	const response = await fetchWrapper('/v1/admin/add-or-update-course', {
		method: 'POST',
		body: payload,
	});

	return response;
};

export const getAllCourses = async ({ academicClass }: { academicClass?: number }) => {
	const response = await fetchWrapper('/v1/admin/get-all-courses', {
		method: 'POST',
		body: { academicClass },
	});
	return response;
};

export const filterAndMapTranslations = (
	field: Record<string, any>
): Array<{
	languageCode: {
		label: string;
		value: LanguageCodesEnum;
	};
	translation: string;
}> => {
	if (!field || !isNotEmptyObject(field)) {
		return [];
	}

	return Object.entries(field)
		.filter(([key]) => languageOptions.some((item) => item.value === key))
		.map(([key, { text }]) => ({
			languageCode: languageOptions.find((languageOption) => languageOption.value === key),
			translation: text,
		}));
};

export const filterAndMapItems = (
	items: {
		key: string;
		value: {
			[key in LanguageCodesEnum]: {
				text: string;
				audio: any;
			};
		};
	}[]
): Array<{
	key: string;
	value: { text: string };
	lang_translations: { translation: string; languageCode: { label: string; value: LanguageCodesEnum } }[]; // eslint-disable-line camelcase
}> | null => {
	if (!items || items.length === 0) {
		return null;
	}

	return items.map((item, index) => {
		// eslint-disable-next-line camelcase
		const lang_translations = Object.entries(item.value)
			.filter(([lang]) => languageOptions.some((option) => option.value === lang))
			.map(([lang, { text }]) => ({
				translation: text,
				languageCode: languageOptions.find((option) => option.value === lang),
			}));

		return {
			key: item.key,
			value: { text: item.value.en.text }, // Assuming 'en' is the default language
			lang_translations,
			column: index % 2 ? '2' : '1',
		};
	});
};

export const filterAndMapCustomUploadedAudio = (
	field: Record<string, any>
): Array<{
	languageCode: {
		label: string;
		value: LanguageCodesEnum;
	};
	audio: string;
}> => {
	if (!field || !isNotEmptyObject(field)) {
		return [];
	}

	return Object.entries(field ?? {})
		.map(([languageCode, fragmentedAudio]) => {
			if (!audioLanguageOptions.some((op) => op.value === languageCode)) {
				return null;
			}

			const customAudioUrl = fragmentedAudio?.audio?.['CUSTOM']?.audioUrls?.[0]?.audioUrl;

			if (!customAudioUrl) {
				return null;
			}

			return {
				languageCode: {
					label: languages[languageCode].name,
					value: languageCode as LanguageCodesEnum,
				},
				audio: getSanitizedAudioUrl(customAudioUrl),
			};
		})
		.filter((doc) => doc !== null);
};

export const renameFile = (file, newName) => {
	return new File([file], newName, { type: file.type, lastModified: file.lastModified });
};

export const urlToFile = async (audioUrl, fileName = 'audio.mp3') => {
	const response = await fetch(audioUrl);
	const blob = await response.blob();
	return new File([blob], fileName, { type: blob.type });
};
const desanitizeAudioUrl = (url) => {
	const match = url.match(/(customAudios\/.+)/);
	return match ? match[1] : url;
};

export const convertTranlationToKeyValuePair = (
	translatedField: Array<{
		languageCode: {
			label: string;
			value: LanguageCodesEnum;
		};
		translation: string;
	}>,
	oldFieldData: Record<string, any>
): Record<string, any> => {
	if (!translatedField || isEmptyArray(translatedField)) {
		return {};
	}

	return translatedField
		?.filter((f) => f.translation && f?.languageCode?.value)
		?.reduce((acc, { translation, languageCode }) => {
			// translation text
			const fragmentedText = {
				...(oldFieldData?.[languageCode.value] ? JSON.parse(JSON.stringify(oldFieldData[languageCode.value])) : {}),
				text: translation,
			};

			acc[languageCode.value] = fragmentedText;
			return acc;
		}, {});
};

export const convertTranslationAndAudioToKeyValuePair = (
	name: string,
	translatedField: Array<{
		languageCode: {
			label: string;
			value: LanguageCodesEnum;
		};
		translation: string;
	}>,
	audioField: Array<{
		languageCode: {
			label: string;
			value: LanguageCodesEnum;
		};
		audio: string | File;
	}>,
	oldFieldData: Record<string, any>,
	nameToAudioRelativePathMap: Record<string, Record<string, string>>
): Record<string, any> => {
	if (!translatedField || isEmptyArray(translatedField)) {
		return {};
	}

	return translatedField
		?.filter((f) => f.translation && f?.languageCode?.value)
		?.reduce((acc, { translation, languageCode }) => {
			// translation text
			const fragmentedTextAudioUrl = {
				...(oldFieldData?.[languageCode.value] ? JSON.parse(JSON.stringify(oldFieldData[languageCode.value])) : {}),
				text: translation,
			};

			if (!fragmentedTextAudioUrl.audio) {
				fragmentedTextAudioUrl.audio = {};
			}

			// translation audio
			const audioValue = audioField?.find((f) => f.languageCode.value === languageCode.value)?.audio;

			if (audioValue instanceof File && nameToAudioRelativePathMap?.[name]?.[languageCode?.value]) {
				fragmentedTextAudioUrl.audio['CUSTOM'] = {
					audioUrls: [
						{
							audioUrl: nameToAudioRelativePathMap?.[name]?.[languageCode?.value],
						},
					],
				};
			} else if (typeof audioValue === 'string') {
				fragmentedTextAudioUrl.audio['CUSTOM'] = {
					audioUrls: [
						{
							audioUrl: desanitizeAudioUrl(audioValue),
						},
					],
				};
			} else {
				// remove audio
				fragmentedTextAudioUrl.audio['CUSTOM'] = {};
			}

			acc[languageCode.value] = fragmentedTextAudioUrl;
			return acc;
		}, {});
};

export const formatEnglishAudio = (
	name: string,
	audioField: Array<{
		languageCode: {
			label: string;
			value: LanguageCodesEnum;
		};
		audio: string | File;
	}>,
	oldFieldData: Record<string, any>,
	nameToAudioRelativePathMap: Record<string, Record<string, string>>
) => {
	const audioValue = audioField?.find((f) => f.languageCode.value === 'en')?.audio;

	const updatedCustomAudio = {
		...oldFieldData?.en?.audio,
	};

	if (audioValue instanceof File && nameToAudioRelativePathMap?.[name]?.['en']) {
		updatedCustomAudio['CUSTOM'] = {
			audioUrls: [
				{
					audioUrl: nameToAudioRelativePathMap?.[name]?.['en'],
				},
			],
		};
	} else if (typeof audioValue === 'string') {
		updatedCustomAudio['CUSTOM'] = {
			audioUrls: [
				{
					audioUrl: desanitizeAudioUrl(audioValue),
				},
			],
		};
	} else {
		// remove audio
		updatedCustomAudio['CUSTOM'] = {};
	}

	return updatedCustomAudio;
};

// check if any of the images in mcq type question of wrong dimension
export const checkImageInMcq = async ({ mcqWatch }) => {
	// eslint-disable-next-line no-async-promise-executor
	return new Promise(async (resolve, reject) => {
		if (mcqWatch.options) {
			for (let idx = 0; idx < mcqWatch.options.length; idx += 1) {
				const option = mcqWatch.options[idx];
				// eslint-disable-next-line no-await-in-loop
				const flag = await validateImage(option.media, {
					minimumAspectRatio: 1,
					maximumAspectRatio: 1,
				});

				if (!flag) {
					resolve(false);
					showToast('error', `Image no ${idx + 1} is of wrong dimensions`);
				}
			}
		}

		resolve(true);
	});
};

export const makeCustomAudioKeysMap = (data) => {
	const audioToKeysMap = Object.entries(data)
		.filter(([key]) => key.endsWith('_customAudio'))
		.flatMap(([key, languageAudios], idx) => {
			return (languageAudios as Array<any>).map((languageAudio: any) => {
				if (!languageAudio?.languageCode?.value || !languageAudio?.audio?.name) {
					return null;
				}

				return {
					key: `${key}.[${idx}].audio`,
					isMulti: true,
					withCaption: true,
					fieldName: key.replace(/_customAudio$/, ''),

					languageCode: languageAudio?.languageCode?.value,
					entityType: languageAudio?.audio?.type,
					entityId: null,
					file: languageAudio?.audio,
					hasCustomImageUploadLocation: true,
				};
			});
		})
		.filter((doc) => doc);

	// add mcq options as well
	if (data.type.value === QuestionTypesEnum.MCQ) {
		data?.options.map((option, idx) => {
			return Object.entries(option)
				.filter(([key]) => key.endsWith('_customAudio'))
				.flatMap(([key, languageAudios]) => {
					return (languageAudios as Array<any>).map((languageAudio: any) => {
						if (!languageAudio?.languageCode?.value || !languageAudio?.audio?.name) {
							return null;
						}

						const uploadObject = {
							key: `${key}.[${idx}].audio`,
							isMulti: true,
							withCaption: true,
							fieldName: `option${idx}`,
							languageCode: languageAudio?.languageCode?.value,
							entityType: languageAudio?.audio?.type,
							entityId: null,
							file: languageAudio?.audio,
							hasCustomImageUploadLocation: true,
						};

						audioToKeysMap.push(uploadObject);

						return uploadObject;
					});
				});
		});
	}

	if (data.type.value === QuestionTypesEnum.MATCH_COLUMN) {
		data?.items.map((item, idx) => {
			return Object.entries(item)
				.filter(([key]) => key.endsWith('_customAudio'))
				.flatMap(([key, languageAudios]) => {
					return (languageAudios as Array<any>).map((languageAudio: any) => {
						if (!languageAudio?.languageCode?.value || !languageAudio?.audio?.name) {
							return null;
						}

						const uploadObject = {
							key: `${key}.[${idx}].audio`,
							isMulti: true,
							withCaption: true,
							fieldName: `option${idx}`,
							languageCode: languageAudio?.languageCode?.value,
							entityType: languageAudio?.audio?.type,
							entityId: null,
							file: languageAudio?.audio,
							hasCustomImageUploadLocation: true,
						};

						audioToKeysMap.push(uploadObject);

						return uploadObject;
					});
				});
		});
	}

	return audioToKeysMap;
};

export const uploadCustomAudioToAzure = async ({
	audioToKeysMap,
	lessonId,
	questionId,
	entityType,
}): Promise<Record<string, Record<string, string>>> => {
	const nameToAudioRelativePathMap = {};
	audioToKeysMap.map(async (doc) => {
		const relativePath = `customAudios/${lessonId}/${questionId}/${doc.fieldName}/${doc?.languageCode}`;
		const fileName = `${uuid()}.${doc.file.name.split('.').pop()}`;

		nameToAudioRelativePathMap[doc.fieldName] = {
			...nameToAudioRelativePathMap[doc.fieldName],
			[doc.languageCode]: `${relativePath}/${fileName}`,
		};

		await uploadMedias({
			medias: [
				{
					file: renameFile(doc.file, fileName),
					location: relativePath,
				},
			],
			entityType,
			entityId: doc.entityId,
			mediaKey: doc.key,
		});
	});
	return nameToAudioRelativePathMap;
};

export const crosswordInvalidWordsMeaningAndExamples = async ({ data, invalidWordsData, wordsMeaningSentences }): Promise<boolean> => {
	// Marking invalid words as invalid
	const promises = [];
	if (!isEmptyArray(invalidWordsData)) {
		promises.concat(
			invalidWordsData.map((invalidWord) => {
				const { wordId, wordText } = invalidWord;
				return fetchWrapper(`/v1/admin/mark-word-as-invalid`, {
					method: 'POST',
					body: { id: wordId, text: wordText },
				});
			})
		);
	}

	await Promise.all(promises);

	if (!isEmptyArray(wordsMeaningSentences)) {
		try {
			const response = await fetchWrapper(`/admin/v1/set-words-meaning-and-example`, {
				method: 'POST',
				body: { words: wordsMeaningSentences },
			});

			if (response.data.status === true) {
				showToast('success', `Word, meanings and examples were updated ✅`);
			} else {
				showToast('error', `Failed to updated word, meanings and examples 🚨`);
			}
		} catch (err) {
			console.log('error in updating word meaning and sentences', err);
		}
	}

	if (data.matrixWords) {
		// validate if there are not repeating words
		const repeatedWords = hasRepeatedWords(data.matrixWords);

		if (repeatedWords) {
			showToast('error', 'There are duplicate words in the matrix');
			return false;
		}
	}
	return true;
};

export const validateMcqQuestion = (data): boolean => {
	// remove the boxes options that are not filled
	if (!data.options || data.options.length === 0) {
		showToast('error', 'An MCQ Question should have at least 2 options.');
		return false;
	}
	data.options = data.options.filter((option) => option.value || option.media);

	if (data?.options?.findIndex((x) => x.isCorrectAnswer) === -1) {
		showToast('error', 'An MCQ Question should have at least 1 correct option.');
		return false;
	}
	if (data?.options?.filter((option) => option.isCorrectAnswer)?.length > 1) {
		showToast('error', 'An MCQ Question should have only one correct option.');
		return false;
	}
	if (data?.options?.length < 2) {
		showToast('error', 'Please enter at least 2 options with text or image');
		return false;
	}
	return true;
};

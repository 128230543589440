import React from 'react';
import { Route, Switch } from 'react-router';
import ReelAddEditForm from './components/ReelAddEditForm';
import ReelList from './components/ReelList';

const Reels = () => {
	return (
		<Switch>
			<Route path="/reels/manage" component={ReelList} />
			<Route path="/reels/add-edit" component={ReelAddEditForm} />
		</Switch>
	);
};

export default Reels;

import React from 'react';
import { useNavigation } from 'context/navigationContext';
import { Row, Col, Button } from 'reactstrap';
import { Checkbox } from 'reusableComponents/HookForm/Checkbox';
import { SelectFieldNew } from 'reusableComponents/HookForm/Select';
import { stringifyQueryParams } from 'utils/commonHelpers';
import { LessonDetails, LessonLabel } from '../StyledComponents';
import { LessonAdminUiStateEnum } from '../constants';
import { prepareLessonAddForm, getLessonData } from '../actions/subtopics.actions';

const showQuestionButtons = (openInNewWindow, lessonId) => {
	return (
		<div>
			<Button
				style={{
					margin: '4px',
					padding: '4px',
					fontSize: '.7rem',
					backgroundColor: '#ecdcff',
				}}
				onClick={() => openInNewWindow(`/lessons/questions/list${stringifyQueryParams({ lessonId })}`)}
			>
				Show Questions
			</Button>
		</div>
	);
};

// FUNCTION THAT DECIDED UI TO BE SHOWN BASED ON LESSON STATE
export const formatLessonDetails = (
	lesson,
	variant: 'CONTROL' | 'TEST',
	{ allowedRanks, isTestingPaused, lessonIdx, dispatch, setIsOpenAddEditLessonModal, rankNumber }
) => {
	const { openInNewWindow } = useNavigation();

	const editLessonHandler = () => {
		dispatch(
			getLessonData({
				lessonId: lesson._id,
			})
		);

		setIsOpenAddEditLessonModal(true);
	};

	const addLessonHandler = ({ rankNumber, rankDecimal }: { rankNumber: number; rankDecimal: number }) => {
		dispatch(
			prepareLessonAddForm({
				rankNumber,
				rankDecimal,
			})
		);
		setIsOpenAddEditLessonModal(true);
	};

	switch (variant) {
		case 'CONTROL': {
			if (!lesson) {
				return (
					<>
						<LessonDetails>
							<Row>
								<Col
									sm="12"
									style={{
										display: 'flex',
										justifyContent: 'center',
									}}
								/>
							</Row>
						</LessonDetails>
					</>
				);
			}

			if (lesson.uiState === LessonAdminUiStateEnum.NO_COPY_ALLOWED) {
				return (
					<>
						<LessonDetails>
							<Row>
								<Col sm="6">
									<div>
										<LessonLabel>Type : </LessonLabel> {lesson?.uiType}
									</div>
									<div>
										<LessonLabel>AdminCode : </LessonLabel> {lesson?.adminCode}
									</div>
									{/* <div>
										<LessonLabel>rank : </LessonLabel> {lesson?.rankDecimal}
									</div> */}
									{showQuestionButtons(openInNewWindow, lesson._id)}
									<Button
										style={{
											margin: '4px',
											padding: '4px',
											fontSize: '.7rem',
											backgroundColor: '#ffe2e0',
										}}
										onClick={editLessonHandler}
									>
										Edit lesson
									</Button>
								</Col>
								{/* <Col sm="6">
									<Checkbox disabled={!isTestingPaused} label="Remove Lesson" name={`removeLesson[${lesson.adminCode}]`} checked={false} />
								</Col> */}
							</Row>
						</LessonDetails>
					</>
				);
			}

			return (
				<LessonDetails>
					<Row>
						<Col sm="5">
							<div>
								<LessonLabel>Type : </LessonLabel> {lesson?.uiType}
							</div>
							<div>
								<LessonLabel>AdminCode : </LessonLabel> {lesson?.adminCode}
							</div>
							{/* <div>
								<LessonLabel>rank : </LessonLabel>
								{lesson?.rankDecimal}
							</div> */}
							{showQuestionButtons(openInNewWindow, lesson._id)}
							<Button
								style={{
									margin: '4px',
									padding: '4px',
									fontSize: '.7rem',
									backgroundColor: '#ffe2e0',
								}}
								onClick={editLessonHandler}
							>
								Edit lesson
							</Button>
						</Col>
						<Col sm="6">
							{/* <Checkbox disabled={!isTestingPaused} label="Remove Lesson" name={`removeLesson[${lesson.adminCode}]`} checked={false} /> */}
							<SelectFieldNew
								isDisabled={!isTestingPaused}
								label="Copy Lesson to TEST"
								placeholder="Select Rank"
								name={`moveToTest[${lesson.adminCode}]`}
								options={allowedRanks.map((rank) => ({
									label: rank,
									value: rank,
								}))}
								isClearable
							/>
						</Col>
					</Row>
				</LessonDetails>
			);
			break;
		}

		case 'TEST': {
			if (!lesson) {
				// allow user to make a lesson here
				return (
					<>
						<LessonDetails>
							<Row>
								<Col
									sm="12"
									style={{
										display: 'flex',
										justifyContent: 'center',
									}}
								>
									<Button
										disabled={!isTestingPaused}
										type="button"
										onClick={() => {
											addLessonHandler({
												rankNumber,
												rankDecimal: lessonIdx + 1,
											});
										}}
									>
										+ Create Lesson {lessonIdx + 1}
									</Button>
								</Col>
							</Row>
						</LessonDetails>
					</>
				);
			}

			if (lesson.uiState === LessonAdminUiStateEnum.REFLECTION) {
				// test lesson was copied from one of the control lesson
				return (
					<>
						<LessonDetails className="overlay-container">
							<div>
								<LessonLabel>Type : </LessonLabel> {lesson?.uiType}
							</div>
							<div>
								<LessonLabel>AdminCode : </LessonLabel> {lesson?.adminCode}
							</div>
							{/* <div>
								<LessonLabel>rank : </LessonLabel> {lesson?.rankDecimal}
							</div> */}
						</LessonDetails>
					</>
				);
			}

			if (lesson.uiState === LessonAdminUiStateEnum.COPIED_LESSON) {
				// test lesson was copied from one of the control lesson
				return (
					<>
						<LessonDetails>
							<Row>
								<Col sm="6">
									<div>
										<LessonLabel>Type : </LessonLabel> {lesson?.uiType}
									</div>
									<div>
										<LessonLabel>AdminCode : </LessonLabel> {lesson?.adminCode}
									</div>
									<div>
										<LessonLabel>Status : </LessonLabel> {lesson?.status}
									</div>
									{/* <div>
										<LessonLabel>rank : </LessonLabel>
										{lesson?.rankDecimal}
									</div> */}
									{showQuestionButtons(openInNewWindow, lesson._id)}
									<Button
										style={{
											margin: '4px',
											padding: '4px',
											fontSize: '.7rem',
											backgroundColor: '#ffe2e0',
										}}
										onClick={editLessonHandler}
									>
										Edit lesson
									</Button>
								</Col>
								<Col sm="6">
									<Checkbox disabled={!isTestingPaused} label="Remove Lesson" name={`removeLesson[${lesson.adminCode}]`} checked={false} />
								</Col>
							</Row>
						</LessonDetails>
					</>
				);
			}

			if (lesson?.bucket === 'C') {
				// test lesson same as control lesson
				return (
					<>
						<LessonDetails className="overlay-container">
							<div>
								<LessonLabel>Type : </LessonLabel> {lesson?.uiType}
							</div>
							<div>
								<LessonLabel>AdminCode : </LessonLabel> {lesson?.adminCode}
							</div>
							{/* <div>
								<LessonLabel>rank : </LessonLabel> {lesson?.rankDecimal}
							</div> */}
						</LessonDetails>
					</>
				);
			}

			return (
				<LessonDetails>
					<Row>
						<Col sm="6">
							<div>
								<LessonLabel>Type : </LessonLabel> {lesson?.uiType}
							</div>
							<div>
								<LessonLabel>AdminCode : </LessonLabel> {lesson?.adminCode}
							</div>
							{/* <div>
								<LessonLabel>rank : </LessonLabel>
								{lesson?.rankDecimal}
							</div> */}
							{showQuestionButtons(openInNewWindow, lesson._id)}
							<Button
								style={{
									margin: '4px',
									padding: '4px',
									fontSize: '.7rem',
									backgroundColor: '#ffe2e0',
								}}
								onClick={editLessonHandler}
							>
								Edit lesson
							</Button>
						</Col>
						<Col sm="6">
							<Checkbox disabled={!isTestingPaused} label="Remove Lesson" name={`removeLesson[${lesson.adminCode}]`} checked={false} />
						</Col>
					</Row>
				</LessonDetails>
			);
			break;
		}

		default: {
			return <></>;
		}
	}
};

// reelslice for the reel component
import { createSlice, CaseReducer, PayloadAction } from '@reduxjs/toolkit';
import { TReelState } from './utils/types';
import { fetchReelById, fetchReels } from './actions/reels.actions';

type TReelReducerType<T> = CaseReducer<TReelState, PayloadAction<T>>;

type TReelReducers = {
	setIsLoading: TReelReducerType<void>;
	setIsLoaded: TReelReducerType<void>;
	setError: TReelReducerType<string>;
	setApiParams: TReelReducerType<TReelState['apiParams']>;
	setPage: TReelReducerType<number>;
	toggleIsSubmitting: TReelReducerType<{ isSubmitting: boolean }>;
};

const ReelSlice = createSlice<TReelState, TReelReducers>({
	name: 'reels',
	initialState: {
		reels: [],
		reel: null,
		isLoading: false,
		error: null,
		page: 1,
		total: 10,
		isSubmitting: false,
		apiParams: {
			limit: 10,
			page: 1,
			caption: '',
			sortKey: 'createdAt',
			sortOrder: '-1',
		},
	},
	reducers: {
		setIsLoading: (state) => {
			state.isLoading = true;
		},
		setIsLoaded: (state) => {
			state.isLoading = false;
		},
		setError: (state, action) => {
			state.error = action?.payload;
		},
		setApiParams: (state, action) => {
			state.apiParams = {
				...(action.payload.limit && { limit: action.payload.limit }),
				...(action.payload.page !== undefined && { page: action.payload.page }),
				...(action.payload.sortKey !== undefined && { sortKey: action.payload.sortKey }),
				...(action.payload.sortOrder !== undefined && { sortOrder: action.payload.sortOrder }),
				...(typeof action.payload.caption !== 'undefined' && { caption: action.payload.caption }),
				...(action.payload.filters && { filters: action.payload.filters }),
			};
		},
		setPage: (state, action) => {
			state.page = action.payload;
		},
		toggleIsSubmitting: (state, action) => {
			state.isSubmitting = action.payload.isSubmitting;
		},
	},
	extraReducers: (builder) => {
		// Fetch Lesson Questions
		builder
			.addCase(fetchReels.pending, (state) => {
				state.isLoading = true;
			})
			.addCase(fetchReels.fulfilled, (state, { payload }) => {
				if (payload?.reels && Array.isArray(payload.reels)) {
					// assign video.videoName from API to video.title
					state.reels = payload.reels;
					state.total = payload.totalDocuments;
				}
				state.isLoading = false;
			})

			.addCase(fetchReelById.pending, (state) => {
				state.isLoading = true;
			})
			.addCase(fetchReelById.fulfilled, (state, { payload }) => {
				if (payload) {
					state.reel = payload;
				}
				state.isLoading = false;
			});
	},
});

export const { setApiParams, setIsLoading, setIsLoaded, setPage, toggleIsSubmitting } = ReelSlice.actions;

export default ReelSlice.reducer;

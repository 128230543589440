import React, { PureComponent } from 'react';
import Dropzone from 'react-dropzone';
import PropTypes from 'prop-types';
import { getCFSanitizedImageUrl } from 'utils/commonHelpers';

export default class SmallDropZone extends PureComponent {
	constructor() {
		super();
		this.state = {
			fileName: '',
		};
		this.onDrop = this.onDrop.bind(this);
		this.removeFile = this.removeFile.bind(this);
	}

	onDrop(files) {
		const { onChange } = this.props;
		const file = files[0];
		if (file) {
			this.setState({ fileName: file.name });
			// eslint-disable-next-line no-unused-expressions
			onChange && onChange(file);
		}
	}

	removeFile() {
		this.setState({ fileName: '' });
		// eslint-disable-next-line no-unused-expressions
		this.props.onChange && this.props.onChange(null);
	}

	render() {
		const { fileName } = this.state;
		return (
			<div style={{ textAlign: 'center', padding: '10px', position: 'relative' }}>
				<Dropzone onDrop={this.onDrop} multiple={false} accept={this.props.accept}>
					{({ getRootProps, getInputProps }) => (
						<div
							{...getRootProps()}
							style={{
								height: '100%',
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center',
								flexDirection: 'column',
								cursor: 'pointer',
								padding: 0,
							}}
						>
							<input {...getInputProps()} />
							{fileName ? (
								<div
									style={{
										display: 'flex',
										borderRadius: '5px',
									}}
								>
									<div
										style={{
											display: 'flex',
											borderRadius: '5px',
											backgroundColor: '#FFE74C',
											padding: '10px',
											position: 'relative',
										}}
									>
										<p
											style={{
												fontSize: '8px',
												position: 'absolute',
												top: '0px',
												left: '0px',
											}}
										>
											{fileName.length > 20 ? `${fileName.substring(0, 17)}...` : fileName}
										</p>
										<button
											type="button"
											onClick={(e) => {
												e.stopPropagation();
												this.removeFile();
											}}
											style={{ border: 'none', background: 'none', cursor: 'pointer', marginTop: '5px' }}
										>
											<img src={getCFSanitizedImageUrl('assets/admin/removeRedIcon.png')} alt="removeIcon" className="upload-icon" />
										</button>
									</div>
								</div>
							) : (
								<div
									style={{
										borderRadius: '5px',
										backgroundColor: '#FFE74C',
										padding: '10px',
									}}
								>
									<img src={getCFSanitizedImageUrl('assets/admin/uploadBigArrowWhite.png')} alt="uploadIcon" className="upload-icon" />
								</div>
							)}
						</div>
					)}
				</Dropzone>
			</div>
		);
	}
}

SmallDropZone.propTypes = {
	onChange: PropTypes.func,
	accept: PropTypes.string,
};

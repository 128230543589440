import React from 'react';
import { Route, Switch, Redirect, withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import Membership from 'containers/Membership';
import Videos from 'containers/Videos';
import Reels from 'containers/Reels';
import VideoCourse from 'containers/VideoCourses';
import Layout from '../Layout/index';
import MainWrapper from './MainWrapper';
import LogIn from '../Authentication/LogIn';
import FAQ from '../FAQ';
import Register from '../Authentication/Register';
import ForgotPassword from '../Authentication/ForgotPassword';
import ResetPassword from '../Authentication/ResetPassword';
import NotFound from '../NotFound';
import Users from '../Users';
import DevSettings from '../DevSettings';
import history from './history';
import LessonList from '../Lessons/components/LessonsList';
import { Lessons, Subtopics } from '../Lessons/index';

const AuthenticatedRoutes = () => {
	const currentPath = history.location.pathname;
	return (
		<MainWrapper>
			<main>
				<div>
					{currentPath !== '/image-viewer' && <Layout />}
					<div className="container__wrap">
						<Switch>
							<Route path="/users" component={Users} />
							<Route path="/faq" component={FAQ} />
							<Route path="/membership" component={Membership} />
							<Route path="/videos" component={Videos} />
							<Route path="/reels" component={Reels} />
							<Route path="/video-course" component={VideoCourse} />
							<Route path="/dev-settings" component={DevSettings} />
							<Route path="/lessons" component={Lessons} />
							<Route path="/subtopic" component={Subtopics} />
							<Route exact path="/404" component={NotFound} />
							<Route path="/" component={LessonList} /> {/* opening page */}
							<Redirect to="/404" />
						</Switch>
					</div>
				</div>
			</main>
		</MainWrapper>
	);
};

const UnauthenticatedRoutes = () => (
	<MainWrapper>
		<main>
			<Switch>
				<Route exact path="/" component={LogIn} />
				<Route exact path="/login" component={LogIn} />
				<Route exact path="/register" component={Register} />
				<Route exact path="/forgot-password" component={ForgotPassword} />
				<Route exact path="/reset-password/:token" component={ResetPassword} />
				<Redirect to="/login" />
			</Switch>
		</main>
	</MainWrapper>
);

const Router = (props) => {
	return props.user ? <AuthenticatedRoutes /> : <UnauthenticatedRoutes />;
};

const mapStateToProps = (state) => ({
	user: state.auth.user,
});

const mapDispatchToProps = () => ({});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withRouter, withConnect)(Router);

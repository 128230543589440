import { createSlice, CaseReducer, PayloadAction } from '@reduxjs/toolkit';
import { TVideoState } from './utils/types';
import { fetchVideoById, fetchVideos } from './actions/video.actions';

type TVideoReducerType<T> = CaseReducer<TVideoState, PayloadAction<T>>;

type TVideoReducers = {
	setIsLoading: TVideoReducerType<void>;
	setIsLoaded: TVideoReducerType<void>;
	setError: TVideoReducerType<string>;
	setApiParams: TVideoReducerType<TVideoState['apiParams']>;
	setPage: TVideoReducerType<number>;
	toggleIsSubmitting: TVideoReducerType<{ isSubmitting: boolean }>;
};

const videoSlice = createSlice<TVideoState, TVideoReducers>({
	name: 'videos',
	initialState: {
		videos: [],
		video: undefined,
		isLoading: false,
		error: null,
		page: 1,
		total: 10,
		isSubmitting: false,
		apiParams: {
			limit: 20,
			page: 1,
			videoName: '',
			videoType: null,
			sortKey: 'createdAt',
			sortOrder: '-1',
		},
	},
	reducers: {
		setIsLoading: (state) => {
			state.isLoading = true;
		},
		setIsLoaded: (state) => {
			state.isLoading = false;
		},
		setError: (state, action) => {
			state.error = action?.payload;
		},
		setApiParams: (state, action) => {
			state.apiParams = {
				...(action.payload.limit && { limit: action.payload.limit }),
				...(action.payload.page !== undefined && { page: action.payload.page }),
				...(action.payload.sortKey !== undefined && { sortKey: action.payload.sortKey }),
				...(action.payload.sortOrder !== undefined && { sortOrder: action.payload.sortOrder }),
				...(typeof action.payload.videoName !== 'undefined' && { videoName: action.payload.videoName }),
				...(typeof action.payload.videoType !== 'undefined' && { videoType: action.payload.videoType }),
				...(action.payload.filters && { filters: action.payload.filters }),
			};
		},
		setPage: (state, action) => {
			state.page = action.payload;
		},
		toggleIsSubmitting: (state, action) => {
			state.isSubmitting = action.payload.isSubmitting;
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(fetchVideos.pending, (state) => {
				state.isLoading = true;
			})
			.addCase(fetchVideos.fulfilled, (state, { payload }) => {
				if (payload?.videos && Array.isArray(payload.videos)) {
					state.videos = payload.videos;
					state.total = payload.documentCount;
				}
				state.isLoading = false;
			})

			.addCase(fetchVideoById.pending, (state) => {
				state.isLoading = true;
			})

			.addCase(fetchVideoById.fulfilled, (state, { payload }) => {
				if (payload) {
					state.video = payload;
				}
				state.isLoading = false;
			});
	},
});

export const { setApiParams, setIsLoading, setIsLoaded, setPage, toggleIsSubmitting } = videoSlice.actions;

export default videoSlice.reducer;

import get from 'lodash/get';
import { IPhotoDatabase, IPhoto } from '../types/Photo';
import { getSanitizedImageUrl, isNotEmptyArray, isNotEmptyObject } from './commonHelpers';
import { uploadMedias } from './fetchWrapper';

// TODO: Add types and documentation
export const uploadImagesAndConvertToDatabaseFormat = async ({ imagesKeys, data, hasCustomImageUploadLocation = false }) => {
	const imagePromises = imagesKeys.map(({ key, isMulti, withCaption, entityType, entityId }) => {
		let images = get(data, key);

		if (isNotEmptyObject(images) && !Array.isArray(images)) {
			images = [images];
		}

		if (!images || !isNotEmptyArray(images)) return { imageKey: key, data: null };

		if (!isMulti && !withCaption) {
			const isAlreadyUploaded = !!images[0].id;
			if (isAlreadyUploaded)
				return {
					imageKey: key,
					data: images[0],
				};

			return uploadMedias({ medias: [{ file: images[0], caption: '' }], entityType, entityId, mediaKey: key }).then((res) => {
				return { imageKey: key, data: (res[0] as any).data };
			});
		}

		if (!isMulti && withCaption) {
			const isAlreadyUploaded = !!images[0]?.photo?.[0]?.id;

			if (isAlreadyUploaded) {
				return { imageKey: key, data: { ...images[0].photo[0], caption: images[0].caption } };
			}

			const filesToUpload = [{ file: images[0].photo[0], caption: images[0].caption }];

			return uploadMedias({
				medias: filesToUpload,
				entityType,
				entityId,
				mediaKey: key,
			}).then((res: any) => {
				return { imageKey: key, data: { ...res[0].data, caption: images[0].caption } };
			});
		}

		if (isMulti && !withCaption) {
			const alreadyUploadedImages = images.filter((img) => img.id);
			const imagesToUpload = images.filter((img) => !img.id);

			return uploadMedias({
				medias: imagesToUpload,
				entityType,
				entityId,
				mediaKey: key,
			}).then((res) => {
				return {
					imageKey: key,
					data: [...alreadyUploadedImages, ...res],
				};
			});
		}

		if (isMulti && withCaption) {
			const alreadyUploadedImages = images.filter((img) => img.photo.id).map((img) => ({ ...img.photo, caption: img.caption }));
			const imagesToUpload = images.filter((img) => !img.photo.id);
			const imagesToUploadCaptions = imagesToUpload.map((img) => img.caption);
			const imagesToUploadFiles = imagesToUpload.map((img) => ({
				file: img.photo,
				[hasCustomImageUploadLocation ? 'location' : 'caption']: img.caption,
			}));

			return uploadMedias({
				medias: imagesToUploadFiles,
				entityType,
				entityId,
				mediaKey: key,
			}).then((res) => {
				const uploadedImagesWithCaption = res.map((imgRes: any, i) => {
					return {
						...imgRes.data,
						caption: imagesToUploadCaptions[i],
					};
				});
				return {
					imageKey: key,
					data: [...alreadyUploadedImages, ...uploadedImagesWithCaption],
				};
			});
		}

		return undefined;
	});

	const imagesUploadData = await Promise.all(imagePromises);
	const imagesDataInObjectShape = imagesUploadData.reduce((acc: any, curr: any) => {
		acc[curr.imageKey] = curr.data;
		return acc;
	}, {});

	return imagesDataInObjectShape;
};

const isIPhotoDatabase = (variableToCheck: any): variableToCheck is IPhotoDatabase => (variableToCheck as IPhotoDatabase).url !== undefined;

const appendPreviewUrl = (pic: IPhotoDatabase): IPhoto => ({ ...pic, preview: getSanitizedImageUrl(pic?.url), name: pic?.id });

export const getPreviewImages = (images: IPhotoDatabase | IPhotoDatabase[], withCaption: boolean) => {
	if (
		!isNotEmptyObject(images) ||
		(!(images as IPhotoDatabase[]).length && (images as IPhotoDatabase).url === undefined) ||
		((images as IPhotoDatabase[]).length && (images as IPhotoDatabase[]).length === 0)
	)
		return [];

	if (isIPhotoDatabase(images)) {
		if (withCaption) {
			return [
				{
					photo: [appendPreviewUrl(images)],
					caption: images.caption,
				},
			];
		}
		return [appendPreviewUrl(images)];
	}

	if (!withCaption) {
		return images.map(appendPreviewUrl);
	}

	return images.map((pic) => ({ photo: appendPreviewUrl(pic), caption: pic?.caption }));
};

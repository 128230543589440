import React, { useState, useEffect, useRef, PureComponent } from 'react';
import styled, { keyframes } from 'styled-components';
import Dropzone from 'react-dropzone';
import PropTypes from 'prop-types';
import { getCFSanitizedImageUrl } from 'utils/commonHelpers';

// Define the animation for sliding the stripes diagonally
const slide = keyframes`
  0% { background-position: 0 0; }
  100% { background-position: 100px 100px; }
`;

// Styled component with diagonal strip pattern
const StripedDiv = styled.div`
	background: repeating-linear-gradient(0deg, #eee 0px, #eee 20px, #fff 20px, #fff 40px);
	background-size: 1000px 1000px;
	animation: ${slide} 5s linear infinite;
	border-radius: 8px;
`;

export default class VideoDropZone extends PureComponent {
	constructor() {
		super();
		this.state = {
			fileName: '',
		};
		this.onDrop = this.onDrop.bind(this);
		this.removeFile = this.removeFile.bind(this);
	}

	onDrop(files) {
		const { onChange } = this.props;
		const file = files[0];
		if (file) {
			this.setState({ fileName: file.name });
			// eslint-disable-next-line no-unused-expressions
			onChange && onChange(file);
		}
	}

	removeFile() {
		this.setState({ fileName: '' });
		// eslint-disable-next-line no-unused-expressions
		this.props.onChange && this.props.onChange(null);
	}

	render() {
		const { fileName } = this.state;
		return (
			<StripedDiv>
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'center',
						alignItems: 'center',
						textAlign: 'center',
						position: 'relative',
						border: '1px solid black',
						height: '100%',
						minHeight: '450px',
					}}
				>
					<Dropzone onDrop={this.onDrop} multiple={false} accept={this.props.accept}>
						{({ getRootProps, getInputProps }) => (
							<div
								{...getRootProps()}
								style={{
									height: '100%',
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'center',
									flexDirection: 'column',
									cursor: 'pointer',
									padding: 0,
								}}
							>
								<input {...getInputProps()} />
								{fileName ? (
									<div
										style={{
											display: 'flex',
											borderRadius: '5px',
										}}
									>
										<div>
											<p
												style={{
													position: 'absolute',
													top: '0px',
													left: '0px',
												}}
											>
												{fileName}
											</p>
											<button
												type="button"
												onClick={(e) => {
													e.stopPropagation();
													this.removeFile();
												}}
												style={{
													position: 'absolute',
													top: '0px',
													right: '0px',
													border: 'none',
													background: 'none',
													cursor: 'pointer',
													marginTop: '5px',
												}}
											>
												<img src={getCFSanitizedImageUrl('assets/admin/removeRedIcon.png')} alt="removeIcon" className="upload-icon" />
											</button>
										</div>
									</div>
								) : (
									<div
										style={{
											height: '100%',
											// display: 'flex',
											// flexDirection: 'column',
											// alignItems: 'center',
											// justifyContent: 'center',
										}}
									>
										<p
											style={{
												fontSize: '80px',
												margin: '0',
												padding: '0',
											}}
										>
											⬆
										</p>
										<p>Drop a video here or click to upload</p>
									</div>
								)}
							</div>
						)}
					</Dropzone>
				</div>
			</StripedDiv>
		);
	}
}

VideoDropZone.propTypes = {
	onChange: PropTypes.func,
	accept: PropTypes.string,
};

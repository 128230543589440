/* eslint-disable no-alert */
/* eslint-disable no-restricted-globals */
import { createAsyncThunk } from '@reduxjs/toolkit';
import { setError } from 'containers/App/globalSlice';
import { showToast } from 'utils/commonHelpers';
import { fetchWrapper } from 'utils/fetchWrapper';
import { TLesson } from 'containers/Lessons/utils/types';

export const fetchSubtopicLessons = createAsyncThunk(
	'/subtopic/fetchSubtopicLessons',
	async (apiParams: { subtopicAdminCode }, { dispatch }) => {
		try {
			const response = await fetchWrapper(`/admin/get-subtopic-lessons`, {
				method: 'POST',
				body: apiParams,
			});

			const { data } = response;
			if (response.status !== 200) {
				dispatch({ type: setError.type, payload: { errors: response.errors } });
			}

			return {
				lessons: data as Array<TLesson>,
			};
		} catch (error) {
			showToast('error', error.message);
		}
	}
);

export const toggleTestingLessons = createAsyncThunk(
	'/subtopic/toggleTestingLessons',
	async (apiParams: { subtopicAdminCode; isTestingPaused }, { dispatch }) => {
		try {
			const isConfirmed = confirm(
				apiParams.isTestingPaused
					? 'All the test lessons will go live. Are you sure you want to continue this action?'
					: 'All the test lessons will be paused. Are you sure you want to continue this action?'
			);

			if (isConfirmed) {
				const response = await fetchWrapper(`/admin/toggle-testing-lessons`, {
					method: 'POST',
					body: apiParams,
				});

				const { data } = response;
				if (response.status !== 200 || !data?.status) {
					showToast('error', `Something went wrong : ${data?.msg}`);
				} else {
					showToast('success', apiParams.isTestingPaused ? 'Subtopic testing started' : 'Subtopic testing paused');
				}

				dispatch(
					fetchSubtopicLessons({
						subtopicAdminCode: apiParams.subtopicAdminCode,
					})
				);
			}
		} catch (error) {
			showToast('error', error.message);
		}
	}
);

export const removeSelectedLessons = createAsyncThunk(
	'/subtopic/removeTestingLessons',
	async (apiParams: { subtopicAdminCode; lessonsToRemove }, { dispatch }) => {
		try {
			const { lessonsToRemove } = apiParams;

			if (!lessonsToRemove || lessonsToRemove.length === 0) {
				return showToast('warn', 'No lessons selected to remove');
			}

			const isConfirmed = confirm(`Are you sure you want to remove the selected lessons? This action is irreversible.`);

			if (isConfirmed) {
				const response = await fetchWrapper(`/admin/remove-testing-lessons`, {
					method: 'POST',
					body: apiParams,
				});

				const { data } = response;
				if (response.status !== 200 || !data?.status) {
					showToast('error', `Something went wrong : ${data?.msg}`);
				} else {
					showToast('success', 'Successfully removed lessons');
				}

				dispatch(
					fetchSubtopicLessons({
						subtopicAdminCode: apiParams.subtopicAdminCode,
					})
				);
			}
		} catch (error) {
			showToast('error', error.message);
		}
	}
);

export const getLessonData = createAsyncThunk('/subtopic/getLessonData', async (apiParams: { lessonId }, { dispatch }) => {
	try {
		const lessonData = await fetchWrapper('/admin/get-lesson-data', {
			method: 'POST',
			body: apiParams,
		});

		return lessonData?.data;
	} catch (error) {
		showToast('error', error.message);
	}
});

export const prepareLessonAddForm = createAsyncThunk(
	'/subtopic/prepareLessonAddForm',
	async (
		apiParams: {
			rankNumber;
			rankDecimal;
		},
		{ dispatch }
	) => {
		try {
			return {
				rankNumber: apiParams.rankNumber,
				rankDecimal: apiParams.rankDecimal,
			};
		} catch (error) {
			showToast('error', error.message);
		}
	}
);

export const scaleControlOrTestForm = createAsyncThunk(
	'/subtopic/scaleControlOrTestForm',
	async (apiParams: { subtopicAdminCode; bucket }, { dispatch }) => {
		try {
			dispatch(
				fetchSubtopicLessons({
					subtopicAdminCode: apiParams.subtopicAdminCode,
				})
			);

			return {
				bucket: apiParams.bucket,
			};
		} catch (error) {
			showToast('error', error.message);
		}
	}
);

export const createOrUpdateSubtopicLesson = createAsyncThunk(
	'/subtopic/createOrUpdateSubtopicLesson',
	async (apiParams: { subtopicAdminCode; lessonId; editMode; formFields }, { dispatch }) => {
		try {
			const { subtopicAdminCode, editMode } = apiParams;

			const isConfirmed = confirm(editMode ? `Confirm updating lesson.` : `Confirm creating lesson.`);

			if (isConfirmed) {
				const response = await fetchWrapper(`/admin/create-or-update-subtopic-lesson`, {
					method: 'POST',
					body: apiParams,
				});

				const { data } = response;
				if (response.status !== 200 || !data?.status) {
					showToast('error', `Something went wrong : ${data?.msg}`);
				} else {
					showToast('success', editMode ? 'Successfully updated lesson' : 'Successfully created lesson');
				}

				dispatch(
					fetchSubtopicLessons({
						subtopicAdminCode,
					})
				);
			}
		} catch (error) {
			showToast('error', error.message);
		}
	}
);

export const copyControlLessonsToTestLessons = createAsyncThunk(
	'/subtopic/copyControlLessonsToTestLessons',
	async (apiParams: { lessonsCodeAndRank; subtopicAdminCode }, { dispatch }) => {
		try {
			const { subtopicAdminCode } = apiParams;
			const isConfirmed = confirm(`Are you sure want to copy lessons from control to test?`);

			if (isConfirmed) {
				const response = await fetchWrapper(`/admin/copy-control-lesson-to-test-lesson`, {
					method: 'POST',
					body: apiParams,
				});

				const { data } = response;
				if (response.status !== 200 || !data?.status) {
					showToast('error', `Something went wrong : ${data?.msg}`);
				} else {
					showToast('success', `Copied Lessons : ${data?.msg}`);
				}

				dispatch(
					fetchSubtopicLessons({
						subtopicAdminCode,
					})
				);
			}
		} catch (error) {
			showToast('error', error.message);
		}
	}
);

export const scaleSubtopicLessons = createAsyncThunk(
	'/subtopic/scaleSubtopicLessons',
	async (apiParams: { subtopicAdminCode; bucket }, { dispatch }) => {
		try {
			const { subtopicAdminCode } = apiParams;

			const response = await fetchWrapper(`/admin/scale-subtopic-lessons`, {
				method: 'POST',
				body: apiParams,
			});

			const { data } = response;
			if (response.status !== 200 || !data?.status) {
				showToast('error', `Something went wrong : ${data?.msg}`);
			} else {
				showToast('success', `Copied Lessons : ${data?.msg}`);
			}

			dispatch(
				fetchSubtopicLessons({
					subtopicAdminCode,
				})
			);
		} catch (error) {
			showToast('error', error.message);
		}
	}
);
